import React from 'react'

import { Row, Col, Badge, Table } from 'react-bootstrap'

export default function UsersList({data, user, totalUsers})
{

    return(
        <>
        <Row>
            
            <Col>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>
                                Empresa
                            </th>
                            <th>
                                Status
                            </th>
                            <th>
                                Usuários
                            </th>
                            <th>
                                Campanhas
                            </th>
                            <th>
                                Campanhas ativas
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                       
                       { data.map((row, key) =>
                            <tr key={key}>
                                <td className={user === row.id ? 'selected' : 'false'}>
                                    {row.nome}
                                </td>
                                <td className={user === row.id ? 'selected' : 'false'}>
                                    {row.isActive ? 'Ativo' : 'Inativo'}
                                </td>
                                <td className={user === row.id ? 'selected' : 'false'}>
                                    { row.users.length }
                                </td>
                                <td className={user === row.id ? 'selected' : 'false'}>
                                    { row.campanhasTotal }
                                </td>
                                <td className={user === row.id ? 'selected' : 'false'}>
                                    { row.campanhasAtivas }
                                </td>
                            </tr>
                       )}

                    </tbody>
                </Table>
            </Col>
            
        </Row>

        <Row>
            { totalUsers.map((row, key) =>
                <Col>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>
                                    {row.tipo}s
                                </th>
                                <th>
                                    Ativos
                                </th>
                                <th>
                                    Inativos
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                        
                            <tr>
                                <td>
                                    {row.totalAtivo + row.totalInativo}
                                </td>
                                <td>
                                    {row.totalAtivo}
                                </td>
                                <td>
                                    {row.totalInativo}
                                </td>
                            </tr>

                        </tbody>
                    </Table>
                </Col>
            )}
            
        </Row>
        </>
    )

}
import React, { useEffect, useState } from 'react';
import { api } from '../../Api/app';
import { useGlobal } from '../../contexts/GlobalContext';
import CreationEmail from '../../Pages/Templates/CreationEmail';
import messageStore from '../../Store/MessageStore';
import InputDefault from '../Form/InputDefault';

const AudioVoice = (props) => {
    const [load, setLoad] = useState(false);
    const [phone, setPhone] = useState('');
    const [phones, setPhones] = useState([]);
    const [upload, setUpload] = useState('');
    const [uploadFilename, setUploadFilename] = useState('');
    const [AudioUpload, setAudioUpload] = useState(new Audio());

    const [bina, setBina] = useState('');
    const [tentativas, setTentativas] = useState(1);

    useEffect(() => {
        props.handleOnChange('mensagem', upload);
        setAudioUpload(new Audio(upload));
    }, [upload]);

    useEffect(() => {
        props.handleOnChange('bina', bina);
    }, [bina]);

    useEffect(() => {
        props.handleOnChange('tentativas', tentativas);
    }, [tentativas]);

    async function handleOnSave(e) {
        try {
            e.preventDefault();
            if (!upload) throw 'O arquivo de aúdio é obrigatório.';
            if (!tentativas)
                throw 'É necessário preencher o máximo de tentativas.';
            if (parseFloat(tentativas) > 4)
                throw 'É permitido no máximo 4 tentativas.';
            if (!bina)
                throw 'É necessário preencher um número para Bina, esse número servirá de identificação quando ligar nos telefones.';
            props.setStep(props.next);
        } catch (e) {
            messageStore.addError(e);
        }
    }

    async function handleUpload(e) {
        try {
            var formData = new FormData();
            formData.append('file', e.target.files[0], e.target.files[0].name);
            setUploadFilename(e.target.files[0].name);
            let response = await api.post('templates/upload', formData);
            if (response.data.error === true) throw response.data.message;
            setUpload(response.data.link);
        } catch (err) {
            console.log('err upload', err);
            //messageStore.addError(err);
        }
    }

    async function sendAvulsos() {
        try {
            if (phones.length === 0) throw 'Nenhum destinatário encontrado.';
            if (!upload)
                throw 'É necessário subir um arquivo de aúdio antes de enviar.';
            if (!tentativas)
                throw 'É necessário preencher o máximo de tentativas.';
            if (parseFloat(tentativas) > 4)
                throw 'É permitido no máximo 4 tentativas.';
            if (!bina)
                throw 'É necessário preencher um número para Bina, esse número servirá de identificação quando ligar nos telefones.';

            setLoad(true);

            const imageWhatsapp = props.fields.imagem
                ? props.fields.imagem.split('/files/templates/')[1]
                : null;

            let response = await api.post('send/voz', {
                audio: upload,
                destinatarios: phones,
            });

            messageStore.addSuccess(
                'Enviado com sucesso, a chamada de voz pode demorar até 10 minutos.'
            );

            setLoad(false);
        } catch (e) {
            setLoad(false);
            messageStore.addError(e);
        }
    }

    function handlePhones(e) {
        const value = e.target.value
            .replace(' ', '')
            .replace(' ', '')
            .replace(' ', '')
            .replace('(', '')
            .replace(')', '')
            .replace('-', '');

        setPhone(value);
        setPhones(value.split(','));
    }

    function validatePhone(phone) {
        phone = phone
            .replace(' ', '')
            .replace(' ', '')
            .replace(' ', '')
            .replace('(', '')
            .replace(')', '')
            .replace('-', '');

        var regex = new RegExp(
            '^((1[1-9])|([2-9][0-9]))((3[0-9]{3}[0-9]{4})|(9[0-9]{3}[0-9]{5}))$'
        );
        return regex.test(phone);
    }

    return (
        <form
            className="step"
            onSubmit={handleOnSave}
            style={{ display: props.step === props.num ? 'block' : 'none' }}
        >
            <h2>{props.nome}</h2>
            <p>
                Configure e faça o upload da mensagem que será feita a chamada
                de voz
            </p>

            <div className="separator">
                <div className="input message-campgain">
                    <div className={'phone'}>
                        <div
                            className={`display voice ${
                                upload ? 'active' : 'inactive'
                            }`}
                        >
                            <div>
                                <h6>{uploadFilename}</h6>
                                <div>
                                    <button
                                        onClick={() => {
                                            AudioUpload.pause();
                                            setAudioUpload(new Audio(upload));
                                        }}
                                        type="button"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            aria-hidden="true"
                                            role="img"
                                            width="1em"
                                            height="1em"
                                            preserveAspectRatio="xMidYMid meet"
                                            viewBox="0 0 20 20"
                                        >
                                            <g fill="none">
                                                <path
                                                    d="M6.535 2.116c-1.04-.264-2.13-.151-2.984.327c-.87.486-1.48 1.347-1.51 2.5c-.046 1.802.371 4.22 2.027 7.059c1.635 2.802 3.44 4.482 4.938 5.48c.946.632 1.991.649 2.884.237c.88-.405 1.594-1.213 1.965-2.217a1.838 1.838 0 0 0-.12-1.535l-.954-1.703a2.5 2.5 0 0 0-3.074-1.114l-.666.255c-.323.123-.62.067-.788-.099c-.565-.555-.969-1.309-1.152-2.12c-.058-.256.06-.558.342-.772l.601-.453a2.5 2.5 0 0 0 .663-3.24l-.972-1.694a1.929 1.929 0 0 0-1.2-.91zM3.041 4.97c.02-.773.412-1.327.998-1.655c.602-.337 1.424-.439 2.25-.23c.245.063.453.22.579.44l.971 1.694a1.5 1.5 0 0 1-.397 1.944l-.601.453c-.52.392-.88 1.064-.716 1.79c.222.982.713 1.912 1.427 2.614c.522.512 1.266.541 1.846.32l.666-.255a1.5 1.5 0 0 1 1.845.668l.953 1.702c.12.214.14.47.055.7c-.29.785-.835 1.374-1.445 1.656c-.597.275-1.272.267-1.911-.16c-1.371-.914-3.07-2.482-4.63-5.153C3.37 8.818 3 6.584 3.042 4.97zm14.813-2.824a.5.5 0 0 1 0 .708L15.207 5.5l2.647 2.646a.5.5 0 0 1-.708.708L14.5 6.207l-2.646 2.647a.5.5 0 0 1-.708-.708L13.793 5.5l-2.647-2.646a.5.5 0 0 1 .708-.708L14.5 4.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                                                    fill="currentColor"
                                                />
                                            </g>
                                        </svg>
                                        <span>Cancelar</span>
                                    </button>
                                    <button
                                        onClick={() => AudioUpload.play()}
                                        type="button"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            aria-hidden="true"
                                            role="img"
                                            width="1em"
                                            height="1em"
                                            preserveAspectRatio="xMidYMid meet"
                                            viewBox="0 0 20 20"
                                        >
                                            <g fill="none">
                                                <path
                                                    d="M6.535 2.117c-1.04-.264-2.13-.152-2.984.326c-.87.486-1.48 1.348-1.51 2.501c-.046 1.802.371 4.22 2.027 7.058c1.635 2.803 3.44 4.483 4.938 5.481c.946.631 1.991.648 2.884.237c.88-.405 1.594-1.214 1.965-2.217a1.838 1.838 0 0 0-.12-1.536l-.954-1.703a2.5 2.5 0 0 0-3.074-1.113l-.666.255c-.323.123-.62.066-.788-.1c-.565-.555-.969-1.308-1.152-2.12c-.058-.256.06-.558.342-.771l.601-.454a2.5 2.5 0 0 0 .663-3.239l-.972-1.695a1.929 1.929 0 0 0-1.2-.91zM3.041 4.97c.02-.774.412-1.327.998-1.655c.602-.337 1.424-.44 2.25-.23c.245.062.453.22.579.439l.971 1.695a1.5 1.5 0 0 1-.397 1.943l-.601.454c-.52.392-.88 1.064-.716 1.789c.222.982.713 1.913 1.427 2.614c.522.512 1.266.542 1.846.32l.666-.255a1.5 1.5 0 0 1 1.845.668l.953 1.703c.12.214.14.47.055.7c-.29.785-.835 1.374-1.445 1.655c-.597.275-1.272.267-1.911-.16c-1.371-.914-3.07-2.482-4.63-5.153C3.37 8.818 3 6.584 3.042 4.971z"
                                                    fill="currentColor"
                                                />
                                                <path
                                                    d="M12.743 2.063a.5.5 0 1 0-.486.874l.384.214a8.5 8.5 0 0 1 4.33 6.584l.032.315a.5.5 0 1 0 .994-.1l-.031-.314a9.5 9.5 0 0 0-4.84-7.36l-.383-.213z"
                                                    fill="currentColor"
                                                />
                                                <path
                                                    d="M11.571 4.743a.5.5 0 0 1 .686-.171l.124.074a6.5 6.5 0 0 1 3.068 4.505l.044.267a.5.5 0 1 1-.986.165l-.045-.267a5.5 5.5 0 0 0-2.595-3.812l-.124-.075a.5.5 0 0 1-.172-.686z"
                                                    fill="currentColor"
                                                />
                                            </g>
                                        </svg>
                                        <span>Ouvir</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="buttons">
                        <button>Próximo passo</button>
                        {props.step !== 1 && (
                            <button
                                onClick={() => props.setStep(props.prev)}
                                type="button"
                            >
                                Passo anterior
                            </button>
                        )}
                    </div>
                </div>
                <div className="list message-campgain">
                    <div className="upload-image">
                        <label>
                            <p>Audio (formato mp3)</p>
                            <input
                                accept="audio/mp3"
                                id="upload-button"
                                onChange={handleUpload}
                                type="file"
                                name="file"
                            />
                        </label>

                        {upload && (
                            <button
                                className="remove-upload"
                                onClick={() => setUpload(null)}
                            >
                                Remover imagem
                            </button>
                        )}
                    </div>
                    <label>
                        <InputDefault
                            onChange={(name, value) => setBina(value)}
                            value={bina}
                            type="text"
                            className="input-default"
                            title="Número da Bina (Precisa ser um número válido)"
                            mask={'(99) 9999-99999'}
                            maskChar={''}
                        />
                    </label>
                    <label>
                        <InputDefault
                            onChange={(name, value) => setTentativas(value)}
                            value={tentativas}
                            type="text"
                            className="input-default"
                            title="Número de tentativas (max:4)"
                            type="number"
                            max="4"
                            min="1"
                        />
                    </label>

                    <label>
                        <b>Teste o envio da campanha</b>
                        <span>Exemplo: 1190909090,1191919191</span>

                        <div>
                            <input
                                onChange={(e) => handlePhones(e)}
                                type="text"
                                value={phone}
                                className="input-default"
                                placeholder="Celulares separados por virgula"
                            />
                            <button type="button" onClick={sendAvulsos}>
                                {load === true ? (
                                    <i className="fa fa-spin fa-spinner"></i>
                                ) : (
                                    'Enviar mensagem teste'
                                )}
                            </button>
                        </div>

                        <div className="phones">
                            {phones.map((row, key) => (
                                <span
                                    className={`phone-number ${
                                        validatePhone(row) ? 'valid' : 'invalid'
                                    }`}
                                >
                                    {validatePhone(row) ? (
                                        <i className="fa fa-check" />
                                    ) : (
                                        <i className="fa fa-times" />
                                    )}
                                    {row}
                                </span>
                            ))}
                        </div>
                    </label>
                </div>
            </div>
        </form>
    );
};

export default AudioVoice;

import React, { useEffect, useState } from 'react';
import Contacts from '../../Components/Chat/Contacts';
import Conversation from '../../Components/Chat/Conversation';
import Profile from '../../Components/Chat/Profile';
import { api } from '../../Api/app';

import './Chat.scss';
import Search from '../../Components/Chat/Search';

export default function Chat() {
    const [contacts, setContacts] = useState([]);

    const [conversation, setConversation] = useState({});

    const [load, setLoad] = useState(true);

    const [search, setSearch] = useState('');

    const [tab, setTab] = useState('minhas');

    const [canais, setCanais] = useState([]);

    useEffect(() => {
        console.log(canais);
    }, [canais]);

    useEffect(() => {
        setLoad(true);
    }, [tab]);

    useEffect(() => {
        updateContacts(conversation);
    }, [search, tab]);

    useEffect(() => {
        let rotationInterval = setInterval(() => {
            console.log('getContacts, setinterval contacts');
            updateContacts(conversation);
        }, 5000);

        return () => {
            console.log('getContacts, clearInterval');
            clearInterval(rotationInterval);
        };
    }, [conversation, search, tab]);

    async function getContacts() {
        try {
            const responseContacts = [];

            const response = await api.get(
                'conversations/whatsapp?search=' + search + '&tab=' + tab
            );

            console.log(response.data);
            response.data.map((row, key) => {
                responseContacts.push({
                    falarComAtendente: row.falarComAtendente,
                    ativo: row.ativo,
                    queueId: row._id,
                    tipoDeEnvio: row.tipoDeEnvio,
                    id: row.ultimaConversa.id,
                    userId: row.userId,
                    name: '',
                    phone: row.telefone,
                    lastMessage: row.ultimaConversa.mensagem,
                    audio: row.ultimaConversa.audio,
                    figurinha: row.ultimaConversa.figurinha,
                    foto: row.ultimaConversa.imagem,
                    video: row.ultimaConversa.video,
                    contatoJSON: row.ultimaConversa.contatoJSON,
                    localizacaoJSON: row.ultimaConversa.localizacaoJSON,
                    arquivo: row.ultimaConversa.arquivo,
                    dateMessage: new Date(row.ultimaConversa.createdAt),
                    totalMessage: row.conversasNaoLidas,
                    type: row.ultimaConversa.userId ? 'sent' : 'received',
                });
            });

            responseContacts.map((row, key) => {
                if (row.queueId === conversation.queueId) {
                    conversation.falarComAtendente = row.falarComAtendente;
                    conversation.lastMessage = row.lastMessage;
                    conversation.audio = row.audio;
                    conversation.figurinha = row.figurinha;
                    conversation.foto = row.foto;
                    conversation.video = row.video;
                    conversation.contatoJSON = row.contatoJSON;
                    conversation.localizacaoJSON = row.localizacaoJSON;
                    conversation.arquivo = row.arquivo;
                    conversation.ativo = row.ativo;
                    responseContacts[key] = conversation;
                }
            });

            return responseContacts;
        } catch (err) {
            console.log('erro ao carregar contatos', err);
        }
    }

    async function updateContacts(json) {
        //console.log('ta pegando só o primeiro contacts carregado e sobrescrevendo, tem que dar um jeito de recuperar novamente aqui.')

        try {
            const newContacts = await getContacts();

            json.totalMessage = 0;

            newContacts.map((row) => {
                if (row.phone === json.phone) {
                    row = json;
                }
            });

            newContacts.sort((x, y) => {
                return y.dateMessage - x.dateMessage;
            });

            setContacts([...newContacts]);

            setLoad(false);
        } catch (err) {
            setLoad(false);
            console.log(err);
        }
    }

    function filterItems(needle, heystack) {
        var query = needle.toLowerCase();
        return heystack.filter(function (item) {
            return item.phone.indexOf(query) >= 0;
        });
    }

    function handleSearch(search) {
        setSearch(search);
        setContacts([...filterItems(search, contacts)]);
    }
    return (
        <div id="chat">
            <div className="left">
                <Profile setCanais={setCanais} />

                <Search
                    contacts={contacts}
                    setContacts={setContacts}
                    search={search}
                    setSearch={handleSearch}
                />

                <ul className="tab-contacts">
                    <li
                        onClick={() => setTab('todas')}
                        className={`${tab === 'todas' ? 'active' : 'inactive'}`}
                    >
                        Todas conversas
                    </li>
                    <li
                        onClick={() => setTab('minhas')}
                        className={`${
                            tab === 'minhas' ? 'active' : 'inactive'
                        }`}
                    >
                        Minhas conversas
                    </li>
                    <li
                        onClick={() => setTab('aguardando')}
                        className={`${
                            tab === 'aguardando' ? 'active' : 'inactive'
                        }`}
                    >
                        Aguardando
                    </li>
                    <li
                        onClick={() => setTab('encerrados')}
                        className={`${
                            tab === 'encerrados' ? 'active' : 'inactive'
                        }`}
                    >
                        Encerrados
                    </li>
                </ul>

                <Contacts
                    conversation={conversation}
                    setConversation={setConversation}
                    contacts={contacts}
                    setContacts={setContacts}
                    load={load}
                    canais={canais}
                />
            </div>

            <div className="right">
                {conversation.id ? (
                    <Conversation
                        conversation={conversation}
                        updateContacts={updateContacts}
                        tab={tab}
                    />
                ) : (
                    <div className="empty"></div>
                )}
            </div>
        </div>
    );
}

import React from 'react'
import IconButton from '../Icon/IconButton'

export default function MenuConditions({
    elements,
    selected,
    setSelected,
    Model,
}) {

    const conditionDefault = {
        all: [
          {
            if: 'respostaUsuario',
            condition: 'existe',
            value: ''
          }
        ],
        target: 0
    }

    function handleNewCondition()
    {

        console.log(selected)

      selected.params.conditions.push(conditionDefault)
      Model.onEditElement(selected)

    }

    function handleRemoveCondition(key)
    {

        console.log('remover o key', key)

        const conditionsFIlter = []

        selected.params.conditions.map((row, keyCondition) => {
            if ( key !== keyCondition ) {
                conditionsFIlter.push(row)
            }
        })

        selected.params.conditions = conditionsFIlter

        Model.onEditElement(selected)

        //faz os caminhos
        const ElementsFilter = elements.filter( obj => obj.source !== selected.id )

        console.log( ElementsFilter )

        selected.params.conditions.map((row, key) => {

            var newConnect = { 
                id: `e${selected.id}-${row.target}`,
                source: selected.id, 
                target: row.target, 
                arrowHeadType: 'arrowclosed' 
            }

            ElementsFilter.push(newConnect)

        })

        Model.setElements(ElementsFilter)
        
    }

    function handleNewConditionAnd( key )
    {

      selected.params.conditions[key].all.push(conditionDefault)
      Model.onEditElement(selected)
      setSelected({...selected})

    }

    function handleRemoveConditionAnd(keyParent, key)
    {

      const conditionsFIlter = []

      selected.params.conditions[keyParent].all.map((row, conditionKey) => {

        if ( key !== conditionKey ) {
          conditionsFIlter.push(row)
        }

      })

      selected.params.conditions[keyParent].all = conditionsFIlter
      Model.onEditElement(selected)

      if ( selected.params.conditions[keyParent].all.length === 0 ) handleRemoveCondition(keyParent)

    }

    function handleEditCondition(keyParent, key, name, value)
    {

      selected.params.conditions[keyParent].all[key][name] = value.replace(', ',',').replace(' ,',',')
      Model.onEditElement(selected)
      setSelected({...selected})

    }

    function handleEditConditionTarget(keyParent, value)
    {


        selected.params.conditions[keyParent].target = value
        Model.onEditElement(selected)
        setSelected({...selected})

        //faz os caminhos
        const ElementsFilter = elements.filter( obj => obj.source !== selected.id )

        console.log( ElementsFilter )

        selected.params.conditions.map((row, key) => {

            var newConnect = { 
                id: `e${selected.id}-${row.target}`,
                source: selected.id, 
                target: row.target, 
                arrowHeadType: 'arrowclosed' 
            }

            ElementsFilter.push(newConnect)

        })

        console.log( ElementsFilter )

        Model.setElements(ElementsFilter)

    }

    function handleSetExceptionTarget(target)
    {

        selected.params.exception = target
        Model.onEditElement(selected)
        setSelected({...selected})

    }

    return (
        <div className="conditions">

            { selected.params.conditions.map((row, key) =>
            <div className={`condition`} key={key}>

                { row.all.map((condition, conditionKey) =>

                <>

                    { row.all.length > 1 && conditionKey > 0 && 
                        <div className={`conditionAnd ${ !condition.if || (condition.condition !== 'existe' && condition.condition !== 'naoExiste') || !condition.condition ? condition.value ? 'success' : 'error' : 'success'} `}>
                            <p>E</p>
                        </div>
                    }

                    <div className={`if ${ !condition.if || (condition.condition !== 'existe' && condition.condition !== 'naoExiste') || !condition.condition ? condition.value ? 'success' : 'error' : 'success'} `} key={conditionKey}>

                        <div className="ifCondition">

                            <select 
                                onChange={(e) => handleEditCondition(key, conditionKey, 'if', e.target.value)} 
                                placeholder="Se"
                                value={condition.if}
                            >
                                <option value="">Se</option>
                                <option value="respostaUsuario">Resposta do usuário</option>
                            </select>

                            <select 
                                onChange={(e) => handleEditCondition(key, conditionKey, 'condition', e.target.value)}
                                placeholder="Condição"
                                value={condition.condition}
                            >
                                <option value="">Condição</option>
                                <option value="existe">Existe</option>
                                <option value="igual">Igual a</option>
                                <option value="diferente">Diferente de</option>
                                <option value="contem">Contém</option>
                                <option value="comeco">Começa com</option>
                                <option value="termino">Termina com</option>
                                <option value="maior">Maior que</option>
                                <option value="menor">Menor que</option>
                                <option value="maiorIgual">Maior ou igual que</option>
                                <option value="menorIgual">Menor ou igual que</option>
                                {/* <option value="parecido">Parecido com</option>
                                <option value="inexistente">Não existe</option> */}
                            </select>
                        
                        </div>

                        { condition.condition && ( condition.condition === 'igual' || condition.condition === 'diferente' || condition.condition === 'contem' || condition.condition === 'comeco' || condition.condition === 'termino' || condition.condition === 'parecido' ) && 
                            <div className="tags">
                                <input 
                                    onChange={(e) => handleEditCondition(key, conditionKey, 'value', e.target.value)}
                                    placeholder="Valores separados por virgula"
                                    value={condition.value}
                                    className="target"
                                />
                                <div className="list">
                                    { condition.value && condition.value.split(',').map((tag, tagKey) =>
                                        <div className="tag" key={tagKey} onClick={() => handleEditCondition(key, conditionKey, 'value', condition.value.filter( obj => obj !== tag ))}>
                                            <span>{tag}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        }

                        { condition.condition && ( condition.condition === 'maior' || condition.condition === 'menor' || condition.condition === 'maiorIgual' || condition.condition === 'menorIgual' ) && 
                            <input 
                                onChange={(e) => handleEditCondition(key, conditionKey, 'value', e.target.value)}
                                placeholder="Valor"
                                type="number"
                                value={condition.value}
                                className="target"
                            />
                        }

                        <div className="warning">
                            <span>Condição incorreta.</span>
                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M11.953 2C6.465 2 2 6.486 2 12s4.486 10 10 10s10-4.486 10-10S17.493 2 11.953 2zM12 20c-4.411 0-8-3.589-8-8s3.567-8 7.953-8C16.391 4 20 7.589 20 12s-3.589 8-8 8z" fill="#626262"/><path d="M11 7h2v7h-2zm0 8h2v2h-2z" fill="#626262"/><rect x="0" y="0" width="24" height="24" fill="rgba(0, 0, 0, 0)" /></svg>
                        </div>

                        <button className="removeAnd" onClick={() => handleRemoveConditionAnd(key, conditionKey)}>
                            <IconButton icon="del"/>
                        </button>

                    </div>
                </>
                )}

                <div className="newAnd">
                    <button onClick={() => handleNewConditionAnd(key)}>
                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"><path d="M696 480H544V328c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v152H328c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h152v152c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V544h152c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8z" fill="#626262"/><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448s448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372s372 166.6 372 372s-166.6 372-372 372z" fill="#626262"/><rect x="0" y="0" width="1024" height="1024" fill="rgba(0, 0, 0, 0)" /></svg>
                    </button>
                </div>

                <label className="targetDialog">
                    <span>Ir para</span>
                    <select 
                        value={row.target}
                        onChange={(e) => handleEditConditionTarget(key, e.target.value)}
                    >
                        <option>Ir para</option>
                        { elements.filter(obj => obj.data ).map((row, key) =>
                            <option key={key} value={row.id}>{row.params.nameDialog}</option>
                        )}
                    </select>
                </label>

                <div className="removeCondition">
                    <button className="button-zig column full danger" onClick={() => handleRemoveCondition(key)}>Deletar condição</button>
                </div>

            </div>
            )}

            <button className="button-zig column full secondary" onClick={handleNewCondition}>Adicionar condição</button>

            <div className={`exception-target`}>
                <h5>Saida padrão</h5>
                <p>Sempre que o usuário sair do fluxo criado, vai ser levado a esse diálogo.</p>
                <select 
                    value={selected.params.exception}
                    onChange={(e) => handleSetExceptionTarget(e.target.value)}
                >
                    <option value="">Selecione</option>
                    { elements.filter(obj => obj.data ).map((row, key) =>
                        <option key={key} value={row.id}>{row.params.nameDialog}</option>
                    )}
                </select>
            </div>

        </div>
    )
}
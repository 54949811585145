import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { api } from '../../Api/app';
import datesFormater from '../../Helper/DatesFormater';
import messageStore from '../../Store/MessageStore';

export default function CronManual(props) {
    const [status, setStatus] = useState(false);

    const [logs, setLogs] = useState([]);

    const [loading, setLoading] = useState(false);

    const [scrollAutomatico, setScrollAutomatico] = useState(true);

    const [brokerTipo, setBrokerTipo] = useState('whatsapp');

    const [timeReload, setTimeReload] = useState(2000);

    useEffect(() => {
        if (status === true) reloadCron();
    }, [status]);

    useEffect(() => {
        console.log('logs state', status);

        if (status === true) {
            setTimeout(function () {
                reloadCron();
            }, timeReload);
        }
    }, [logs]);

    async function reloadCron() {
        try {
            setLoading(true);

            const response = await api.get('cron/' + brokerTipo, {
                headers: {
                    Authorization:
                        'Bearer eyJhbGciOiJIUzI1NiJ9.NjFmOThkNzdkYjUxMWMxMTkwMGY1MWNl.SU2KHPWDk4ajhqUuviwqm4xqPS9GiHAPN8M04xOQRgE',
                },
            });

            const newLog = {
                delay: timeReload,
                broker: brokerTipo,
                data: new Date(),
                responses: response.data.responseMany,
            };

            setLoading(false);

            logs.push(newLog);

            setLogs([...logs]);

            if (scrollAutomatico) gotoBottom();
        } catch (e) {
            setStatus(false);
            setLoading(false);
            console.log(e);
            messageStore.addError('Erro de rotina, consultar logs.');
        }
    }

    function gotoBottom() {
        var element = document.getElementById('scrolling');
        element.scrollTop = element.scrollHeight - element.clientHeight;
    }

    return (
        <div id="logs-box">
            <div className="title">
                <div>
                    <button
                        className="button-zig primary"
                        onClick={() => setStatus(!status)}
                    >
                        {status ? 'Pause' : 'Play'}
                    </button>

                    {loading === true && (
                        <i className="fa fa-spin fa-spinner"></i>
                    )}
                </div>

                <select
                    onChange={(e) => setBrokerTipo(e.target.value)}
                    value={brokerTipo}
                >
                    <option value="whatsapp">Whatsapp</option>
                    <option value="sms">SMS</option>
                    <option value="email">Email</option>
                </select>

                <select
                    onChange={(e) => setTimeReload(e.target.value)}
                    value={timeReload}
                >
                    <option value="1000">1s</option>
                    <option value="2000">2s</option>
                    <option value="3000">3s</option>
                    <option value="4000">4s</option>
                    <option value="5000">5s</option>
                    <option value="6000">6s</option>
                    <option value="7000">7s</option>
                    <option value="8000">8s</option>
                    <option value="9000">9s</option>
                    <option value="10000">10s</option>
                    <option value="11000">11s</option>
                    <option value="12000">12s</option>
                    <option value="13000">13s</option>
                    <option value="14000">14s</option>
                    <option value="15000">15s</option>
                    <option value="16000">16s</option>
                    <option value="17000">17s</option>
                    <option value="18000">18s</option>
                    <option value="19000">19s</option>
                    <option value="20000">20s</option>
                </select>

                <button
                    className="button-zig secondary"
                    onClick={() => setScrollAutomatico(!scrollAutomatico)}
                >
                    {scrollAutomatico
                        ? 'Scroll automatico ativo'
                        : 'Scroll automatico desativado'}
                </button>
            </div>

            <div className="logs" id="scrolling">
                {logs.map((row, key) => (
                    <div className="message" key={key}>
                        <div className="date">
                            {datesFormater.dateBRSecond(row.data)}
                        </div>

                        <div className="info">Broker: {row.broker}</div>
                        <div className="info">Delay: {row.delay}ms</div>

                        <ul>
                            {row.responses.length > 0 &&
                                row.responses.map((response, responseKey) => (
                                    <li key={responseKey}>{response}</li>
                                ))}
                            {row.responses.length == 0 && (
                                <li>Nenhum envio na fila.</li>
                            )}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );
}

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

//scss
import './LayoutPrivate.scss';
//images
import Logo from '../../Images/txty_logofinal-branca.png';
import MaleSupport from '../../Images/male_support.png';
//images_icons
import IconDashboard from '../../Images/icos/icon_dashboard.png';
import IconCampgain from '../../Images/icos/icon_campgain.png';
import IconTemplate from '../../Images/icos/icon_template.png';
import IconAnalytics from '../../Images/icos/icon_analytics.png';
import IconConfiguration from '../../Images/icos/icon_configuration.png';
import IconTicket from '../../Images/icos/icon_ticket.png';
import IconFaq from '../../Images/icos/icon_faq.png';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import { Icon, InlineIcon } from '@iconify/react';
import usersLight from '@iconify-icons/ph/users-light';
import sendAlt from '@iconify-icons/carbon/send-alt';
import { api } from '../../Api/app';
import { useGlobal } from '../../contexts/GlobalContext';

export default function LayoutPrivate(props) {
    const history = useHistory();
    const { user } = useGlobal();

    const [page, setPage] = useState(
        '/' + props.location.pathname.split('/')[1]
    );
    const [pageTwo, setPageTwo] = useState(
        '/' + props.location.pathname.split('/')[2]
    );
    const [backend, setBackend] = useState({});

    useEffect(() => {
        setPage('/' + props.location.pathname.split('/')[1]);
        setPageTwo('/' + props.location.pathname.split('/')[2]);
        scrollTop();
        getVersionBackend();
    }, [props]);

    function scrollTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    async function getVersionBackend() {
        try {
            const response = await api.get('');
            setBackend({ ...response.data });
        } catch (err) {
            console.log('não foi possivel resgatar o backend.');
        }
    }

    return (
        <div id="zigzag" data-account={user.accountId}>
            <nav className="nav-left">
                <div className="logo">
                    <img alt="Logo" src={Logo} />
                </div>

                {/* <div className="link">
                    <Link className={page === '/nova-campanha' ? 'active' : 'no-active'} to="/nova-campanha">
                        <b>+</b>
                        <span>Nova campanha</span>
                    </Link>
                </div> */}

                <ul>
                    <li>
                        <Link
                            className={page === '/' ? 'active' : 'no-active'}
                            to="/"
                        >
                            <img alt="Icone dashboard" src={IconDashboard} />
                            <span className="text">Dashboard</span>
                            <span className="bg"></span>
                        </Link>
                    </li>

                    <li style={{ marginTop: '10px' }}>
                        <span>Envios</span>
                    </li>

                    {user.account.brokers.filter(
                        (obj) => obj.tipo === 'Whatsapp'
                    ).length > 0 && (
                        <li>
                            <Link
                                className={
                                    page === '/nova-campanha' &&
                                    pageTwo === '/Whatsapp'
                                        ? 'active'
                                        : 'no-active'
                                }
                                to="/nova-campanha/Whatsapp"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                    focusable="false"
                                    width="1em"
                                    height="1em"
                                    preserveAspectRatio="xMidYMid meet"
                                    viewBox="0 0 256 256"
                                >
                                    <path
                                        d="M128 28a100.026 100.026 0 0 0-86.885 149.54l-9.005 31.516a12 12 0 0 0 14.835 14.834l31.517-9.004A100.007 100.007 0 1 0 128 28zm0 192a91.87 91.87 0 0 1-46.952-12.867a3.995 3.995 0 0 0-3.144-.408l-33.157 9.473a4 4 0 0 1-4.944-4.945l9.472-33.156a4.001 4.001 0 0 0-.408-3.144A92.01 92.01 0 1 1 128 220zm50.512-73.457l-20.46-11.691a12.01 12.01 0 0 0-12.127.129l-13.807 8.284a44.042 44.042 0 0 1-19.382-19.383l8.284-13.807a12.01 12.01 0 0 0 .128-12.127l-11.69-20.46A10.916 10.916 0 0 0 100 72a32.008 32.008 0 0 0-32 31.88A84.001 84.001 0 0 0 151.999 188h.12A32.008 32.008 0 0 0 184 156a10.913 10.913 0 0 0-5.488-9.457zM152.108 180H152a76 76 0 0 1-76-76.107A23.997 23.997 0 0 1 100 80a2.9 2.9 0 0 1 2.512 1.457l11.69 20.461a4.004 4.004 0 0 1-.042 4.042l-9.39 15.648a3.999 3.999 0 0 0-.218 3.699a52.041 52.041 0 0 0 26.142 26.141a3.997 3.997 0 0 0 3.699-.218l15.647-9.39a4.006 4.006 0 0 1 4.043-.043l20.46 11.692A2.897 2.897 0 0 1 176 156a23.997 23.997 0 0 1-23.892 24z"
                                        fill="#626262"
                                    />
                                    <rect
                                        x="0"
                                        y="0"
                                        width="256"
                                        height="256"
                                        fill="rgba(0, 0, 0, 0)"
                                    />
                                </svg>
                                <span className="text">Enviar Whatsapp</span>
                                <span className="bg"></span>
                            </Link>
                        </li>
                    )}

                    {user.account.brokers.filter((obj) => obj.tipo === 'SMS')
                        .length > 0 && (
                        <li>
                            <Link
                                className={
                                    page === '/nova-campanha' &&
                                    pageTwo === '/SMS'
                                        ? 'active'
                                        : 'no-active'
                                }
                                to="/nova-campanha/SMS"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                    focusable="false"
                                    width="1em"
                                    height="1em"
                                    preserveAspectRatio="xMidYMid meet"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        d="M3 20.586L6.586 17H18a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14.586zM3 22H2V6a3 3 0 0 1 3-3h13a3 3 0 0 1 3 3v9a3 3 0 0 1-3 3H7l-4 4zM6.5 9a1.5 1.5 0 1 1 0 3a1.5 1.5 0 0 1 0-3zm0 1a.5.5 0 1 0 0 1a.5.5 0 0 0 0-1zm5-1a1.5 1.5 0 1 1 0 3a1.5 1.5 0 0 1 0-3zm0 1a.5.5 0 1 0 0 1a.5.5 0 0 0 0-1zm5-1a1.5 1.5 0 1 1 0 3a1.5 1.5 0 0 1 0-3zm0 1a.5.5 0 1 0 0 1a.5.5 0 0 0 0-1z"
                                        fill="#626262"
                                    />
                                    <rect
                                        x="0"
                                        y="0"
                                        width="24"
                                        height="24"
                                        fill="rgba(0, 0, 0, 0)"
                                    />
                                </svg>
                                <span className="text">Enviar SMS</span>
                                <span className="bg"></span>
                            </Link>
                        </li>
                    )}

                    <li>
                        <Link
                            className={
                                page === '/nova-campanha' && pageTwo === '/Voz'
                                    ? 'active'
                                    : 'no-active'
                            }
                            to="/nova-campanha/Voz"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                aria-hidden="true"
                                role="img"
                                width="1em"
                                height="1em"
                                preserveAspectRatio="xMidYMid meet"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    d="M5 7h2v10H5V7zm-4 3h2v4H1v-4zm8-8h2v18H9V2zm4 2h2v18h-2V4zm4 3h2v10h-2V7zm4 3h2v4h-2v-4z"
                                    fill="currentColor"
                                />
                            </svg>
                            <span className="text">Enviar Voz</span>
                            <span className="bg"></span>
                        </Link>
                    </li>

                    {user.account?.brokers.filter((obj) => obj.tipo === 'Email')
                        .length > 0 && (
                        <li>
                            <Link
                                className={
                                    page === '/nova-campanha' &&
                                    pageTwo === '/Email'
                                        ? 'active'
                                        : 'no-active'
                                }
                                to="/nova-campanha/Email"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                    focusable="false"
                                    width="1em"
                                    height="1em"
                                    preserveAspectRatio="xMidYMid meet"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        d="M5 5h13a3 3 0 0 1 3 3v9a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V8a3 3 0 0 1 3-3zm0 1c-.488 0-.936.175-1.283.466L11.5 11.52l7.783-5.054A1.992 1.992 0 0 0 18 6H5zm6.5 6.712L3.134 7.28A1.995 1.995 0 0 0 3 8v9a2 2 0 0 0 2 2h13a2 2 0 0 0 2-2V8c0-.254-.047-.497-.134-.72L11.5 12.711z"
                                        fill="#626262"
                                    />
                                    <rect
                                        x="0"
                                        y="0"
                                        width="24"
                                        height="24"
                                        fill="rgba(0, 0, 0, 0)"
                                    />
                                </svg>
                                <span className="text">Enviar E-mail</span>
                                <span className="bg"></span>
                            </Link>
                        </li>
                    )}

                    <li style={{ marginTop: '10px' }}>
                        <span>Complementos de envios</span>
                    </li>

                    <li>
                        <Link
                            className={
                                page === '/gestao-contatos'
                                    ? 'active'
                                    : 'no-active'
                            }
                            to="/gestao-contatos"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                aria-hidden="true"
                                focusable="false"
                                width="1em"
                                height="1em"
                                preserveAspectRatio="xMidYMid meet"
                                viewBox="0 0 32 32"
                            >
                                <path
                                    d="M12 4a5 5 0 1 1-5 5a5 5 0 0 1 5-5m0-2a7 7 0 1 0 7 7a7 7 0 0 0-7-7z"
                                    fill="#626262"
                                />
                                <path
                                    d="M22 30h-2v-5a5 5 0 0 0-5-5H9a5 5 0 0 0-5 5v5H2v-5a7 7 0 0 1 7-7h6a7 7 0 0 1 7 7z"
                                    fill="#626262"
                                />
                                <path d="M22 4h10v2H22z" fill="#626262" />
                                <path d="M22 9h10v2H22z" fill="#626262" />
                                <path d="M22 14h7v2h-7z" fill="#626262" />
                                <rect
                                    x="0"
                                    y="0"
                                    width="32"
                                    height="32"
                                    fill="rgba(0, 0, 0, 0)"
                                />
                            </svg>
                            <span className="text">Gestão de contatos</span>
                            <span className="bg"></span>
                        </Link>
                    </li>

                    {user.account.brokers.filter(
                        (obj) => obj.tipo === 'Whatsapp' && obj.oficial === true
                    ).length > 0 && (
                        <li>
                            <Link
                                className={
                                    page === '/templates-oficiais'
                                        ? 'active'
                                        : 'no-active'
                                }
                                to="/templates-oficiais"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                    focusable="false"
                                    width="1em"
                                    height="1em"
                                    preserveAspectRatio="xMidYMid meet"
                                    viewBox="0 0 256 256"
                                >
                                    <path
                                        d="M211.994 96A84 84 0 1 0 76 161.9V240a4 4 0 0 0 5.79 3.578l46.202-23.106l46.219 23.106A4 4 0 0 0 180 240v-78.108A83.872 83.872 0 0 0 211.994 96zM172 233.528l-42.219-21.106a4.003 4.003 0 0 0-3.578 0L84 233.527V167.52a83.783 83.783 0 0 0 88-.007zM127.994 172a76 76 0 1 1 76-76a76.086 76.086 0 0 1-76 76zm0-128a52 52 0 1 0 52 52a52.059 52.059 0 0 0-52-52zm0 96a44 44 0 1 1 44-44a44.05 44.05 0 0 1-44 44z"
                                        fill="#626262"
                                    />
                                </svg>
                                <span className="text">Templates oficiais</span>
                                <span className="bg"></span>
                            </Link>
                        </li>
                    )}

                    {user.account.brokers.filter(
                        (obj) => obj.tipo === 'Whatsapp'
                    ).length > 0 && (
                        <li>
                            <Link
                                className={
                                    page === '/chat' && pageTwo === '/undefined'
                                        ? 'active'
                                        : 'no-active'
                                }
                                to="/chat"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                    role="img"
                                    width="1em"
                                    height="1em"
                                    preserveAspectRatio="xMidYMid meet"
                                    viewBox="0 0 16 16"
                                >
                                    <g fill="currentColor">
                                        <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333L6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                    </g>
                                </svg>
                                <span className="text">Chat</span>
                                <span className="bg"></span>
                            </Link>
                        </li>
                    )}

                    <li>
                        <Link
                            className={
                                page === '/chat' && pageTwo === '/web'
                                    ? 'active'
                                    : 'no-active'
                            }
                            to="/chat/web"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                aria-hidden="true"
                                role="img"
                                width="1.32em"
                                height="1em"
                                preserveAspectRatio="xMidYMid meet"
                                viewBox="0 0 42 32"
                            >
                                <g fill="currentColor">
                                    <path d="M35.652 14.023a.5.5 0 0 0-.303.953C39.257 16.221 41 18.078 41 21c0 2.599-2.371 4.616-3.783 5.588A.5.5 0 0 0 37 27v3.823a7.953 7.953 0 0 1-3.126-2.155a.505.505 0 0 0-.468-.159c-.285.055-.576.133-.871.212c-.51.137-1.036.279-1.535.279c-2.568 0-4.366-.552-6.204-1.903a.5.5 0 0 0-.593.806C26.23 29.393 28.199 30 31 30c.631 0 1.223-.159 1.795-.313c.178-.049.355-.097.53-.138c1.869 1.974 3.983 2.423 4.075 2.441a.495.495 0 0 0 .416-.103A.498.498 0 0 0 38 31.5v-4.239c2.582-1.841 4-4.057 4-6.261c0-3.381-2.017-5.598-6.348-6.977z" />
                                    <path d="M33 13.5C33 5.804 25.982 0 16.677 0C7.325 0 0 5.931 0 13.502c0 4.539 3.211 7.791 6 9.759v6.636a.502.502 0 0 0 .705.456c.146-.065 3.559-1.616 6.479-4.809c1.265.235 2.696.461 3.994.461C26.641 26.005 33 20.979 33 13.5zM17.177 25.005c-1.31 0-2.799-.251-4.083-.496a.507.507 0 0 0-.468.159C10.576 26.98 8.167 28.449 7 29.082V23a.5.5 0 0 0-.217-.412C4.145 20.773 1 17.725 1 13.502C1 6.491 7.886 1 16.677 1C25.413 1 32 6.374 32 13.5c0 6.882-5.957 11.505-14.823 11.505z" />
                                    <path d="M16.5 11.5c-1.103 0-2 .897-2 2s.897 2 2 2s2-.897 2-2s-.897-2-2-2zm0 3a1.001 1.001 0 0 1 0-2a1.001 1.001 0 0 1 0 2z" />
                                    <path d="M23.5 11.5c-1.103 0-2 .897-2 2s.897 2 2 2s2-.897 2-2s-.897-2-2-2zm0 3a1.001 1.001 0 0 1 0-2a1.001 1.001 0 0 1 0 2z" />
                                    <path d="M9.5 11.595c-1.103 0-2 .897-2 2s.897 2 2 2s2-.897 2-2s-.897-2-2-2zm0 3a1.001 1.001 0 0 1 0-2a1.001 1.001 0 0 1 0 2z" />
                                </g>
                            </svg>
                            <span className="text">Chat web</span>
                            <span className="bg"></span>
                        </Link>
                    </li>

                    <li>
                        <Link
                            className={
                                page === '/higienizacao'
                                    ? 'active'
                                    : 'no-active'
                            }
                            to="/higienizacao"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                aria-hidden="true"
                                focusable="false"
                                width="1em"
                                height="1em"
                                preserveAspectRatio="xMidYMid meet"
                                viewBox="0 0 32 32"
                            >
                                <path d="M26 20h-6v-2h6z" fill="#626262" />
                                <path d="M30 28h-6v-2h6z" fill="#626262" />
                                <path d="M28 24h-6v-2h6z" fill="#626262" />
                                <path
                                    d="M17.003 20a4.895 4.895 0 0 0-2.404-4.173L22 3l-1.73-1l-7.577 13.126a5.699 5.699 0 0 0-5.243 1.503C3.706 20.24 3.996 28.682 4.01 29.04a1 1 0 0 0 1 .96h14.991a1 1 0 0 0 .6-1.8c-3.54-2.656-3.598-8.146-3.598-8.2zm-5.073-3.003A3.11 3.11 0 0 1 15.004 20c0 .038.002.208.017.469l-5.9-2.624a3.8 3.8 0 0 1 2.809-.848zM15.45 28A5.2 5.2 0 0 1 14 25h-2a6.5 6.5 0 0 0 .968 3h-2.223A16.617 16.617 0 0 1 10 24H8a17.342 17.342 0 0 0 .665 4H6c.031-1.836.29-5.892 1.803-8.553l7.533 3.35A13.025 13.025 0 0 0 17.596 28z"
                                    fill="#626262"
                                />
                                <rect
                                    x="0"
                                    y="0"
                                    width="32"
                                    height="32"
                                    fill="rgba(0, 0, 0, 0)"
                                />
                            </svg>
                            <span className="text">Higienizar CSV</span>
                            <span className="bg"></span>
                        </Link>
                    </li>

                    {user.account.brokers.filter(
                        (obj) => obj.tipo === 'Whatsapp'
                    ).length > 0 && (
                        <li>
                            <Link
                                className={
                                    page === '/chatbot' ? 'active' : 'no-active'
                                }
                                to="/chatbot"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                    focusable="false"
                                    width="1em"
                                    height="1em"
                                    preserveAspectRatio="xMidYMid meet"
                                    viewBox="0 0 15 15"
                                >
                                    <g fill="none">
                                        <path
                                            d="M9 2.5V2v.5zm-3 0V3v-.5zm6.856 9.422l-.35-.356l-.205.2l.07.277l.485-.12zM13.5 14.5l-.121.485a.5.5 0 0 0 .606-.606l-.485.12zm-4-1l-.354-.354l-.624.625l.857.214l.121-.485zm.025-.025l.353.354a.5.5 0 0 0-.4-.852l.047.498zM.5 8H0h.5zM7 0v2.5h1V0H7zm2 2H6v1h3V2zm6 6a6 6 0 0 0-6-6v1a5 5 0 0 1 5 5h1zm-1.794 4.279A5.983 5.983 0 0 0 15 7.999h-1a4.983 4.983 0 0 1-1.495 3.567l.701.713zm.78 2.1L13.34 11.8l-.97.242l.644 2.578l.97-.242zm-4.607-.394l4 1l.242-.97l-4-1l-.242.97zm-.208-.863l-.025.024l.708.707l.024-.024l-.707-.707zM9 14c.193 0 .384-.01.572-.027l-.094-.996A5.058 5.058 0 0 1 9 13v1zm-3 0h3v-1H6v1zM0 8a6 6 0 0 0 6 6v-1a5 5 0 0 1-5-5H0zm6-6a6 6 0 0 0-6 6h1a5 5 0 0 1 5-5V2zm1.5 6A1.5 1.5 0 0 1 6 6.5H5A2.5 2.5 0 0 0 7.5 9V8zM9 6.5A1.5 1.5 0 0 1 7.5 8v1A2.5 2.5 0 0 0 10 6.5H9zM7.5 5A1.5 1.5 0 0 1 9 6.5h1A2.5 2.5 0 0 0 7.5 4v1zm0-1A2.5 2.5 0 0 0 5 6.5h1A1.5 1.5 0 0 1 7.5 5V4zm0 8c1.064 0 2.042-.37 2.813-.987l-.626-.78c-.6.48-1.359.767-2.187.767v1zm-2.813-.987c.77.617 1.75.987 2.813.987v-1a3.483 3.483 0 0 1-2.187-.767l-.626.78z"
                                            fill="#626262"
                                        />
                                    </g>
                                    <rect
                                        x="0"
                                        y="0"
                                        width="15"
                                        height="15"
                                        fill="rgba(0, 0, 0, 0)"
                                    />
                                </svg>
                                <span className="text">Chatbot whatsapp</span>
                                <span className="bg"></span>
                            </Link>
                        </li>
                    )}

                    <li>
                        <Link
                            className={
                                page === '/templates' ? 'active' : 'no-active'
                            }
                            to="/templates"
                        >
                            <img alt="Icone template" src={IconTemplate} />
                            <span className="text">Landing pages</span>
                            <span className="bg"></span>
                        </Link>
                    </li>

                    <li style={{ marginTop: '10px' }}>
                        <span>Gerenciamento</span>
                    </li>

                    <li>
                        <Link
                            className={
                                page === '/campanhas' ? 'active' : 'no-active'
                            }
                            to="/campanhas"
                        >
                            <img alt="Icone campanha" src={IconCampgain} />
                            <span className="text">Minhas Campanhas</span>
                            <span className="bg"></span>
                        </Link>
                    </li>

                    {/* <li>
                        <Link className={page === '/templates' ? 'active' : 'no-active'} to="/templates/">
                            <img alt="Icone template" src={IconTemplate}/>
                            <span className="text">Templates</span>
                            <span className="bg"></span>
                        </Link>
                    </li> */}

                    <li>
                        <Link
                            className={
                                page === '/relatorio-chat'
                                    ? 'active'
                                    : 'no-active'
                            }
                            to="/relatorio-chat"
                        >
                            <img alt="Icone analytics" src={IconAnalytics} />
                            <span className="text">Relatório de chat</span>
                            <span className="bg"></span>
                        </Link>
                    </li>
                    {/* 
                    <li>
                        <Link className={page === '/relatorio-chatbot' ? 'active' : 'no-active'} to="/relatorio-chatbot">
                            <img alt="Icone analytics" src={IconAnalytics}/>
                            <span className="text">Relatório de chatbot</span>
                            <span className="bg"></span>
                        </Link>
                    </li> */}

                    <li>
                        <Link
                            className={
                                page === '/envios' ? 'active' : 'no-active'
                            }
                            to="/envios"
                        >
                            <img alt="Icone analytics" src={IconAnalytics} />
                            <span className="text">Visualizar envios</span>
                            <span className="bg"></span>
                        </Link>
                    </li>

                    <li>
                        <Link
                            className={
                                page === '/configuracoes'
                                    ? 'active'
                                    : 'no-active'
                            }
                            to="/configuracoes"
                        >
                            <img
                                alt="Icone configurações"
                                src={IconConfiguration}
                            />
                            <span className="text">Configurações</span>
                            <span className="bg"></span>
                        </Link>
                    </li>

                    {/* <li>
                        <span>Suporte</span>
                    </li> */}

                    <li style={{ display: 'none' }}>
                        <Link
                            className={
                                page === '/tickets' ? 'active' : 'no-active'
                            }
                            to="/tickets"
                        >
                            <img alt="Icone Ticket" src={IconTicket} />
                            <span className="text">Tickets</span>
                            <span className="bg"></span>
                        </Link>
                    </li>

                    <li style={{ display: 'none' }}>
                        <Link
                            className={page === '/faq' ? 'active' : 'no-active'}
                            to="/faq"
                        >
                            <img alt="Icone FAQ" src={IconFaq} />
                            <span className="text">FAQ</span>
                            <span className="bg"></span>
                        </Link>
                    </li>

                    {user.isAdmin && (
                        <>
                            <li>
                                <span>Administração</span>
                            </li>

                            <li>
                                <a target="_blank" href="/monitoramento">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        aria-hidden="true"
                                        role="img"
                                        width="1em"
                                        height="1em"
                                        preserveAspectRatio="xMidYMid meet"
                                        viewBox="0 0 32 32"
                                    >
                                        <path
                                            d="M24 21h2v5h-2z"
                                            fill="currentColor"
                                        />
                                        <path
                                            d="M20 16h2v10h-2z"
                                            fill="currentColor"
                                        />
                                        <path
                                            d="M11 26a5.006 5.006 0 0 1-5-5h2a3 3 0 1 0 3-3v-2a5 5 0 0 1 0 10z"
                                            fill="currentColor"
                                        />
                                        <path
                                            d="M28 2H4a2.002 2.002 0 0 0-2 2v24a2.002 2.002 0 0 0 2 2h24a2.003 2.003 0 0 0 2-2V4a2.002 2.002 0 0 0-2-2zm0 9H14V4h14zM12 4v7H4V4zM4 28V13h24l.002 15z"
                                            fill="currentColor"
                                        />
                                    </svg>
                                    <span className="text">Monitoramento</span>
                                    <span className="bg"></span>
                                </a>
                            </li>

                            <li>
                                <Link
                                    className={
                                        page === '/adm-contas'
                                            ? 'active'
                                            : 'no-active'
                                    }
                                    to="/adm-contas"
                                >
                                    <Icon icon={usersLight} />

                                    <span className="text">
                                        Gerenciar contas
                                    </span>
                                    <span className="bg"></span>
                                </Link>
                            </li>

                            <li>
                                <Link
                                    className={
                                        page === '/adm-brokers'
                                            ? 'active'
                                            : 'no-active'
                                    }
                                    to="/adm-brokers"
                                >
                                    <Icon icon={sendAlt} />

                                    <span className="text">
                                        Gerenciar brokers
                                    </span>
                                    <span className="bg"></span>
                                </Link>
                            </li>
                        </>
                    )}
                </ul>

                <div className="suport" style={{ display: 'none' }}>
                    <Link
                        className={page === '/faq' ? 'active' : 'no-active'}
                        to="/faq"
                    >
                        <span className="title">Centro de Ajuda</span>
                        <span className="description">
                            Visite o nosso centro de ajuda
                        </span>
                        <span className="link">Visitar</span>

                        <img alt="Suporet" src={MaleSupport} />
                    </Link>
                </div>

                <span
                    className="version"
                    style={{
                        color: '#FFF',
                        fontSize: 11,
                        paddingBottom: 10,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    v{backend.Version}
                </span>
            </nav>

            <section className="section-private">
                <header>
                    <div className="left">
                        <div className="name-client">{user.account.nome}</div>
                    </div>

                    <div className="right">
                        <div className="notification">
                            <button
                                onClick={() => history.push('/notificacoes')}
                            >
                                {props.logs.total > 0 && (
                                    <span id="total-notification">
                                        {props.logs.total}
                                    </span>
                                )}
                            </button>
                        </div>

                        <div className="menu-user">
                            <button>
                                <i
                                    id="img-profile"
                                    style={{
                                        backgroundImage:
                                            'url(' + user.foto + ')',
                                    }}
                                ></i>
                                <span id="name-user">{user.nome}</span>
                            </button>

                            <div className="submenu">
                                <Link to="/configuracoes">Configurações</Link>
                                {/* <Link to="/configuracoes/dados-pagamento">Dados de cobrança</Link> */}
                                <Link to="/notificacoes">Notificações</Link>
                                <Link to="/login">Sair</Link>
                            </div>
                        </div>
                    </div>
                </header>

                <div className="content">{props.children}</div>
            </section>
        </div>
    );
}

import React, { useEffect, useState } from 'react'
import { api } from '../../Api/app'
import H1Page from '../../Components/Layout/H1Page'
import {
    Chart,
    ChartTitle,
    ChartLegend,
    ChartTooltip,
    ChartSeries,
    ChartSeriesItem,
    ChartSeriesLabels,
  } from "@progress/kendo-react-charts";
import Datetime from '../../Helper/Datetime'
import "./RelatorioChatbot.scss"

export default function RelatorioChatbot(props){

    const [dataInicial, setDataInicial] = useState(Datetime.yesterday())
    const [dataFinal, setDataFinal] = useState(Datetime.today())
    const [chatbotId, setChatbotId] = useState('')
    const [data, setData] = useState({})
    const [chatbots, setChatbots] = useState([])

    useEffect(() => {

        api.get('chatbots').then(response => {

            setChatbots(response.data)

        })

        getData()

    }, [])

    useEffect(() => {

        getData()

    }, [dataInicial, dataFinal, chatbotId, props])

    async function getData()
    {

        const response = await api.get(`relatorios/chatbot?chatbotId=${chatbotId}&dataInicial=${dataInicial}&dataFinal=${dataFinal}`)

        const responseData = response.data[0]

        const chart = []

        chart.push({
            "stat": "Enviados",
            "count": responseData.disparos,
            "color": "#0e5a7e"
        })

        responseData.variaveis.map((row, key) => {

            let r = Math.random() * 255;
            let g = Math.random() * 255;
            let b = Math.random() * 255;

            chart.push({
                "stat": row.pergunta,
                "count": row.respostas.length,
                "color": `rgba(${r},${g},${b}, 1)`
            })

        })

        chart.push({
            "stat": "Atendimentos",
            "count": responseData.atendimento,
            "color": "#2185b4"
        })

        responseData.chart = chart

        console.log( responseData )

        setData(responseData)

    }

    const tooltipRender = (props) => {
        if (props.point) {
          return <div className="tooltip-chat">{props.point.dataItem.stat}</div>;
        }
    }
    
    return(
        
        <div id="relatorio-chat" className="page">

            <div className="h1-button">

                <H1Page nome={"Relatórios de chatbot"}/>
                
            </div>

            <div className="filter-page">

                <label>
                    <select value={chatbotId} onChange={(e) => setChatbotId(e.target.value)}>
                        <option value="">Selecione um chatbot</option>
                        { chatbots.map((row, key) => 
                            <option value={row.id}>{row.nome}</option>
                        )}
                    </select>
                </label>

                <label>
                    <input onChange={(e) => setDataInicial(e.target.value)} type="date" value={dataInicial}/>
                </label>

                <label>
                    <input onChange={(e) => setDataFinal(e.target.value)} type="date" value={dataFinal}/>
                </label>

            </div>
            
            <div className="chatbots-charts">
                <div className="chart-list">
                    <p>{data.chatbotNome}</p>
                    <Chart
                        style={{
                            width: 1000,
                        }}
                    >
                        <ChartSeries>
                            <ChartSeriesItem
                                type="funnel"
                                data={data.chart}
                                categoryField="stat"
                                field="count"
                                colorField="color"
                            >
                                <ChartSeriesLabels color="white" background="none" format="N0" />

                            </ChartSeriesItem>
                        </ChartSeries>

                        <ChartLegend visible={true} />

                    </Chart>
                </div>
            </div>

        </div>

    )

}
import React from 'react'

export default function Search({search, setSearch})
{

    return(
        <div id="search">

            <label className="input">

                <i className="fa fa-search"></i>
                <input 
                    search={search}
                    onChange={(e) => setSearch(e.target.value)} 
                    placeholder="Pesquisar número"
                />

            </label>

        </div>
    )

}
import React, { useEffect, useState } from 'react';
import { api } from '../../Api/app';
import messageStore from '../../Store/MessageStore';

import './ContactList.scss';

export default function CleanContacts(props) {
    const open = props.open;
    const [progress, setProgress] = useState(false);
    const [porcentagem, setPorcentagem] = useState(0);

    async function handleProcess() {
        try {
            setProgress(true);
            const response = await api.post(
                'contacts-validate/enviar-lote-por-lista',
                {
                    contactListId: props.id,
                }
            );

            if (response.data.error === true)
                throw 'Não foi possivel validar essa lista no momento, tente novamente mais tarde ou contate um administrador.';

            setPorcentagem(response.data.porcentagem);
            if (response.data.stop === false) {
                handleProcess();
            } else {
                messageStore.addSuccess(
                    'Todos os contatos dessa lista foram para uma validação individual, acompanha na aba contatos na coluna Status Higienização.'
                );
                setProgress(false);
            }
        } catch (err) {
            setPorcentagem(0);
            setProgress(false);
            messageStore.addError(err);
        }
    }

    return (
        <div
            id="higienizacao-contatos"
            style={{ display: open ? 'block' : 'none' }}
        >
            <table className="table-default">
                <thead>
                    <tr>
                        <th style={{ whiteSpace: 'nowrap' }}>
                            Higienização de contatos
                        </th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td colSpan={2}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                aria-hidden="true"
                                focusable="false"
                                width="1em"
                                height="1em"
                                preserveAspectRatio="xMidYMid meet"
                                viewBox="0 0 1024 1024"
                            >
                                <path
                                    d="M464 720a48 48 0 1 0 96 0a48 48 0 1 0-96 0zm16-304v184c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V416c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8zm475.7 440l-416-720c-6.2-10.7-16.9-16-27.7-16s-21.6 5.3-27.7 16l-416 720C56 877.4 71.4 904 96 904h832c24.6 0 40-26.6 27.7-48zm-783.5-27.9L512 239.9l339.8 588.2H172.2z"
                                    fill="#626262"
                                />
                                <rect
                                    x="0"
                                    y="0"
                                    width="1024"
                                    height="1024"
                                    fill="rgba(0, 0, 0, 0)"
                                />
                            </svg>{' '}
                            Ao clicar em Higienizar, todos os contatos da sua
                            lista vão passar por uma validação individual. Todos
                            os números serão validados e você pode conferir na
                            aba contatos no Status Higienização.
                        </td>
                    </tr>

                    {progress && (
                        <tr>
                            <td colSpan="2">
                                <div className="process-load-contacts">
                                    <div
                                        style={{
                                            width: porcentagem + '%',
                                        }}
                                    ></div>
                                </div>
                            </td>
                        </tr>
                    )}

                    {progress === false && (
                        <tr>
                            <td colSpan="2">
                                <div className="button-send">
                                    <button
                                        type="button"
                                        className="button-zig primary"
                                        style={{ flexDirection: 'row' }}
                                        onClick={handleProcess}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            aria-hidden="true"
                                            focusable="false"
                                            width="1em"
                                            height="1em"
                                            preserveAspectRatio="xMidYMid meet"
                                            viewBox="0 0 32 32"
                                        >
                                            <path
                                                d="M26 20h-6v-2h6z"
                                                fill="#626262"
                                            />
                                            <path
                                                d="M30 28h-6v-2h6z"
                                                fill="#626262"
                                            />
                                            <path
                                                d="M28 24h-6v-2h6z"
                                                fill="#626262"
                                            />
                                            <path
                                                d="M17.003 20a4.895 4.895 0 0 0-2.404-4.173L22 3l-1.73-1l-7.577 13.126a5.699 5.699 0 0 0-5.243 1.503C3.706 20.24 3.996 28.682 4.01 29.04a1 1 0 0 0 1 .96h14.991a1 1 0 0 0 .6-1.8c-3.54-2.656-3.598-8.146-3.598-8.2zm-5.073-3.003A3.11 3.11 0 0 1 15.004 20c0 .038.002.208.017.469l-5.9-2.624a3.8 3.8 0 0 1 2.809-.848zM15.45 28A5.2 5.2 0 0 1 14 25h-2a6.5 6.5 0 0 0 .968 3h-2.223A16.617 16.617 0 0 1 10 24H8a17.342 17.342 0 0 0 .665 4H6c.031-1.836.29-5.892 1.803-8.553l7.533 3.35A13.025 13.025 0 0 0 17.596 28z"
                                                fill="#626262"
                                            />
                                            <rect
                                                x="0"
                                                y="0"
                                                width="32"
                                                height="32"
                                                fill="rgba(0, 0, 0, 0)"
                                            />
                                        </svg>
                                        <span style={{ marginLeft: '10px' }}>
                                            Higienizar contatos
                                        </span>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}

import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { api } from '../../Api/app'
import FormDefault from '../../Components/Form/FormDefault'
import InputDefault from '../../Components/Form/InputDefault'
import InputRow from '../../Components/Form/InputRow'
import H1Page from '../../Components/Layout/H1Page'
import MessageStore from '../../Store/MessageStore'
import Cards from 'react-credit-cards'
import 'react-credit-cards/es/styles-compiled.css'
//scss
import './Usuario.scss'

export default function DadosPagamento(props) {

    const [load, setLoad] = useState(true)
    const [data, setData] = useState(
        {
            enderecoCobranca: {
                cep: ''
            },
            creditCard: {}
        }
    )
    const [focusCreditCard, setFocusCreditCard] = useState(false)
    const history = useHistory()

    useEffect(() => {

        api.get('profile').then( response => {

            api.get('/accounts/'+response.data.accountId).then( account => {

                setData({...account.data})
                setLoad(false)

            })

        })

    }, [props])

    function handleOnChangeEndereco(name, value)
    {

        data.enderecoCobranca[name] = value
        setData( {...data} )

    }

    function handleOnChangeCredit(name, value)
    {

        console.log( name )
        if ( name === 'cvv' ){
            setFocusCreditCard('cvc')
        } else {
            setFocusCreditCard('number')
        }

        data.creditCard[name] = value
        setData( {...data} )

    }

    function handleOnSave(e)
    {

        e.preventDefault()

        const dataPut = {
            enderecoCobranca: {
                cep: data.enderecoCobranca.cep,
                logradouro: data.enderecoCobranca.logradouro,
                numero: data.enderecoCobranca.numero,
                complemento: data.enderecoCobranca.complemento,
                bairro: data.enderecoCobranca.bairro,
                cidade: data.enderecoCobranca.cidade,
                estado: data.enderecoCobranca.estado,
            },
            creditCard: {
                nome: data.creditCard.nome,
                numero: data.creditCard.numero,
                exp: data.creditCard.exp,
                cvv: data.creditCard.cvv,
                cpf: data.creditCard.cpf,
                nascimento: data.creditCard.nascimento,
                telefone: data.creditCard.telefone
            }
        }

        api.put('profile/update-payment', dataPut).then( response => {

            console.log( response )

            if ( response.data.error === true ){
                MessageStore.addError( response.data.message )
            } else {
                MessageStore.addSuccess('Dados de pagamento alterados com sucesso.')
            }

        })

    }

    async function handleChangeCep(name, value)
    {

        try {
            
            handleOnChangeEndereco(name, value)

            const viacep = await Axios.get('https://viacep.com.br/ws/'+value+'/json/')

            handleOnChangeEndereco('bairro', viacep.data.bairro)
            handleOnChangeEndereco('logradouro', viacep.data.logradouro)
            handleOnChangeEndereco('localidade', viacep.data.localidade)
            handleOnChangeEndereco('uf', viacep.data.uf)

        } catch ( err ) {

            console.log( value.length )

            if ( value.length === 8 ){
                MessageStore.addError('Não foi possivel encontrar esse endereço.')
            }

        }


    }

    return(

        <div id="usuario" className="page">

            <H1Page nome="Configurações"/>

            <div className="nav-config">
                <button onClick={() => history.push('/configuracoes')}>Minhas configurações</button>
                {/* <button className="active" onClick={() => history.push('/configuracoes/dados-pagamento')}>Dados de cobrança</button> */}
            </div>

            <div className="row">

                <FormDefault onSubmit={handleOnSave}>

                    <h3>Endereço de cobrança</h3>

                    <InputRow>
                        <InputDefault
                            title="CEP"
                            name="cep"
                            value={data.enderecoCobranca.cep}
                            onChange={handleChangeCep}
                            mask={"99999-999"}
                            maskChar={""}
                        />
                        <InputDefault
                            title="Endereço"
                            name="logradouro"
                            value={data.enderecoCobranca.logradouro}
                            onChange={handleOnChangeEndereco}
                        />
                        <InputDefault
                            title="Número"
                            name="numero"
                            value={data.enderecoCobranca.numero}
                            onChange={handleOnChangeEndereco}
                        />
                        <InputDefault
                            title="Complemento"
                            name="complemento"
                            value={data.enderecoCobranca.complemento}
                            onChange={handleOnChangeEndereco}
                        />
                    </InputRow>

                    <InputRow>
                        <InputDefault
                            title="Bairro"
                            name="bairro"
                            value={data.enderecoCobranca.bairro}
                            onChange={handleOnChangeEndereco}
                        />
                        <InputDefault
                            title="Cidade"
                            name="cidade"
                            value={data.enderecoCobranca.cidade}
                            onChange={handleOnChangeEndereco}
                        />
                        <InputDefault
                            title="Estado"
                            name="estado"
                            value={data.enderecoCobranca.estado}
                            onChange={handleOnChangeEndereco}
                        />
                    </InputRow>

                    <h3>Dados do cartão</h3>

                    <div className="credit-card">

                        <div className="form">
                            <InputRow>
                                <InputDefault
                                    title="Nome"
                                    name="nome"
                                    value={data.creditCard.nome}
                                    onChange={handleOnChangeCredit}
                                />
                                <InputDefault
                                    title="CPF"
                                    name="cpf"
                                    value={data.creditCard.cpf}
                                    onChange={handleOnChangeCredit}
                                />
                                <InputDefault
                                    title="Data de nascimento"
                                    name="nascimento"
                                    value={data.creditCard.nascimento}
                                    onChange={handleOnChangeCredit}
                                />
                                <InputDefault
                                    title="Telefone"
                                    name="telefone"
                                    value={data.creditCard.telefone}
                                    onChange={handleOnChangeCredit}
                                />
                            </InputRow>

                            <InputRow>
                                <InputDefault
                                    type="tel"
                                    title="Número"
                                    name="numero"
                                    value={data.creditCard.numero}
                                    onChange={handleOnChangeCredit}
                                    mask="9999 9999 9999 9999"
                                    maskChar=""
                                />
                                <InputDefault
                                    type="tel"
                                    title="Vencimento"
                                    name="exp"
                                    value={data.creditCard.exp}
                                    onChange={handleOnChangeCredit}
                                    mask="99/99"
                                />
                                <InputDefault
                                    type="tel"
                                    title="Código de segurança"
                                    name="cvv"
                                    value={data.creditCard.cvv}
                                    onChange={handleOnChangeCredit}
                                />
                            </InputRow>
                        </div>

                        <div className="card">
                            { data.creditCard.numero &&
                                <Cards
                                    cvc={data.creditCard.cvv}
                                    expiry={data.creditCard.exp}
                                    focused={focusCreditCard}
                                    name={data.creditCard.nome}
                                    number={data.creditCard.numero}
                                />
                            }
                        </div>

                    </div>

                    <button type="submit">Salvar</button>

                </FormDefault>

            </div>
            
        </div>

    )

}
import React, { useEffect, useRef, useState } from 'react';
import Datetime from '../../Helper/Datetime';
import MessageRender from './MessageRender';

export default function Messages({ messages, load, setImageZoom }) {
    return (
        <>
            <div className="position-bottom">
                {load === true && (
                    <div className="loading-general">
                        <div>
                            <i className="fa fa-spinner fa-spin"></i>
                        </div>
                    </div>
                )}

                {load === false &&
                    messages.map((row, key) => (
                        <div
                            key={key}
                            className={`message ${row.type}`}
                            id={row.id}
                        >
                            {row.campanha && (
                                <div className="author">
                                    Campanha <b>{row.nome}</b>
                                </div>
                            )}

                            {row.author && (
                                <div className="author">
                                    {row.chatbotId && (
                                        <svg
                                            className="icon-bot"
                                            xmlns="http://www.w3.org/2000/svg"
                                            aria-hidden="true"
                                            focusable="false"
                                            width="1em"
                                            height="1em"
                                            preserveAspectRatio="xMidYMid meet"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                d="M21.928 11.607c-.202-.488-.635-.605-.928-.633V8c0-1.103-.897-2-2-2h-6V4.61c.305-.274.5-.668.5-1.11a1.5 1.5 0 0 0-3 0c0 .442.195.836.5 1.11V6H5c-1.103 0-2 .897-2 2v2.997l-.082.006A1 1 0 0 0 1.99 12v2a1 1 0 0 0 1 1H3v5c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2v-5a1 1 0 0 0 1-1v-1.938a1.006 1.006 0 0 0-.072-.455zM5 20V8h14l.001 3.996L19 12v2l.001.005l.001 5.995H5z"
                                                fill="#626262"
                                            />
                                            <ellipse
                                                cx="8.5"
                                                cy="12"
                                                rx="1.5"
                                                ry="2"
                                                fill="#626262"
                                            />
                                            <ellipse
                                                cx="15.5"
                                                cy="12"
                                                rx="1.5"
                                                ry="2"
                                                fill="#626262"
                                            />
                                            <path
                                                d="M8 16h8v2H8z"
                                                fill="#626262"
                                            />
                                            <rect
                                                x="0"
                                                y="0"
                                                width="24"
                                                height="24"
                                                fill="rgba(0, 0, 0, 0)"
                                            />
                                        </svg>
                                    )}
                                    {row.author}
                                </div>
                            )}

                            <div className="content">
                                <MessageRender
                                    figurinha={row.figurinha}
                                    audio={row.audio}
                                    video={row.video}
                                    contatoJSON={row.contatoJSON}
                                    localizacaoJSON={row.localizacaoJSON}
                                    foto={row.foto}
                                    arquivo={row.arquivo}
                                    message={row.messageText}
                                    botoes={row.botoes}
                                    setImageZoom={setImageZoom}
                                    id={row.id}
                                />

                                <div className="infos">
                                    <span className="date">
                                        {Datetime.string(row.messageDate)}
                                    </span>

                                    {row.type === 'sent' && (
                                        <>
                                            {row.status === 'Em Fila' && (
                                                <svg
                                                    className="status-check"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 16 15"
                                                    width="16"
                                                    height="15"
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        d="M9.75 7.713H8.244V5.359a.5.5 0 0 0-.5-.5H7.65a.5.5 0 0 0-.5.5v2.947a.5.5 0 0 0 .5.5h.094l.003-.001.003.002h2a.5.5 0 0 0 .5-.5v-.094a.5.5 0 0 0-.5-.5zm0-5.263h-3.5c-1.82 0-3.3 1.48-3.3 3.3v3.5c0 1.82 1.48 3.3 3.3 3.3h3.5c1.82 0 3.3-1.48 3.3-3.3v-3.5c0-1.82-1.48-3.3-3.3-3.3zm2 6.8a2 2 0 0 1-2 2h-3.5a2 2 0 0 1-2-2v-3.5a2 2 0 0 1 2-2h3.5a2 2 0 0 1 2 2v3.5z"
                                                    ></path>
                                                </svg>
                                            )}
                                            {row.status === 'Enviado' && (
                                                <svg
                                                    className="status-check"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 16 15"
                                                    width="16"
                                                    height="15"
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        d="M10.91 3.316l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"
                                                    ></path>
                                                </svg>
                                            )}
                                            {row.status === 'Recebido' && (
                                                <svg
                                                    className="status-check"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 16 15"
                                                    width="16"
                                                    height="15"
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.879a.32.32 0 0 1-.484.033l-.358-.325a.319.319 0 0 0-.484.032l-.378.483a.418.418 0 0 0 .036.541l1.32 1.266c.143.14.361.125.484-.033l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"
                                                    ></path>
                                                </svg>
                                            )}
                                            {row.status ===
                                                'Confirmado Leitura' && (
                                                <svg
                                                    className="status-check"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 16 15"
                                                    width="16"
                                                    height="15"
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.879a.32.32 0 0 1-.484.033l-.358-.325a.319.319 0 0 0-.484.032l-.378.483a.418.418 0 0 0 .036.541l1.32 1.266c.143.14.361.125.484-.033l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"
                                                        fill="#4fc3f7"
                                                    ></path>
                                                </svg>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
        </>
    );
}

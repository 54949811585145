import React, { useState, useEffect } from 'react';
import { api } from '../../Api/app';
import messageStore from '../../Store/MessageStore';
import InputDefault from '../Form/InputDefault';
import FormDefault from '../Form/FormDefault';
import InputRow from '../Form/InputRow';
import IconButton from '../Icon/IconButton';
import CreatableSelect from 'react-select/creatable';

import './ContactList.scss';

export default function EditColumns(props) {
    const open = props.open;
    const [load, setLoad] = useState(true);
    const [columns, setColumns] = useState([]);
    const [values, setValues] = useState({
        contactListId: props.list._id,
    });

    useEffect(() => {
        setColumns([...props.columns]);

        if (props.contact) {
            console.log(props.contact);
            setValues({ ...props.contact });
        }
    }, []);

    function handleNewColumn() {
        try {
            if (columns.length >= 20)
                throw 'O máximo de colunas permitidas são 20.';

            columns.push('Coluna ' + (columns.length + 1));
            setColumns([...columns]);
        } catch (e) {
            messageStore.addError(e);
        }
    }

    function handleDeleteColumn(name) {
        const list = columns.filter((obj) => obj !== name);

        console.log(list);

        setColumns([...list]);
    }

    function handleChangeColumn(name, value) {
        columns[name] = value.toLowerCase();

        setColumns([...columns]);

        console.log(columns);
    }

    async function handleConfirm(e) {
        try {
            e.preventDefault();
            props.handleEditColumn(columns);
            messageStore.addSuccess('Colunas salva com sucesso.');

            if (props.redirect) {
                props.setTab(props.redirect);
                props.setRedirect(false);
            }
        } catch (err) {
            console.log(err);
            messageStore.addError('Algo deu errado...');
        }
    }

    return (
        <form
            onSubmit={handleConfirm}
            style={{ display: open ? 'block' : 'none' }}
        >
            <div className="overflow">
                <table className="table-default">
                    <thead>
                        <tr>
                            <th>Coluna</th>
                        </tr>
                    </thead>

                    <tbody>
                        {columns.map((coluna, indice) => (
                            <tr>
                                <th
                                    style={{
                                        paddingRight: 20,
                                        position: 'relative',
                                        zIndex: 9999,
                                    }}
                                >
                                    {coluna !== 'celular' &&
                                    coluna !== 'email' ? (
                                        <CreatableSelect
                                            placeholder={coluna}
                                            onChange={(e) =>
                                                handleChangeColumn(
                                                    indice,
                                                    e.value
                                                )
                                            }
                                            name={indice}
                                            defaultValue={coluna}
                                            options={[
                                                {
                                                    label: 'Primeiro nome',
                                                    value: 'primeiro nome',
                                                },
                                                {
                                                    label: 'Sobrenome',
                                                    value: 'sobrenome',
                                                },
                                                {
                                                    label: 'Nome completo',
                                                    value: 'Nome completo',
                                                },
                                                {
                                                    label: 'CPF',
                                                    value: 'CPF',
                                                },
                                                {
                                                    label: 'CNPJ',
                                                    value: 'CNPJ',
                                                },
                                                {
                                                    label: 'Telefone residencial',
                                                    value: 'Telefone residencial',
                                                },
                                                {
                                                    label: 'Telefone comercial',
                                                    value: 'Telefone comercial',
                                                },
                                                {
                                                    label: 'Sexo',
                                                    value: 'Sexo',
                                                },
                                                {
                                                    label: 'CEP residencial',
                                                    value: 'CEP residencial',
                                                },
                                                {
                                                    label: 'Endereço residencial',
                                                    value: 'Endereço residencial',
                                                },
                                                {
                                                    label: 'Bairro residencial',
                                                    value: 'Bairro residencial',
                                                },
                                                {
                                                    label: 'Complemento residencial',
                                                    value: 'Complemento residencial',
                                                },
                                                {
                                                    label: 'UF residencial',
                                                    value: 'UF residencial',
                                                },
                                                {
                                                    label: 'Cidade residencial',
                                                    value: 'Cidade residencial',
                                                },
                                                {
                                                    label: 'CEP comercial',
                                                    value: 'CEP comercial',
                                                },
                                                {
                                                    label: 'Endereço comercial',
                                                    value: 'Endereço comercial',
                                                },
                                                {
                                                    label: 'Bairro comercial',
                                                    value: 'Bairro comercial',
                                                },
                                                {
                                                    label: 'Complemento comercial',
                                                    value: 'Complemento comercial',
                                                },
                                                {
                                                    label: 'UF comercial',
                                                    value: 'UF comercial',
                                                },
                                                {
                                                    label: 'Cidade comercial',
                                                    value: 'Cidade comercial',
                                                },
                                            ]}
                                        />
                                    ) : (
                                        <InputDefault
                                            readOnly={true}
                                            value={coluna}
                                        />
                                    )}
                                </th>
                            </tr>
                        ))}
                    </tbody>

                    <tfoot>
                        <tr>
                            <td>
                                <button
                                    type="button"
                                    className="button-zig secondary"
                                    style={{ flexDirection: 'row' }}
                                    onClick={handleNewColumn}
                                >
                                    <IconButton icon="new" />
                                    <span style={{ marginLeft: '10px' }}>
                                        Adicionar novo campo
                                    </span>
                                </button>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>

            <div className="tfoot">
                <button
                    className="button-zig primary"
                    style={{ flexDirection: 'row' }}
                >
                    <IconButton icon="save" />
                    <span style={{ marginLeft: '10px' }}>Confirmar</span>
                </button>
            </div>
        </form>
    );
}

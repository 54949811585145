import React, { useState } from 'react'
import "./Modal.scss"

export default function Modal({title, content, children})
{

    const [open, setOpen] = useState(false)

    return(
        <>

        <div onClick={() => setOpen(!open)}>{content}</div>

        { open === true && 
            <div id="modal">
                <div className="overlay" onClick={() => setOpen(false)}></div>
                <div className="modal-box">

                    <button onClick={() => setOpen(false)} className="button-zig neutral">Fechar</button>

                    <div className="content">

                        <h1>{title}</h1>

                        <div className="container">
                            {children}
                        </div>

                    </div>

                </div>
            </div>
        }
        </>
    )

}
import React, { useEffect, useState } from 'react';
import { api } from '../../Api/app';
import BrokersList from '../../Components/Monitoramento/BrokersList';
import messageStore from '../../Store/MessageStore';

//import 'bootstrap/dist/css/bootstrap.min.css'
import {
    Row,
    Col,
    InputGroup,
    FormControl,
    Spinner,
    Dropdown,
    DropdownButton,
    Button,
} from 'react-bootstrap';

import './Monitoramento.scss';
import UsersList from '../../Components/Monitoramento/UsersList';
import SendList from '../../Components/Monitoramento/SendList';
import Datetime from '../../Helper/Datetime';
import { useHistory } from 'react-router';

export default function Monitoramento(props) {
    const history = useHistory();

    const [fila, setFila] = useState([]);

    const [brokers, setBrokers] = useState([]);

    const [users, setUsers] = useState([]);

    const [totalUsers, setTotalUsers] = useState([]);

    const [load, setLoad] = useState(true);

    const [autoRefresh, setAutoRefresh] = useState(
        props.match.params.tempo !== 'false' ? props.match.params.tempo : false
    );

    const [user, setUser] = useState(
        props.match.params.user !== 'false' ? props.match.params.user : false
    );

    const [dataInicial, setDataInicial] = useState(Datetime.today());

    const [dataFinal, setDataFinal] = useState(Datetime.today());

    const [totalPorcanal, setTotalPorCanal] = useState([]);

    useEffect(() => {
        setAutoRefresh(
            props.match.params.tempo !== 'false'
                ? props.match.params.tempo
                : false
        );

        setUser(
            props.match.params.user !== 'false'
                ? props.match.params.user
                : false
        );

        console.log(props);

        if (autoRefresh) {
            const interval = setInterval(() => {
                getData();
            }, autoRefresh * 1000);

            return () => clearInterval(interval);
        }
    }, [props, autoRefresh, user, dataInicial, dataFinal]);

    useEffect(() => {
        getData();
    }, [dataInicial, dataFinal, user]);

    async function getData() {
        try {
            console.log(dataInicial, dataFinal, user);

            setLoad(true);

            const accountId = user ? user : '';

            const responseAccounts = await api.get('accounts');

            const responseVolumetria = await api.get(
                'monitoramento/volumetria?periodoInicial=' +
                    dataInicial +
                    '&periodoFinal=' +
                    dataFinal +
                    '&idEmpresa=' +
                    accountId
            );

            const responseFila = await api.get(
                'monitoramento/fila?periodoInicial=' +
                    dataInicial +
                    '&periodoFinal=' +
                    dataFinal +
                    '&idEmpresa=' +
                    accountId
            );

            const responseUsers = await api.get(
                'monitoramento/usuarios?periodoInicial=' +
                    dataInicial +
                    '&periodoFinal=' +
                    dataFinal +
                    '&idEmpresa=' +
                    accountId
            );

            const newTotalPorCanal = [];

            responseVolumetria.data.map((row, key) => {
                var data = [];

                data.tipo = row.tipo;

                row.totalPorBroker.map((broker) => {
                    broker.totalPorStatus.map((status, statusKey) => {
                        data[status.status] = data[status.status]
                            ? data[status.status]
                            : 0;
                        data[status.status] =
                            parseFloat(data[status.status]) +
                            parseFloat(status.total);
                    });
                });

                newTotalPorCanal.push(data);
            });

            setTotalPorCanal([...newTotalPorCanal]);

            setTotalUsers([...responseUsers.data]);
            setUsers([...responseAccounts.data.docs]);
            setBrokers([...responseVolumetria.data]);
            setFila([...responseFila.data]);

            setLoad(false);
        } catch (err) {
            setLoad(false);
            console.log(err);
            messageStore.addError(
                'Houve um erro ao carregador dados do backend.'
            );
        }
    }

    async function handleSendTest() {
        setLoad(true);
        const response = await api.get('/cron/whatsapp-enviar-testes', {
            headers: {
                Authorization:
                    'Bearer eyJhbGciOiJIUzI1NiJ9.NjFmOThkNzdkYjUxMWMxMTkwMGY1MWNl.SU2KHPWDk4ajhqUuviwqm4xqPS9GiHAPN8M04xOQRgE',
            },
        });
        await getData();
        messageStore.addSuccess('Testes enviados com sucesso.');
    }

    async function handleCheckStatus() {
        setLoad(true);
        const response = await api.get('/cron/update-whatsapp-brokers-status', {
            headers: {
                Authorization:
                    'Bearer eyJhbGciOiJIUzI1NiJ9.NjFmOThkNzdkYjUxMWMxMTkwMGY1MWNl.SU2KHPWDk4ajhqUuviwqm4xqPS9GiHAPN8M04xOQRgE',
            },
        });
        await getData();
        messageStore.addSuccess(
            'Status de instancias verificadas com sucesso.'
        );
    }

    return (
        <div id="monitoramento">
            <header>
                <Row>
                    <Col sm={3}>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text id="inputGroup-sizing-default">
                                    Periodo inicial
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                type="date"
                                value={dataInicial}
                                onChange={(e) => setDataInicial(e.target.value)}
                                aria-describedby="inputGroup-sizing-default"
                            />
                        </InputGroup>
                    </Col>

                    <Col sm={3}>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text id="inputGroup-sizing-default">
                                    Periodo final
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                type="date"
                                value={dataFinal}
                                onChange={(e) => setDataFinal(e.target.value)}
                            />
                        </InputGroup>
                    </Col>

                    <Col sm={6}>
                        <div className="filters">
                            <DropdownButton
                                as={InputGroup.Prepend}
                                variant="outline-secondary"
                                title={
                                    autoRefresh
                                        ? autoRefresh + ' segundos'
                                        : 'Não atualizar'
                                }
                                id="input-group-dropdown-1"
                                value={20}
                            >
                                <Dropdown.Item
                                    active={
                                        autoRefresh === false ||
                                        autoRefresh === 'false'
                                            ? true
                                            : false
                                    }
                                    onClick={(e) =>
                                        history.push(
                                            '/monitoramento/false/' +
                                                (user ? user : 'false')
                                        )
                                    }
                                >
                                    Não atualizar
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                {[
                                    5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55,
                                    60, 120, 180, 240, 300, 360, 420, 480,
                                ].map((row, key) => (
                                    <Dropdown.Item
                                        onClick={(e) =>
                                            history.push(
                                                '/monitoramento/' +
                                                    row +
                                                    '/' +
                                                    (user ? user : 'false')
                                            )
                                        }
                                        key={key}
                                        id={`${row}`}
                                        active={
                                            parseFloat(autoRefresh) ===
                                            parseFloat(row)
                                                ? true
                                                : false
                                        }
                                    >
                                        {row} segundos
                                    </Dropdown.Item>
                                ))}
                            </DropdownButton>

                            <DropdownButton
                                as={InputGroup.Prepend}
                                variant="outline-secondary"
                                title={
                                    user && users.length > 0
                                        ? users.filter(
                                              (obj) => obj.id === user
                                          )[0].nome
                                        : 'Todas empresas'
                                }
                                id="input-group-dropdown-1"
                                value={20}
                            >
                                <Dropdown.Item
                                    active={user === false ? true : false}
                                    onClick={(e) =>
                                        history.push(
                                            '/monitoramento/' +
                                                (autoRefresh
                                                    ? autoRefresh
                                                    : 'false') +
                                                '/false'
                                        )
                                    }
                                >
                                    Todas empresas
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                {users.map((row, key) => (
                                    <Dropdown.Item
                                        onClick={(e) =>
                                            history.push(
                                                '/monitoramento/' +
                                                    (autoRefresh
                                                        ? autoRefresh
                                                        : 'false') +
                                                    '/' +
                                                    row.id
                                            )
                                        }
                                        key={key}
                                        active={user === row.id ? true : false}
                                    >
                                        {row.nome}
                                    </Dropdown.Item>
                                ))}
                            </DropdownButton>

                            <Button
                                onClick={handleCheckStatus}
                                variant="success"
                            >
                                Verificar Status
                            </Button>
                            <Button onClick={handleSendTest}>
                                Disparar Testes
                            </Button>

                            {load === true && (
                                <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                            )}
                        </div>
                    </Col>
                </Row>
            </header>

            <Row>
                <Col>
                    <h4>Brokers</h4>
                    <BrokersList data={brokers} totalPorcanal={totalPorcanal} />
                </Col>

                <Col>
                    <h4>Contas</h4>
                    <UsersList
                        data={users}
                        user={user}
                        totalUsers={totalUsers}
                    />

                    <h4>Fila de envios</h4>
                    <SendList data={fila} />
                </Col>
            </Row>
        </div>
    );
}

import React, { useEffect, useState } from 'react'
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch'
import { api } from '../../Api/app'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import messageStore from '../../Store/MessageStore'
import IconButton from '../Icon/IconButton'
import ModelChatbot from '../../Pages/Chatbot/ModelChatbot'

const Chatbot = (props) => {

    const history = useHistory()

    const [chatbot, setChatbot] = useState(0)

    const [listChatbots, setListChatbots] = useState([])

    const [load, setLoad] = useState(false)

    const Model = new ModelChatbot(
        [], 
        () => {}, 
        () => {}
    )

    useEffect(() => {
        
        getBots()

    }, [chatbot])

    async function getBots()
    {

        const response = await api.get('chatbots')
        setListChatbots([...response.data])

    }


    async function handleOnSave(e)
    {

        e.preventDefault()
        props.setStep(props.next)
        
    }

    async function handleNewChatbot()
    {


        setLoad(true)
        
        const dataToApi = {
            nome: 'T2U Bot',
            estrutura: JSON.stringify(Model.setElementsDefault())
        }

        console.log( dataToApi )

        setLoad(true)
        var response = await api.post('chatbots', dataToApi)

        if ( response.data.error === true ){
            messageStore.addError(response.data.message)
        } else {

            history.push({
                pathname: '/chatbot/'+response.data.id,
                state: {
                    fields: props.fields,
                    send: props.send,
                    step: props.step
                },
            })

        }
        
        setLoad(false)
        
        setLoad(false)

    }

    return(
        <form className="step" onSubmit={handleOnSave} style={{display: props.step === props.num ? 'block' : 'none'}}>

            <h2>{props.nome}</h2>
            <p>Escolha se deseja usar chatbot para sua campanha ou crie um novo.</p>

            <div className="separator">

                <div className="input">

                    <div style={{padding: '0px 0px 30px 0px'}}>
                        <label onClick={() => setChatbot(0)}>
                            <ToggleSwitch value={chatbot ==  0 ? 1 : false}/>
                            <span>Meus chatbots</span>
                        </label>

                        <label onClick={() => setChatbot(2)}>
                            <ToggleSwitch value={chatbot ===  2 ? 1 : false}/>
                            <span>Não quero usar chatbot</span>
                        </label>

                    </div>

                    <div className="buttons">

                        { props.step !== 5 && <button>Próximo passo</button>}
                        { props.step !== 1 && <button onClick={() => props.setStep( (props.prev) )} type="button">Passo anterior</button>}
                        
                    </div>
                </div>

                { chatbot === 0 && 
                    <div className="list">

                        { load === true && <div>Carregando</div> }
                        
                        { load === false && listChatbots.map((row, key) => 
                            <div key={key}>

                                <label>
                                    <input defaultChecked={ props.fields.id_chatbot === row.id ? true : false } onClick={() => props.handleOnChange( 'id_chatbot', row.id )} type="radio" name="id_chatbot" value={row.id}/>
                                    <span>{row.nome}</span>
                                </label>

                                <button type="button" className="button-zig secondary" onClick={() => history.push({
                                    pathname: '/chatbot/'+row.id,
                                    state: {
                                        fields: props.fields,
                                        send: props.send,
                                        step: props.step
                                    },
                                })}>
                                <IconButton icon="edit"/>
                                <span>Editar</span>
                            </button>

                            </div>
                        )}

                        <label style={{display: 'flex', justifyContent: 'flex-end', marginTop: 20}}>
                            <button onClick={handleNewChatbot} type="button" className="button-zig column full primary">
                                <IconButton icon="new"/>
                                <span>Criar chatbot</span>
                            </button>
                        </label>
            
                    </div>
                }

            </div>

        </form>
    )

}

export default Chatbot
import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import br from 'date-fns/locale/pt-BR';

//scss
import './Campanhas.scss'
import PreviewTemplate from '../../Components/TemplateCreation/PreviewTemplate/PreviewTemplate';
import H1Page from '../../Components/Layout/H1Page';
import { api } from '../../Api/app';
import datesFormater from '../../Helper/DatesFormater';
import { Link } from 'react-router-dom';
import Paginator from '../../Components/Paginator/Paginator';

export default function Campanhas(props) {

    //filtros
    const [dataInicial] = useState('')
    const [dataFinal] =  useState('')

    //accordion
    const [accordion, setAccordion] = useState(0)

    const [page, setPage] = useState(1)

    //campanhas
    const [data, setData] = useState({
        docs: []
    })

    useEffect(() => {

        api.get('campaigns?page='+page).then( response => {

            console.log( response.data.docs )
            setData({...response.data})

        })

    }, [page])

    return(
        <div id="campanhas" className="page">

            <H1Page nome="Minhas campanhas"/>
            
            <div className="accordion">
                
                {data.docs.map((row, key) => (
                <div key={key} className={`item ${accordion >= key ? 'up' : 'down'} ${accordion === key ? 'active' : null}`}>
                    
                    <div className="title" onClick={() => accordion === key ? setAccordion(null) : setAccordion(key)}>
                        <span>{row.nome}</span>
                        <button>
                            { accordion === key ? '-' : '+' }
                        </button>
                    </div>
                    <div className="infos">


                        { row.tempoEstimado !== "0" && 
                        <div>
                            <b>Tempo estimado para os envios</b>
                            <span>
                                <i className="fa fa-calendar"></i>
                                {row.tempoEstimado}
                            </span>
                        </div>
                        }

                        <div>
                            <b>Data de envios</b>
                            <span>
                                <i className="fa fa-calendar"></i>
                                {datesFormater.dateBR(row.dataEnvio)}
                            </span>
                        </div>

                        <div>
                            <b>Data de envios</b>
                            <span>
                                <i className="fa fa-calendar"></i>
                                {datesFormater.dateBR(row.dataEnvio)}
                            </span>
                        </div>

                        <div>
                            <b>Tipo</b>
                            <span>{row.tipoDeEnvio}</span>
                        </div>

                        { row.tipoDeEnvio === 'Email' &&
                            <div>
                                <b>Relatório de e-mail</b>
                                <span>
                                    <Link to={'/email/relatorio/'+row._id}>Visualizar relatórios</Link>
                                </span>
                            </div>
                        }

                        { row.templateId &&
                        <>
                            <div>
                                <b>Link do template</b>
                                <PreviewTemplate id={row.templateId}>
                                    <span className="link">
                                        <i class="fa fa-external-link" aria-hidden="true"></i> 
                                        Visualizar
                                    </span>
                                </PreviewTemplate>
                            </div>

                            <div>
                                <b>Relatório de landing page</b>
                                <span>
                                    <Link to={'/templates/relatorio/'+row._id}>Visualizar relatórios</Link>
                                </span>
                            </div>
                        </>
                        }

                        <div>
                            <b>Relatório de envios</b>
                            <span>
                                <Link to={'/analytics/'+row._id}>Visualizar relatórios</Link>
                            </span>
                        </div>

                    </div>

                </div>
                ))}

            </div>

            <div className="tfoot" style={{margin: '20px -10px'}}>
                <Paginator 
                    range={10} 
                    totalPage={data.pages} 
                    setPage={setPage}
                    page={page}/>
            </div>

        </div>
    )

}
import React, { createContext, useContext, useState } from 'react';

const GlobalContext = createContext();

export default function GlobalProvider({ children }) {
    const [user, setUser] = useState({
        account: {
            nome: '',
            brokers: [],
        },
    });

    return (
        <GlobalContext.Provider value={{ user, setUser }}>
            {children}
        </GlobalContext.Provider>
    );
}

export function useGlobal() {
    const context = useContext(GlobalContext);
    const { user, setUser } = context;
    return { user, setUser };
}

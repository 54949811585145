import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { api } from '../../Api/app';
import FormDefault from '../../Components/Form/FormDefault';
import InputDefault from '../../Components/Form/InputDefault';
import InputRow from '../../Components/Form/InputRow';
import IconButton from '../../Components/Icon/IconButton';
import H1Page from '../../Components/Layout/H1Page';
import Paginator from '../../Components/Paginator/Paginator';
import { Button } from '../../Components/TemplateCreation/Widgets/Button/Button';
import messageStore from '../../Store/MessageStore';
//scss
import './administracao.scss';

export default function BrokersForm(props) {
    const [load, setLoad] = useState(true);
    const [data, setData] = useState({
        access: [],
        accounts: [],
    });
    const [accounts, setAccounts] = useState([]);
    const [chatbots, setChatBots] = useState([]);

    const [page, setPage] = useState(1);

    const history = useHistory();

    const id = props.match.params.id;

    useEffect(() => {
        getData();
    }, [props]);

    async function getData() {
        try {
            const responseAccounts = await api.get('accounts');
            setAccounts([...responseAccounts.data.docs]);

            const responseChatBots = await api.get('chatbots');
            setChatBots([...responseChatBots.data]);

            if (id != 'new') {
                const response = await api.get('brokers/' + id);
                setData({ ...response.data });
            }
        } catch (e) {
            console.log(e);
            messageStore.addError('Erro ao listar.');
        }
    }

    function handleOnChange(name, value) {
        data[name] = value;
        setData({ ...data });

        console.log(data);
    }

    async function handleOnSave(e) {
        try {
            e.preventDefault();

            if (id !== 'new') {
                const responsePut = await api.put('brokers/' + id, data);
                if (responsePut.data.error === true)
                    throw responsePut.data.message;
            }

            if (id === 'new') {
                const responsePost = await api.post('brokers/', data);
                if (responsePost.data.error === true)
                    throw responsePost.data.message;
            }

            messageStore.addSuccess('Conta atualizada com sucesso.');

            history.goBack();
        } catch (e) {
            messageStore.addError(e);
        }
    }

    function handleOnChangeAccess(key, value) {
        data.access[key] = value;

        setData({ ...data });
    }

    function addAccess() {
        data.access.push('');
        setData({ ...data });
    }

    function delAccess(access) {
        const newAccess = [];

        data.access.map((row) => {
            if (row != access) newAccess.push(row);
        });

        data.access = newAccess;

        setData({ ...data });
    }

    useEffect(() => {
        console.log(data);
    }, [data]);

    function handleNewAccount(value) {
        if (data.accounts.filter((obj) => obj._id === value).length) {
            console.log(
                'filter',
                data.accounts.filter((obj) => obj._id !== value),
                value,
                data.accounts
            );
            data.accounts = data.accounts.filter((obj) => obj._id !== value);
        } else {
            console.log(
                'push',
                accounts.filter((obj) => obj.id === value)[0],
                value,
                data.accounts
            );
            data.accounts.push(accounts.filter((obj) => obj._id === value)[0]);
        }

        setData({ ...data });
    }

    return (
        <div id="administracao" className="page">
            <H1Page nome="Gerenciar broker" />

            <div className="row">
                <FormDefault onSubmit={handleOnSave}>
                    <InputRow>
                        <InputDefault
                            title="Broker"
                            name="broker"
                            value={data.broker}
                            onChange={handleOnChange}
                        />

                        <InputDefault
                            title="Identificação"
                            name="identificacao"
                            value={data.identificacao}
                            onChange={handleOnChange}
                        />
                    </InputRow>

                    <InputRow>
                        <label class="input-default-zig">
                            <span>Oficial</span>
                            <select
                                onChange={(e) =>
                                    handleOnChange('oficial', e.target.value)
                                }
                                name="oficial"
                                value={data.oficial}
                            >
                                <option value="">Oficial</option>
                                <option value={'false'}>Não</option>
                                <option value={'true'}>Sim</option>
                            </select>
                        </label>

                        <InputDefault
                            title="Celular"
                            name="numero"
                            value={data.numero}
                            onChange={handleOnChange}
                            mask={'(99) 9999-99999'}
                            maskChar={''}
                        />
                    </InputRow>

                    <InputRow>
                        <InputDefault
                            title="Webhook status"
                            name="webhookStatus"
                            value={data.webhookStatus}
                            onChange={handleOnChange}
                        />

                        <InputDefault
                            title="Webhook respostas"
                            name="webhookResposta"
                            value={data.webhookResposta}
                            onChange={handleOnChange}
                        />
                    </InputRow>

                    <InputRow>
                        <label class="input-default-zig">
                            <span>Status</span>
                            <select
                                onChange={(e) =>
                                    handleOnChange('isActive', e.target.value)
                                }
                                name="isActive"
                                value={data.isActive}
                            >
                                <option value="">Selecione um status</option>
                                <option value={'false'}>Inativo</option>
                                <option value={'true'}>Ativo</option>
                            </select>
                        </label>

                        <label class="input-default-zig">
                            <span>Tipo</span>
                            <select
                                onChange={(e) =>
                                    handleOnChange('tipo', e.target.value)
                                }
                                name="tipo"
                                value={data.tipo}
                            >
                                <option value="">Selecione um tipo</option>
                                <option value={'Whatsapp'}>Whatsapp</option>
                                <option value={'SMS'}>SMS</option>
                                <option value={'Email'}>Email</option>
                                <option value={'Telegram'}>Telegram</option>
                                <option value={'Instagram'}>Instagram</option>
                                <option value={'Facebook'}>Facebook</option>
                                <option value={'Voz'}>Voz</option>
                            </select>
                        </label>
                    </InputRow>

                    <InputRow>
                        <label class="input-default-zig">
                            <span>Selecione uma conta</span>
                            <select
                                onChange={(e) =>
                                    handleNewAccount(e.target.value)
                                }
                                name="oficial"
                                value={''}
                            >
                                <option value="" disabled={true}>
                                    Selecione
                                </option>
                                {accounts.map((row, key) => (
                                    <option
                                        disabled={
                                            data.accounts.filter(
                                                (obj) => obj._id === row._id
                                            ).length
                                                ? true
                                                : false
                                        }
                                        key={key}
                                        value={row._id}
                                    >
                                        {row.nome}
                                    </option>
                                ))}
                            </select>
                        </label>
                        <label class="input-default-zig">
                            <span>Chatbot</span>
                            <select
                                onChange={(e) =>
                                    handleOnChange('chatbotId', e.target.value)
                                }
                                name="chatbotId"
                                value={data.chatbotId}
                            >
                                <option value="">Selecione</option>
                                {chatbots.map((row, key) => (
                                    <option key={key} value={row.id}>
                                        {row.nome}
                                    </option>
                                ))}
                            </select>
                        </label>
                    </InputRow>

                    <h3>Contas</h3>

                    <InputRow>
                        {data.accounts.map((row, key) => (
                            <div class="input-default-zig" key={key}>
                                <p>{row.nome}</p>

                                <button
                                    onClick={() => handleNewAccount(row._id)}
                                    type="button"
                                    className="button-zig danger"
                                >
                                    <span>Remover</span>
                                </button>
                            </div>
                        ))}
                    </InputRow>

                    <h3>Acessos</h3>

                    <InputRow>
                        {data.access.map((row, key) => (
                            <div class="input-default-zig">
                                <InputDefault
                                    name={key}
                                    value={row}
                                    onChange={handleOnChangeAccess}
                                />

                                <button
                                    onClick={() => delAccess(row)}
                                    type="button"
                                    className="button-zig danger"
                                >
                                    <span>Remover</span>
                                </button>
                            </div>
                        ))}
                    </InputRow>

                    <button
                        onClick={addAccess}
                        type="button"
                        className="button-zig secondary"
                    >
                        <span>Adicionar novo acesso</span>
                    </button>

                    <button type="submit">Salvar</button>
                </FormDefault>
            </div>
        </div>
    );
}

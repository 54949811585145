import React, { useEffect } from 'react'
import messageStore from '../../Store/MessageStore'

const Name = (props) => {

    useEffect(() => {
        
    }, [])


    async function handleOnSave(e)
    {

        try {

            e.preventDefault()
            if ( !props.fields.nome ) throw "Preencha o nome da campanha."
            props.setStep(props.next)

        } catch (e) {
            messageStore.addError(e)
        }

    }

    return(
        <form className="step" onSubmit={handleOnSave} style={{display: props.step === props.num ? 'block' : 'none'}}>

            <h2>{props.nome}</h2>
            <p>Para iniciar a criação uma nova campanha ou escolha uma já existente.</p>

            <div className="separator">

                <div className="input">

                    <label>
                        <input 
                            name="nome"
                            onChange={(e) => props.handleOnChange( 'nome', e.target.value )}
                            type="text"
                            className="input-default" 
                            placeholder="Nome da Campanha"
                            defaultValue={props.fields.nome}
                        />
                    </label>

                    <div className="buttons">
                        { props.step !== 5 && <button>Próximo passo</button>}
                    </div>

                </div>
            </div>


        </form>
    )

}

export default Name
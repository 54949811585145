import React, {useState, useEffect} from 'react'
import { api } from '../../Api/app'
import messageStore from '../../Store/MessageStore'
import InputDefault from '../Form/InputDefault'
import FormDefault from '../Form/FormDefault'
import InputRow from '../Form/InputRow'
import IconButton from '../Icon/IconButton'

import "./ContactList.scss"

export default function ImportContacts(props)
{

    const open = props.open
    const [load, setLoad] = useState(false)
    const [progress, setProgress] = useState(false)
    const [filename, setFilename] = useState('')

    const [page, setPage] = useState(0)
    const [total, setTotal] = useState(0)

    const [columns, setColumns] = useState([])
    const [columnsFilename, setColumnsFilename] = useState([])
    const [columnsUser, setColumnsUser] = useState([])

    const [status, setStatus] = useState(false)

    async function handleUpload(e)
    {

        try {

            setLoad(true)

            var formData = new FormData()
                formData.append("file", e.target.files[0], e.target.files[0].name)

            let response = await api.post('contact-imports/upload', formData)

            console.log( response )

            setFilename( response.data.filename )
            setColumnsFilename( [...response.data.columns] )
            handleSplit( response.data.filename )
            setLoad( false )

        } catch ( e ) {

            console.log( e )
            messageStore.addError('Houve um erro ao ler o arquivo.')
            setLoad(false)

        }
        
    }

    async function handleSplit(filename)
    {

        try {

            let response = await api.post('contact-imports/split', {
                filename: filename
            })

            setTotal( response.data.total )

        } catch ( e ) {

            return 'Houve um erro ao configurar o arquivo.'

        }

    }

    async function handleProcess( pageRefresh = 1 )
    {

        try {


            if ( columns.length === 0 ) throw `É obrigatório o campo ${props.send === 'Email' ? 'email' : 'celular'}.`

            if ( !columns[0][1] ) throw `É obrigatório o campo ${props.send === 'Email' ? 'email' : 'celular'}.`

            if ( pageRefresh === 1 ) props.handleEditColumn( columnsUser )

            const data = {
                listaId: props.list._id,
                filename: filename,
                page: pageRefresh,
                total: total,
                hasHeader: true,
                columns: columns
            }

            setProgress(true)

            let response = await api.post('contact-imports/process', data)

            if ( response.data.page > 1 && response.data.total >= response.data.page ) {

                handleProcess( response.data.page )
                setPage(response.data.page)

            } else {

                setPage(0)
                setColumns([])
                setColumnsFilename([])
                setColumnsUser([])
                setProgress(false)
                props.setTab('list')
                messageStore.addSuccess('Importação completa.')

            }

            console.log( response.data )

        } catch ( e ) {

            messageStore.addError( e )

        }

    }

    function handleOnChange(key, value)
    {

        columnsUser[key] = value
        setColumnsUser([...columnsUser])

        const newColumns = []

        props.columns.map((row, key) => {

            newColumns.push([row, columnsUser[key] ? columnsUser[key] : null])

        })

        console.log( newColumns )

        setColumns([...newColumns])

    }

    function handleRedirect(tab)
    {

        props.setTab(tab)
        props.setRedirect('import')

    }

    return(

        <div style={{display: open ? 'block' : 'none'}}>

            <div className="overflow">

                <table className="table-default">
                
                    <thead>
                        <tr>
                            <th style={{whiteSpace: 'nowrap'}}>
                                Selecione um arquivo (CSV/TXT)
                            </th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}>

                                <input id="upload-button" onChange={handleUpload} type="file" name="file"/>

                                { load === true && <i className="fa fa-spin fa-spinner fa-2x" style={{marginLeft: 10}}></i> }

                            </td>
                            <td></td>
                        </tr>
                    </tbody>

                    { progress === false && columnsFilename.length > 0 &&
                    <>
                        <thead>
                            <tr>
                                <th>
                                    Dê um nome as colunas
                                </th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                        { props.columns.map((row, key) => 
                            <tr key={key}>
                                <td 
                                    width={200}
                                    style={{
                                        paddingRight: 0
                                    }}
                                >
                                    <label className="select-column input-default-zig">

                                        <select onChange={(e) => handleOnChange(key, e.target.value)}>

                                            <option value="">Selecione uma coluna</option>
                                            { columnsFilename.map((columnKey, i ) =>
                                                <option key={i} disabled={columnsUser.filter( obj => obj === columnKey).length > 0 ? true : false}>{columnKey}</option>
                                            )}

                                        </select>  

                                    </label>
                                </td>
                                <td>

                                    <i class="fa fa-long-arrow-left" aria-hidden="true" style={{marginRight: 5}}></i>
                                    <span>{row}</span>

                                </td>
                            </tr>
                        )}
                        <tr>
                            <td colSpan={2}>
                                <button onClick={() => handleRedirect('columns')} className="button-zig secondary">Gerenciar colunas</button>
                            </td>
                        </tr>
                        </tbody>
                    </>
                    }

                </table>

            </div>

            { progress &&
                <div className="process-load">
                    <div style={{
                        width: (page / total * 100)+'%'
                    }}></div>
                </div>
            }

            { progress === false &&
                <div className="tfoot">
                    
                    <button type="button" className="button-zig primary" style={{flexDirection: 'row'}} onClick={() => handleProcess(page)}>
                        <IconButton icon="new"/>
                        <span style={{marginLeft: '10px'}}>
                            Processar arquivo
                        </span>
                    </button>

                </div>
            }
            
        </div>

    )

}
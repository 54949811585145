import React, { useEffect, useState } from 'react'
import { CSVLink } from "react-csv"

export default function GetFila()
{

    const [text, setText] = useState('')

    const [data, setData] = useState([])
    
    const [headers, setHeaders] = useState(['Phone','Caption','ImageUrl'])

    const [filename, setFilename] = useState('export')

    useEffect(() => {

        try {

            const jsonFormat = JSON.parse(text)
            setData(jsonFormat.messages)

        } catch ( err ) {

            console.log('conversão errada.', err)

        }

    }, [text])


    return(
        <div>
            <input value={filename} onChange={(e) => setFilename(e.target.value)}/>
            <textarea style={{width: '100%', height: '50vh', backgroundColor: '#eaeaea'}} onChange={(e) => setText(e.target.value)}/>
            <CSVLink filename={filename+".csv"} data={data} headers={headers}>
                Download
            </CSVLink>
        </div>
    )

}
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import Messages from '../../Components/Webchat/Mesages'
import SendMessage from '../../Components/Webchat/SendMessage'
import { useContato } from '../../Firebase/useMessages'
import "./ChatWeb.scss"

export default function WebChat(props)
{

    const accountId = props.match.params.token
    const user = window.localStorage.getItem('userchatprofile') ? JSON.parse(window.localStorage.getItem('userchatprofile')) : {}
    const history = useHistory()
    const chatbot = props.location.search.split('?bot=').length > 1 ? props.location.search.split('?bot=')[1] : ''
    const [messages, setMessages] = useState([])

    useEffect(() => {
        if (!user.email) history.push('/webchat/login/'+accountId+'?bot='+chatbot)
        console.log(chatbot)
    }, [])

    return(
        <div id="chat-web-t2u">
            
            { user.email && 
                <>
                    <Messages
                        accountId={accountId}
                        conversationId={user.id}
                        origem={'usuario'}
                        setMessages={setMessages}
                        chatbot={chatbot}
                        messageId={'off'}
                    />

                    <SendMessage
                        accountId={accountId}
                        user={user}
                        origem={'usuario'}
                        chatbot={chatbot}
                        messages={messages}
                    />
                </>
            }

        </div>
    )

}
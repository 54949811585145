import React, { useEffect, useState } from 'react'
import { api } from '../../Api/app'
import Skeleton from 'react-loading-skeleton'
import messageStore from '../../Store/MessageStore'
import H1Page from '../../Components/Layout/H1Page'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import IconButton from '../../Components/Icon/IconButton'
import datesFormater from '../../Helper/DatesFormater'
import ModelChatbot from './ModelChatbot'
import Modal from '../../Components/Modal/Modal'

import { FunnelChart } from 'react-funnel-pipeline'
import 'react-funnel-pipeline/dist/index.css'

import "./Chatbot.scss"

export default function ChatbotIndex(props){

    const [load, setLoad] = useState(true)
    const [data, setData] = useState([])
    const [dataChart, setDataChart] = useState([])

    const Model = new ModelChatbot(
        [], 
        () => {}, 
        () => {}
    )

    const history = useHistory()
    

    useEffect(() => {

        getData()

    }, [props])

    async function getData()
    {

        setLoad(true)
        var response = await api.get('chatbots')
        setData(...[response.data])

        const responseData = await api.get(`relatorios/chatbot`)
        var chart = []

        responseData.data.map((row, key) => {

            let chartObject = []

            chartObject.push({
                "name": "Enviados",
                "value": parseInt(row.disparos),
                "color": "#0e5a7e"
            })

            chartObject.push({
                "name": "Respostas",
                "value": parseInt(row.respostas),
                "color": "#696969"
            })
    
            row.variaveis.map((rowV, keyV) => {
    
                let r = Math.random() * 255;
                let g = Math.random() * 255;
                let b = Math.random() * 255;
    
                chartObject.push({
                    "name": rowV.pergunta.substring(0, 1000),
                    "value": parseInt(rowV.respostas.length),
                    "color": `rgba(${r},${g},${b}, 1)`
                })
    
            })

            var newchartObject = chartObject.sort((a, b) => a.value > b.value ? - 1 : Number(a.value < b.value))

            newchartObject.push({
                "name": "Atendimentos",
                "value": parseInt(row.atendimento),
                "color": "#2185b4"
            })

            var dataChart = {
                chatbotId: row.chatbotId,
                chart: newchartObject
            }

            chart.push(dataChart)

        })

        setDataChart([...chart])

        setLoad( false )

    }
    
    async function handleNew()
    {

        const dataToApi = {
            nome: 'CapiBot',
            estrutura: JSON.stringify(Model.setElementsDefault()),
            atendimento: ['atendimento humano']
        }

        console.log( dataToApi )

        setLoad(true)
        var response = await api.post('chatbots', dataToApi)

        if ( response.data.error === true ){
            messageStore.addError(response.data.message)
        } else {
            history.push('/chatbot/'+response.data.id)
        }
        
        setLoad(false)

    }

    async function handleDelete(id)
    {

        setLoad(true)
        await api.delete('chatbots/'+id)
        getData()
        setLoad(false)

    }

    const tooltipRender = (props) => {
        if (props.point) {
          return props.point.dataItem.stat;
        }
    }

    return(
        
        <div className="page" id="chatbot">

            <div className="h1-button">

                <H1Page nome={"Meus Chatbots"}/>

                <div className="buttons">
                    <button onClick={handleNew} className="button-zig primary">
                        <IconButton icon="new"/>
                        <span>Criar novo Chatbot</span>
                    </button>
                </div>
                
            </div>

            <table className="table-default">

                <thead>
                    <tr>
                        <th colSpan={4}>
                            Nome do Chatbot
                        </th>
                    </tr>
                </thead>

                <tbody>
                    { load === true && 
                        [0,1,2].map((row, key) => 
                            <tr colSpan={4} key={key}>
                                <td><Skeleton/></td>
                            </tr>
                        )
                    }
                    { load === false && data.map((row, key) => 
                        <tr key={key}>
                            <td width="100%">
                                <b>{row.nome}</b><br/>
                                <span>Criado em {datesFormater.dateBR(row.createdAt)}</span>
                            </td>
                            <td>
                                <Modal title="Relatório chatbot" content={<a className="button-zig neutral">
                                    <IconButton icon="view"/>
                                    <span>Relatórios</span>
                                </a>}>

                                    <div className="chart-chatbot">

                                        <FunnelChart 
                                            data={dataChart.filter(obj => obj.chatbotId === row.id)[0].chart}
                                            style={{
                                                width: '100%'
                                            }}
                                        />
                                    </div>

                                </Modal>
                            </td>
                            <td>
                                <a className="button-zig primary" onClick={() => history.push("/chatbot/"+row.id)}>
                                    <IconButton icon="edit"/>
                                    <span>Editar</span>
                                </a>
                            </td>
                            <td>
                                <a className="button-zig danger" onClick={() => messageStore.addConfirm('Deseja remover o template "'+row.nome+'"', () => handleDelete(row.id))}>
                                    <IconButton icon="del"/>
                                    <span>Remover</span>
                                </a>
                            </td>
                        </tr>
                    )}
                </tbody>

            </table>

        </div>

    )

}
import React, { useEffect, useState } from 'react';

import './DetailContact.scss';
import InputDefault from '../Form/InputDefault';
import IconButton from '../Icon/IconButton';
import Axios from 'axios';
import { api } from '../../Api/app';
import messageStore from '../../Store/MessageStore';

function DetailContact({ id = false, setId = () => {} }) {
    const [DTO, setDTO] = useState({});

    useEffect(() => {
        setDTO({
            primeiroNome: '',
            sobrenome: '',
            email: '',
            cpf: '',
            sexo: '',
            dataNascimento: '',
            telefoneComercial: '',
            telefoneResidencial: '',
            celular: '',
            cepResidencial: '',
            enderecoResidencial: '',
            numeroResidencial: '',
            bairroResidencial: '',
            cidadeResidencial: '',
            ufResidencial: '',
            complementoResidencial: '',
            cepComercial: '',
            enderecoComercial: '',
            numeroComercial: '',
            bairroComercial: '',
            cidadeComercial: '',
            ufComercial: '',
            complementoComercial: '',
            foto: '',
            twitter: '',
            facebook: '',
            instagram: '',
            linkedin: '',
        });
        api.get('contacts/' + id)
            .then((response) => {
                setDTO({ ...response.data });
            })
            .catch((err) => {});
    }, [id]);

    async function handleOnChange(name, value) {
        DTO[name] = value;
        setDTO({ ...DTO });

        if (name === 'cepResidencial' && value.length === 9) {
            const response = await Axios.get(
                'https://viacep.com.br/ws/' + value + '/json/'
            );
            DTO.enderecoResidencial = response.data.logradouro;
            DTO.bairroResidencial = response.data.bairro;
            DTO.cidadeResidencial = response.data.localidade;
            DTO.ufResidencial = response.data.uf;
            setDTO({ ...DTO });
        } else if (name === 'cepComercial' && value.length === 9) {
            const response = await Axios.get(
                'https://viacep.com.br/ws/' + value + '/json/'
            );
            DTO.enderecoComercial = response.data.logradouro;
            DTO.bairroComercial = response.data.bairro;
            DTO.cidadeComercial = response.data.localidade;
            DTO.ufComercial = response.data.uf;
            setDTO({ ...DTO });
        }
    }

    const [load, setLoad] = useState(true);

    useEffect(() => {
        setLoad(true);

        setLoad(false);
    }, [id]);

    async function handleUpload(e) {
        try {
            const nameFileName = e.target.files[0].name;

            var formData = new FormData();
            formData.append('file', e.target.files[0], e.target.files[0].name);
            let response = await api.post('chatbots/upload', formData);
            if (response.data.error === true) throw response.data.message;
            DTO.foto = response.data.link;
            setDTO({ ...DTO });
        } catch (err) {
            alert('Erro ao fazer upload do arquivo.');
        }
    }

    async function handleSubmit() {
        const response = await api.put('contacts/' + id, DTO);
        messageStore.addSuccess('Salvo com sucesso.');
        console.log(response);
    }

    return (
        <div id="contact-detail" className={`${id ? 'active' : 'inactive'}`}>
            <div className="modal-contact-detail">
                <div className="head">
                    <h1>Informações do contato</h1>
                    <span onClick={() => setId(false)}>
                        <IconButton icon="close" />
                    </span>
                </div>

                <div className="infos">
                    <div className="photo">
                        <div
                            className="image"
                            style={{ backgroundImage: `url(${DTO.foto})` }}
                        />
                        <input type="file" onChange={handleUpload} />
                    </div>
                    <div className="form">
                        <div className="row">
                            <InputDefault
                                title="Primeiro nome"
                                value={DTO.primeiroNome}
                                name={'primeiroNome'}
                                onChange={handleOnChange}
                            />
                            <InputDefault
                                title="Sobrenome"
                                value={DTO.sobrenome}
                                name={'sobrenome'}
                                onChange={handleOnChange}
                            />
                        </div>
                        <div className="row">
                            <InputDefault
                                title="E-mail"
                                value={DTO.email}
                                name={'email'}
                                onChange={handleOnChange}
                            />
                            <InputDefault
                                title="CPF"
                                value={DTO.cpf}
                                name={'cpf'}
                                onChange={handleOnChange}
                                mask="999.999.999-99"
                            />
                        </div>
                        <div className="row">
                            <label className="input-default-zig">
                                <span>Sexo</span>
                                <select
                                    value={DTO.sexo}
                                    onChange={(e) =>
                                        handleOnChange('sexo', e.target.value)
                                    }
                                >
                                    <option value="">Selecione</option>
                                    <option>Masculino</option>
                                    <option>Feminino</option>
                                    <option>Outros</option>
                                </select>
                            </label>
                            <InputDefault
                                title="Data de nascimento"
                                value={DTO.dataNascimento}
                                name={'dataNascimento'}
                                onChange={handleOnChange}
                                mask="99/99/9999"
                            />
                        </div>

                        <h3>Redes sociais</h3>
                        <div className="row">
                            <InputDefault
                                title="Facebook"
                                value={DTO.facebook}
                                name={'facebook'}
                                onChange={handleOnChange}
                            />
                            <InputDefault
                                title="Instagram"
                                value={DTO.instagram}
                                name={'instagram'}
                                onChange={handleOnChange}
                            />
                        </div>
                        <div className="row">
                            <InputDefault
                                title="Twitter"
                                value={DTO.twitter}
                                name={'twitter'}
                                onChange={handleOnChange}
                            />
                            <InputDefault
                                title="Linkedin"
                                value={DTO.linkedin}
                                name={'linkedin'}
                                onChange={handleOnChange}
                            />
                        </div>

                        <h3>Endereço residencial</h3>
                        <div className="row">
                            <InputDefault
                                title="CEP"
                                value={DTO.cepResidencial}
                                name={'cepResidencial'}
                                onChange={handleOnChange}
                                mask="99999-999"
                                maskChar=""
                            />
                        </div>
                        <div className="row">
                            <InputDefault
                                title="Endereço"
                                value={DTO.enderecoResidencial}
                                name={'enderecoResidencial'}
                                onChange={handleOnChange}
                            />
                            <InputDefault
                                title="Número"
                                value={DTO.numeroResidencial}
                                name={'numeroResidencial'}
                                onChange={handleOnChange}
                            />
                            <InputDefault
                                title="Complemento"
                                value={DTO.complementoResidencial}
                                name={'complementoResidencial'}
                                onChange={handleOnChange}
                            />
                        </div>
                        <div className="row">
                            <InputDefault
                                title="Bairro"
                                value={DTO.bairroResidencial}
                                name={'bairroResidencial'}
                                onChange={handleOnChange}
                            />
                            <InputDefault
                                title="Cidade"
                                value={DTO.cidadeResidencial}
                                name={'cidadeResidencial'}
                                onChange={handleOnChange}
                            />
                            <InputDefault
                                title="Estado"
                                value={DTO.ufResidencial}
                                name={'ufResidencial'}
                                onChange={handleOnChange}
                            />
                        </div>
                        <h3>Endereço Comercial</h3>
                        <div className="row">
                            <InputDefault
                                title="CEP"
                                value={DTO.cepComercial}
                                name={'cepComercial'}
                                onChange={handleOnChange}
                                mask="99999-999"
                                maskChar=""
                            />
                        </div>
                        <div className="row">
                            <InputDefault
                                title="Endereço"
                                value={DTO.enderecoComercial}
                                name={'enderecoComercial'}
                                onChange={handleOnChange}
                            />
                            <InputDefault
                                title="Número"
                                value={DTO.numeroComercial}
                                name={'numeroComercial'}
                                onChange={handleOnChange}
                            />
                            <InputDefault
                                title="Complemento"
                                value={DTO.complementoComercial}
                                name={'complementoComercial'}
                                onChange={handleOnChange}
                            />
                        </div>
                        <div className="row">
                            <InputDefault
                                title="Bairro"
                                value={DTO.bairroComercial}
                                name={'bairroComercial'}
                                onChange={handleOnChange}
                            />
                            <InputDefault
                                title="Cidade"
                                value={DTO.cidadeComercial}
                                name={'cidadeComercial'}
                                onChange={handleOnChange}
                            />
                            <InputDefault
                                title="Estado"
                                value={DTO.ufComercial}
                                name={'ufComercial'}
                                onChange={handleOnChange}
                            />
                        </div>
                        <button
                            onClick={handleSubmit}
                            className="button-zig primary"
                        >
                            Salvar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DetailContact;

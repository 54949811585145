import React from 'react';
import ReactDOM from 'react-dom';
import GlobalProvider from './contexts/GlobalContext';
import Routes from './Routes';
import * as serviceWorker from './serviceWorker';

import './Styles/App.scss';
import './Styles/Global.scss';

ReactDOM.render(
    <React.StrictMode>
        <GlobalProvider>
            <Routes />
        </GlobalProvider>
    </React.StrictMode>,
    document.getElementById('tropa')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { useEffect, useState } from 'react';

//scss
import './NovaCampanha.scss';
import H1Page from '../../Components/Layout/H1Page';
import Name from '../../Components/CampgainCreation/Name';
import LandingPage from '../../Components/CampgainCreation/LandingPage';
import Lists from '../../Components/CampgainCreation/Lists';
import Messages from '../../Components/CampgainCreation/Messages';
import Send from '../../Components/CampgainCreation/Send';
import messageStore from '../../Store/MessageStore';
import IconButton from '../../Components/Icon/IconButton';
import { api } from '../../Api/app';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Chatbot from '../../Components/CampgainCreation/Chatbot';
import AudioVoice from '../../Components/CampgainCreation/AudioVoice';

export default function Layout(props) {
    const history = useHistory();

    const [step, setStep] = useState(1);
    const [send, setSend] = useState(props.match.url.split('/')[2]);

    const [steps, setSteps] = useState([]);

    useEffect(() => {
        let stepsData = [];

        if (send === 'Whatsapp') {
            stepsData = [
                {
                    num: 1,
                    nome: 'Nome',
                    Component: Name,
                    next: 2,
                    prev: 0,
                },
                {
                    num: 2,
                    nome: 'Customização',
                    Component: LandingPage,
                    next: 3,
                    prev: 1,
                },
                {
                    num: 3,
                    nome: 'Chatbot',
                    Component: Chatbot,
                    next: 4,
                    prev: 2,
                },
                {
                    num: 4,
                    nome: 'Público',
                    Component: Lists,
                    next: 5,
                    prev: 3,
                },
                {
                    num: 5,
                    nome: 'Configuração',
                    Component: Messages,
                    next: 6,
                    prev: 4,
                },
                {
                    num: 6,
                    nome: 'Publicação',
                    Component: Send,
                    prev: 5,
                },
            ];
        } else if (send === 'SMS') {
            stepsData = [
                {
                    num: 1,
                    nome: 'Nome',
                    Component: Name,
                    next: 2,
                    prev: 0,
                },
                {
                    num: 2,
                    nome: 'Customização',
                    Component: LandingPage,
                    next: 3,
                    prev: 1,
                },
                {
                    num: 3,
                    nome: 'Público',
                    Component: Lists,
                    next: 4,
                    prev: 2,
                },
                {
                    num: 4,
                    nome: 'Configuração',
                    Component: Messages,
                    next: 5,
                    prev: 3,
                },
                {
                    num: 5,
                    nome: 'Publicação',
                    Component: Send,
                    prev: 4,
                },
            ];
        } else if (send === 'Email') {
            stepsData = [
                {
                    num: 1,
                    nome: 'Nome',
                    Component: Name,
                    next: 2,
                    prev: 0,
                },
                {
                    num: 2,
                    nome: 'Público',
                    Component: Lists,
                    next: 3,
                    prev: 1,
                },
                {
                    num: 3,
                    nome: 'Configuração',
                    Component: Messages,
                    next: 4,
                    prev: 2,
                },
                {
                    num: 4,
                    nome: 'Publicação',
                    Component: Send,
                    prev: 3,
                },
            ];
        } else if (send === 'Voz') {
            stepsData = [
                {
                    num: 1,
                    nome: 'Nome',
                    Component: Name,
                    next: 2,
                    prev: 0,
                },
                {
                    num: 2,
                    nome: 'Público',
                    Component: Lists,
                    next: 3,
                    prev: 1,
                },
                {
                    num: 3,
                    nome: 'Configuração',
                    Component: AudioVoice,
                    next: 4,
                    prev: 2,
                },
                {
                    num: 4,
                    nome: 'Publicação',
                    Component: Send,
                    prev: 3,
                },
            ];
        }

        setSteps([...stepsData]);
    }, []);

    const MetodoBroker = () => (
        <div className="modal-send-phone">
            <div className="modal">
                <h2>Por qual método você deseja enviar?</h2>

                <div className="send-campgain-type">
                    <div className="phone sms" onClick={() => setSend('SMS')}>
                        <button>SMS</button>
                    </div>

                    {/* <div className="phone flashsms" onClick={() => setSend('FlashSMS')}>
                            <button>FlashSMS</button>
                        </div> */}

                    <div
                        className="phone whatsapp"
                        onClick={() => setSend('Whatsapp')}
                    >
                        <button>WhatsApp</button>
                    </div>

                    <div
                        className="phone whatsapp"
                        onClick={() => setSend('Email')}
                    >
                        <button>E-mail</button>
                    </div>
                </div>
            </div>
        </div>
    );

    const fieldsDefault = {
        nome: '',
        id_chatbot: null,
        id_template: null,
        id_template_oficial: null,
        id_lista: '',
        mensagem: '',
        imagem: '',
        periodo_inicial: '',
        colunas: [],
    };

    const [fields, setFields] = useState(
        props.location.state ? props.location.state.fields : fieldsDefault
    );

    const [load, setLoad] = useState(false);

    useEffect(() => {
        console.log(fields);

        if (props.location.state) {
            const stateRedirect = props.location.state;

            console.log(stateRedirect);

            setFields({ ...stateRedirect.fields });
            setSend(stateRedirect.send);
            setStep(stateRedirect.step);
        }
    }, [props]);

    useEffect(() => {
        console.log('uPDATE FIELDS', fields);
    }, [fields]);

    function handleOnChange(name, value) {
        const data = fields;
        data[name] = value;

        console.log(name, value, data);

        setFields(data);
    }

    async function handleOnSave() {
        try {
            fields.send = send;

            if (load === true) throw 'A campanha já esta sendo processada.';

            setLoad(true);

            if (!fields.nome) {
                setStep(1);
                throw 'Nome da campanha é obrigatório.';
            }

            if (fields.id_lista.length === 0) {
                setStep(3);
                throw 'Selecione ao menos uma lista de contatos.';
            }

            const imageFile = fields.imagem
                ? fields.imagem.split('/files/templates/')[1]
                : null;

            const data = {
                chatbotId: fields.id_chatbot,
                templateId: fields.id_template,
                templateOficialId: fields.id_template_oficial,
                contactListId: fields.id_lista,
                tipoDeEnvio: send,
                nome: fields.nome,
                mensagem: fields.mensagem,
                bina: fields.bina,
                tentativas: fields.tentativas,
                imagem: imageFile,
                dataEnvio: fields.dataEnvio ? fields.dataEnvio : new Date(),
                frontend: 'https://t-2u.com/c/',
            };

            const response = await api.post('campaigns', data);

            if (response.data.error === true) throw response.data.message;

            sendQueue(response.data.result.id);
        } catch (e) {
            setLoad(false);
            messageStore.addError('Houve um erro ao criar campanha.');
        }
    }

    async function sendQueue(id) {
        try {
            setLoad(true);

            const response = await api.post('/queues/' + id);

            if (!response.data.status) throw 'Erro ao processar campanha.';

            if (response.data.status != 'Finalizado') {
                sendQueue(id);
            } else {
                messageStore.addSuccess('Campanha salva com sucesso.');
                history.push('/campanhas');

                //setTimeout(function(){
                await api.get('cron/' + send.toLowerCase(), {
                    headers: {
                        Authorization:
                            'Bearer eyJhbGciOiJIUzI1NiJ9.NjFmOThkNzdkYjUxMWMxMTkwMGY1MWNl.SU2KHPWDk4ajhqUuviwqm4xqPS9GiHAPN8M04xOQRgE',
                    },
                });
                //}, 2000)
            }

            setLoad(false);
        } catch (e) {
            console.log(e);
            setLoad(false);
            messageStore.addError('Houve um erro ao inserir envios na fila.');
        }
    }

    return (
        <>
            <div id="novaCampanha" className="page">
                <H1Page nome={`Criar nova campanha de ${send}`} />

                <div className="steps">
                    {/* <div className="step-bt" onClick={() => setSend(null)}>
                    <button className={'send'}>
                        <b>Passo 01</b>
                        <span>Método de envio</span>
                    </button>
                    <span></span>
                </div>  */}

                    {steps.map((row, key) => (
                        <div key={key} className="step-bt">
                            <button
                                className={
                                    step === row.num ? 'active' : 'no-active'
                                }
                                key={key}
                            >
                                <b>Passo 0{key + 1}</b>
                                <span>{row.nome}</span>
                            </button>
                            <span></span>
                        </div>
                    ))}
                </div>

                {!send && <MetodoBroker />}

                {steps.map((row, key) => (
                    <row.Component
                        next={row.next}
                        prev={row.prev}
                        num={row.num}
                        countPages={steps.length}
                        load={load}
                        key={key}
                        step={step}
                        setStep={setStep}
                        fields={fields}
                        send={send}
                        handleOnSave={handleOnSave}
                        handleOnChange={handleOnChange}
                    />
                ))}
            </div>
        </>
    );
}

import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { api } from '../../Api/app';

export default function Profile({ setCanais }) {
    const [load, setload] = useState(true);
    const [permissoes, setPermissoes] = useState([]);

    useEffect(() => {
        api.get('profile')
            .then((response) => {
                response.data.permissoes.map((row, key) => {
                    permissoes.push({
                        label: row,
                        value: row,
                    });
                });
                setPermissoes([...permissoes]);
                setload(false);
            })
            .catch((err) => {
                setload(false);
            });
    }, []);

    useEffect(() => {
        setCanais(permissoes);
    }, [permissoes]);

    return (
        <div id="profile">
            <p>Selecione abaixo, apenas os canais que você quer visualizar.</p>
            <Select
                defaultValue={permissoes}
                placeholder="Selecionar canais"
                isMulti
                onChange={setPermissoes}
                options={permissoes}
            />
        </div>
    );
}

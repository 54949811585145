import Axios from 'axios';
import React, { useState } from 'react';
import InputDefault from '../../Components/Form/InputDefault';
import messageStore from '../../Store/MessageStore';

function TesteAPI() {
    const [load, setLoad] = useState(false);
    const [phones, setPhones] = useState([]);
    const [repeat, setRepeat] = useState(1);
    const [errors, setErrors] = useState(0);
    const [success, setSuccess] = useState(0);

    async function handleReload(executes, success = 0, errors = 0) {
        executes = parseFloat(executes);
        if (parseFloat(repeat) > parseFloat(executes)) {
            const loop = async (phone) => {
                try {
                    const response = await Axios.post(
                        'https://producao.text2you.com.br/api/whatsapp/send-message',
                        {
                            to: phone,
                            type: 'hsm',
                            hsm: {
                                id: '61296176d752dc0fbea3ece6',
                                variables: {
                                    1: 'Fulano',
                                },
                            },
                        },
                        {
                            headers: {
                                Authorization:
                                    'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI1ZjhiNDE3ODE1MjY3MTMxNjA1ZGVkMzYiLCJhY2NvdW50SWQiOiI1ZjhiNDE3ODE1MjY3MTMxNjA1ZGVkMzUiLCJpYXQiOjE2MzU1MTMyNjl9.1Sn5VUTTiNb91mPkzR9NVn-pG_PUycWdfr1IN5I7GTc',
                            },
                        }
                    );

                    if (response.data.error === true)
                        throw 'Retorno error true API';

                    return Promise.resolve({
                        error: false,
                        result: response.data,
                    });
                } catch (err) {
                    return Promise.resolve({
                        error: true,
                        message: err,
                    });
                }
            };
            let responses = await Promise.all(phones.map((row) => loop(row)));
            console.log(responses);

            const newSuccess =
                success + responses.filter((obj) => obj.error === false).length;

            const newErrors =
                errors + responses.filter((obj) => obj.error === true).length;

            setSuccess(newSuccess);
            setErrors(newErrors);

            handleReload(executes + 1, newSuccess, newErrors);
        } else {
            messageStore.addSuccess('Execuções concluidas');
            setLoad(false);
        }
    }

    function handleSubmit() {
        setLoad(true);
        handleReload(0);
    }

    return (
        <div
            style={{
                padding: '20px',
                display: 'flex',
                justifyContent: 'space-between',
            }}
        >
            <div>
                <fieldset>
                    <legend>Celulares</legend>
                    {phones.map((row, key) => (
                        <InputDefault
                            name={key}
                            key={key}
                            value={row}
                            onChange={(name, value) => {
                                phones[name] = value;
                                setPhones([...phones]);
                            }}
                            mask="5599999999999"
                        />
                    ))}
                    <button
                        className="button-zig primary"
                        onClick={() => {
                            phones.push('');
                            setPhones([...phones]);
                        }}
                    >
                        Adicionar celular
                    </button>
                </fieldset>
                <fieldset>
                    <legend>Repetições</legend>
                    <InputDefault
                        value={repeat}
                        onChange={(name, value) => setRepeat(parseFloat(value))}
                    />
                </fieldset>

                <button className="button-zig secondary" onClick={handleSubmit}>
                    {load === false ? 'Executar' : 'Carregando'}
                </button>
            </div>

            <div>
                <h1>Sucessos: {success}</h1>
                <h1>Erros: {errors}</h1>
            </div>
        </div>
    );
}

export default TesteAPI;

import React, { useEffect, useState } from 'react'

export default function Test(props) {

    return(

        <div id="login">

            <a href="exp://192.168.15.6:19000/--/Detail?id=60be4ac0737e3400133adb5e">Abrir APP Expo</a>
            <a href="meatapp://coooker?id=123">Abrir APP Real</a>

        </div>

    )

}
import React, { useEffect, useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useLocation,
    Redirect,
    useHistory,
} from 'react-router-dom';
import { api, TOKEN } from './Api/app';

import ReactNotification, { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

//views
import Dashboard from './Pages/Dashboard/Dashboard';
import Login from './Pages/Login/Login';

//template
import Index from './Pages/Templates/Index';
import Creation from './Pages/Templates/Creation';
import Detalhe from './Pages/Templates/Detalhe';

//campanhas
import NovaCampanha from './Pages/NovaCampanha/NovaCampanha';
import Campanhas from './Pages/Campanhas/Campanhas';

//components
import LayoutPrivate from './Components/Layout/LayoutPrivate';
import Configuracoes from './Pages/Usuario/Configuracoes';
import Faq from './Pages/Faq/Faq';
import Notificacoes from './Pages/Notificacoes/Notificaoes';
import DadosPagamento from './Pages/Usuario/DadosPagamento';
import Analytics from './Pages/Analytics/Analytics';

import messageStore from './Store/MessageStore';
import Monitoramento from './Pages/Monitoramento/Monitoramento';
import Contas from './Pages/Administracao/Contas';
import ContasForm from './Pages/Administracao/ContasForm';
import UsuariosForm from './Pages/Administracao/UsuariosForm';
import Brokers from './Pages/Administracao/Brokers';
import BrokersForm from './Pages/Administracao/BrokersForm';
import CronManual from './Pages/Administracao/CronManual';
import GetFila from './Pages/Administracao/GetFila';
import DetalheHeatMap from './Pages/Templates/DetalheHeatMap';
import RelatoriosTemplate from './Pages/Analytics/RelatoriosTemplate';
import RelatorioEmail from './Pages/Analytics/RelatorioEmail';
import Chatbot from './Pages/Chatbot/Chatbot';
import ChatbotIndex from './Pages/Chatbot/ChatbotIndex';
import Test from './Pages/Login/Test';
import Higienizacao from './Pages/Higienizacao/Higienizacao';
import TemplatesOficiais from './Pages/TemplatesOficiais/Index';
import GestaoContatos from './Pages/GestaoContatos/GestaoContatos';
import Contatos from './Pages/GestaoContatos/Contatos';
import WebChat from './Pages/Webchat/WebChat';
import WebChatLogin from './Pages/Webchat/WebChatLogin';
import ChatWeb from './Pages/Chat/Web';
import RelatorioChat from './Pages/Webchat/RelatorioChat';
import RelatorioChatbot from './Pages/Chatbot/RelatorioChatbot';
import Envios from './Pages/Analytics/Envios';
import GlobalProvider, { useGlobal } from './contexts/GlobalContext';
import Chat from './Pages/Chat/Chat';
import TesteAPI from './Pages/TesteAPI/TesteAPI';

const Tickets = () => (
    <div className="page">
        <h1>Tickets</h1>
    </div>
);

//export function default
export default function Routes(props) {
    const [loading, setLoading] = useState(true);

    const [logs, setLogs] = useState([]);

    const [redirectLogin, setRedirectLogin] = useState(false);

    const { user, setUser } = useGlobal();

    useEffect(() => {
        if (TOKEN) {
            api.get('profile')
                .then((profile) => {
                    setUser(profile.data);
                    setUser(profile.data);
                    setLoading(false);

                    api.get('logs').then((logs) => {
                        setLogs(logs.data);
                    });
                })
                .catch((e) => {
                    messageStore.addError('Sua sessão foi finalizada.');
                    window.localStorage.removeItem('token');
                    setRedirectLogin(true);
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, [props]);

    function NoMatch() {
        let location = useLocation();

        return (
            <div id="page-no-banner">
                <section>
                    <div className="container">
                        <h1>Pagina nao encontrada.</h1>
                        <h3>
                            <code>{location.pathname}</code>
                        </h3>
                    </div>
                </section>
            </div>
        );
    }

    function PrivateRoute({ children, ...rest }) {
        return (
            <Route
                {...rest}
                render={({ location }) =>
                    window.localStorage.getItem('token') ? (
                        children
                    ) : (
                        <Redirect
                            to={{
                                pathname: '/login',
                                state: { from: location },
                            }}
                        />
                    )
                }
            />
        );
    }

    function QRCode(){

        return(
            <div style={{width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <img src="/qrcode.png"/>
            </div>
        )

    }

    return (
        <Router>
            <ReactNotification />
            {redirectLogin === true && (
                <Redirect
                    to={{
                        pathname: '/login',
                        state: {},
                    }}
                />
            )}

            {loading === true && <div>Carregando</div>}

            {loading === false && (
                <Switch>
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/test-api" component={TesteAPI} />
                    <Route exact path="/app" component={Test} />
                    <Route exact path="/t/:id" component={Detalhe} />
                    <Route exact path="/c/:shorturl" component={Detalhe} />
                    <Route exact path="/cron-manual" component={CronManual} />
                    <Route
                        exact
                        path="/thm/:shorturl"
                        component={DetalheHeatMap}
                    />
                

                    <Route
                        exact
                        path="/webchat/auth/:token"
                        component={WebChat}
                    />
                    <Route
                        exact
                        path="/webchat/login/:token"
                        component={WebChatLogin}
                    />

                    <Route exact path="/getfila" component={GetFila} />
                    <Route
                        exact
                        path="/monitoramento"
                        component={Monitoramento}
                    />
                    <Route
                        exact
                        path="/monitoramento/:tempo/:user"
                        component={Monitoramento}
                    />

                    <LayoutPrivate logs={logs}>
                        <PrivateRoute exact path="/">
                            <Route component={Dashboard} />
                        </PrivateRoute>

                        <PrivateRoute exact path="/higienizacao">
                            <Route component={Higienizacao} />
                        </PrivateRoute>

                        <PrivateRoute exact path="/chat">
                            <Route component={Chat} />
                        </PrivateRoute>

                        <PrivateRoute exact path="/chat/web">
                            <Route component={ChatWeb} />
                        </PrivateRoute>

                        <PrivateRoute exact path="/nova-campanha/whatsapp">
                            <Route component={NovaCampanha} />
                        </PrivateRoute>

                        <PrivateRoute exact path="/nova-campanha/sms">
                            <Route component={NovaCampanha} />
                        </PrivateRoute>

                        <PrivateRoute exact path="/nova-campanha/email">
                            <Route component={NovaCampanha} />
                        </PrivateRoute>

                        <PrivateRoute exact path="/nova-campanha/voz">
                            <Route component={NovaCampanha} />
                        </PrivateRoute>

                        <PrivateRoute exact path="/campanhas">
                            <Route component={Campanhas} />
                        </PrivateRoute>

                        <PrivateRoute exact path="/templates-oficiais">
                            <Route component={TemplatesOficiais} />
                        </PrivateRoute>

                        <PrivateRoute exact path="/gestao-contatos">
                            <Route component={GestaoContatos} />
                        </PrivateRoute>

                        <PrivateRoute exact path="/gestao-contatos/:id">
                            <Route component={Contatos} />
                        </PrivateRoute>

                        <PrivateRoute exact path="/templates/">
                            <Route component={Index} />
                        </PrivateRoute>

                        <PrivateRoute exact path="/templates/create/:id">
                            <Route component={Creation} />
                        </PrivateRoute>

                        <PrivateRoute exact path="/relatorio-chat">
                            <Route component={RelatorioChat} />
                        </PrivateRoute>

                        <PrivateRoute exact path="/relatorio-chatbot">
                            <Route component={RelatorioChatbot} />
                        </PrivateRoute>

                        <PrivateRoute exact path="/analytics/:id">
                            <Route component={Analytics} />
                        </PrivateRoute>

                        <PrivateRoute exact path="/envios">
                            <Route component={Envios} />
                        </PrivateRoute>

                        <PrivateRoute exact path="/templates/relatorio">
                            <Route component={RelatoriosTemplate} />
                        </PrivateRoute>

                        <PrivateRoute exact path="/templates/relatorio/:id">
                            <Route component={RelatoriosTemplate} />
                        </PrivateRoute>

                        <PrivateRoute exact path="/email/relatorio">
                            <Route component={RelatorioEmail} />
                        </PrivateRoute>

                        <PrivateRoute exact path="/email/relatorio/:id">
                            <Route component={RelatorioEmail} />
                        </PrivateRoute>

                        <PrivateRoute exact path="/configuracoes">
                            <Route component={Configuracoes} />
                        </PrivateRoute>

                        <PrivateRoute
                            exact
                            path="/configuracoes/dados-pagamento"
                        >
                            <Route component={DadosPagamento} />
                        </PrivateRoute>

                        <PrivateRoute exact path="/tickets">
                            <Route component={Tickets} />
                        </PrivateRoute>

                        <PrivateRoute exact path="/faq">
                            <Route component={Faq} />
                        </PrivateRoute>

                        <PrivateRoute exact path="/notificacoes">
                            <Route component={Notificacoes} />
                        </PrivateRoute>

                        <PrivateRoute exact path="/adm-contas">
                            <Route component={Contas} />
                        </PrivateRoute>

                        <PrivateRoute exact path="/adm-contas/:id">
                            <Route component={ContasForm} />
                        </PrivateRoute>

                        <PrivateRoute exact path="/adm-contas/usuarios/:id">
                            <Route component={UsuariosForm} />
                        </PrivateRoute>

                        <PrivateRoute
                            exact
                            path="/adm-contas/usuarios/new/:accountId"
                        >
                            <Route component={UsuariosForm} />
                        </PrivateRoute>

                        <PrivateRoute exact path="/adm-brokers">
                            <Route component={Brokers} />
                        </PrivateRoute>

                        <PrivateRoute exact path="/adm-brokers/:id">
                            <Route component={BrokersForm} />
                        </PrivateRoute>

                        <PrivateRoute exact path="/chatbot/:id">
                            <Route component={Chatbot} />
                        </PrivateRoute>

                        <PrivateRoute exact path="/chatbot">
                            <Route component={ChatbotIndex} />
                        </PrivateRoute>
                    </LayoutPrivate>
                </Switch>
            )}
        </Router>
    );
}

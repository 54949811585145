const axios = require('axios');

var baseUrl = 'https://text2you.backendtropa.com.br/';

const TOKEN = window.localStorage.getItem('token');

const api = axios.create({
    baseURL: baseUrl,
    headers: {
        Authorization: 'Bearer ' + TOKEN,
    },
});

export { api, TOKEN, baseUrl };

import React, { useEffect, useState } from 'react'
import editMessages from '../../Firebase/editMessages'
import { useHistory } from 'react-router-dom'
import { api } from '../../Api/app'
import messageStore from '../../Store/MessageStore'

import "./SendMessage.scss"
import { useContato } from '../../Firebase/useMessages'

export default function SendMessage({
    accountId, 
    user = {}, 
    origem = 'usuario',
    operator = {},
    chatbot = null,
    messages = [],
    chatbotAttendance = false,
    children,
})
{

    const history = useHistory()
    const [message, setMessage] = useState('')
    const [uploadArquivo, setUploadArquivo] = useState(false)
    const [submenuMedias, setSubmenuMedias] = useState(false)

    const userChanges = useContato(accountId, user.id)

    useEffect(() => {

        //if ( userChanges.contato?.active?.status === false ) window.localStorage.removeItem('userchatprofile')

    }, [userChanges])

    async function handleOnSent(e)
    {
        try {
            
            e.preventDefault()

            if ( !message ) throw "Mensagem obrigatória."

            editMessages.SendMessage(
                user,
                accountId,
                message,
                'text',
                origem,
                operator,
            )

            setMessage('')
            setSubmenuMedias(false)

            setTimeout(() => sendChatbot(), 500)

        } catch ( err ) {

        }
    }

    async function sendChatbot()
    {

        if ( origem === 'usuario' && chatbot ) {

            const lastMessageBot = messages.filter( obj => obj.origem === 'operador' ).length > 0 ? messages.filter( obj => obj.origem === 'operador' ).reverse()[0] : {}
            const data = {
                grupo: `m-${accountId}-${user.id}`,
                chatbotId: chatbot,
                mensagemRecebida: message,
                chatbotFluxogramaId: lastMessageBot.chatbotFluxogramaId
            }

            const sendChatbot = await api.post('callback/chat/message', data)

            if ( sendChatbot.data?.responses?.falarComAtendente === true ) {
                editMessages.editContato(accountId, user.id, false, origem, 'false')
                history.push(`/webchat/auth/${accountId}`)
            }

        }

    }

    async function handleOnUpload( e, tipo = "" )
    {

        try {
            
            const nameFileName = e.target.files[0].name
            const fileInfos = e.target.files[0]
    
            var formData = new FormData()
            formData.append("file", e.target.files[0], nameFileName)
    
            let response = await api.post('chatbots/upload', formData)
    
            setUploadArquivo({
                tipoBotao: tipo,
                fileInfos: fileInfos,
                url: response.data.link
            })

            setSubmenuMedias(false)

        } catch ( err ) {

        }


    }

    async function handleSendMedias()
    {

        setSubmenuMedias(false)

        editMessages.SendMessage(
            user,
            accountId,
            uploadArquivo.url,
            uploadArquivo.tipoBotao,
            origem,
            operator,
        )

        setUploadArquivo({})

    }

    async function handleTerminateChat()
    {

        setSubmenuMedias(false)

        await editMessages.editContato(accountId, user.id, false, origem, false, {
            status: false,
            origem: origem,
        }, false, operator)

    }

    return(
        <form onSubmit={handleOnSent} className={`send-message ${userChanges.contato?.active?.status === true ? 'active' : 'inactive'}`}>

            { children }

            { uploadArquivo.url && 
                <div className="preview-send">

                    <div className="head">
                        <button onClick={() => setUploadArquivo({})}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="currentColor" d="M19.1 17.2l-5.3-5.3 5.3-5.3-1.8-1.8-5.3 5.4-5.3-5.3-1.8 1.7 5.3 5.3-5.3 5.3L6.7 19l5.3-5.3 5.3 5.3 1.8-1.8z"></path></svg>
                        </button>
                        <span>Pré-visualizar</span>
                    </div>

                    <div className="preview">

                        { uploadArquivo.fileInfos.type.split('image').length > 1 &&
                            <img src={uploadArquivo.url}/>
                        }

                        { uploadArquivo.fileInfos.type.split('video').length > 1 &&
                            <video width="320" height="240" controls>
                                <source src={uploadArquivo.url} type="video/mp4"/>
                                <source src={uploadArquivo.url} type="video/ogg"/>
                            </video>
                        }

                        { uploadArquivo.fileInfos.type.split('audio').length > 1 &&
                            <audio controls>
                                <source src={uploadArquivo.url} type="audio/ogg"/>
                                <source src={uploadArquivo.url} type="audio/mpeg"/>
                            </audio>
                        }

                        { uploadArquivo.fileInfos.type.split('video').length === 1 && uploadArquivo.fileInfos.type.split('image').length === 1 && uploadArquivo.fileInfos.type.split('audio').length === 1 &&
                            <img src={"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAA8CAYAAADL94L/AAAByElEQVR4Ae3axdJTQRAFYFyegA3u8ALseCDcicsGhxt3x+G32BXc3X3NBnfXYTqp3sZlhuqpOlXZRL46He9ReJyJxGSTEreaPfEHZiX+1uSJvelVNu+Jvjd7Yk9zI8aSUe0eDpjCIYfNSuw5v/zF5In/6mU27478tXriLJvXjdSwPq1lCDTCmxjiCNav8GZYBVMwWKagX8kWjk9vCcMhYWhEFEw1+oV0wZjdPKY6Vn9EwmBDTYPwBoXCYPLGDQTJjkHQNQRJj0FQtmgs+C8wOHIIkh2DoDu5vD5Xfkz9hsTBWDyxhjDYUDqvLRYSY1JilSQGyyxXOt4QKJPX70NDQmI27gyxHcn9bH/5RFMNAUgoDI4afOAMHBiCdiDNj5woGAhgsCEYudSI1lBCRwoPL957slAoDDYEoPXb/ZVs3FE/y9072fDxsx4BMPVfGOpl1VY/y5++4EWM1Fm9LcCKpy8RpnchDGEIQxjCEIYwhCEMYQhDGMIQhjCEIQxhCEMYwhCGMIQhDGEIQxhYNlXiP+XHXLRDM5thQVpyzIfS2YtLceVEkRmzalsgMArPhp258bA6b/LEb8LqPM930VNdvY/fhMmCxw+Of+4BTcPInBo2AAAAAElFTkSuQmCC"}/>
                        }

                        <span>{uploadArquivo.fileInfos.name}</span>

                        <button className="button-zig column full secondary" onClick={handleSendMedias}>Enviar</button>

                    </div>

                </div>
            }

            <div className="input">
                <input readOnly={chatbotAttendance} placeholder={chatbotAttendance === true ? 'Chat indísponivel até o final do atendiment do bot.' : 'Digite uma mensagem...'} value={message} onChange={(e) => setMessage(e.target.value)} />
            </div>

            <div className="media-upload">

                <button onClick={() => setSubmenuMedias(!submenuMedias)}>
                    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 36 36"><path class="clr-i-outline--badged clr-i-outline-path-1--badged" d="M11.1 18c0 3.9 3.1 7 7 7s7-3.1 7-7s-3.1-7-7-7s-7 3.1-7 7zm12 0c0 2.8-2.2 5-5 5s-5-2.2-5-5s2.2-5 5-5s5 2.2 5 5z" fill="currentColor"/><path class="clr-i-outline--badged clr-i-outline-path-2--badged" d="M32.8 14.7l-2.8-.9l-.1-.3c-.8 0-1.6-.2-2.4-.4c.3.6.6 1.3.8 1.9l.1.5l3.6 1.1v2.8l-3.6 1.1l-.1.5l-.9 2.1l-.3.5l1.8 3.3l-2 2l-3.3-1.8l-.5.3c-.7.4-1.4.7-2.1.9l-.5.1l-1.1 3.6h-2.8l-1.1-3.6l-.5-.1l-2.1-.9l-.5-.3l-3.3 1.8l-2-2l1.8-3.3l-.3-.5c-.4-.7-.7-1.4-.9-2.1l-.1-.5L4 19.4v-2.8l3.4-1l.2-.5c.2-.8.5-1.5.9-2.2l.3-.5l-1.7-3.3l2-2l3.2 1.8l.5-.3c.7-.4 1.4-.7 2.2-.9l.5-.2L16.6 4h2.8l1.1 3.5l.5.2c.7.2 1.3.5 1.9.8c-.3-.8-.4-1.6-.4-2.5l-.4-.2l-.9-2.8c-.2-.5-.8-1-1.5-1h-3.4c-.7 0-1.3.5-1.4 1.2L14 6c-.6.1-1.1.3-1.6.6L9.8 5.2c-.6-.3-1.4-.2-1.9.3L5.5 7.9c-.5.5-.6 1.3-.3 1.9l1.3 2.5c-.2.5-.4 1.1-.6 1.6l-2.8.9c-.6.2-1.1.8-1.1 1.5v3.4c0 .7.5 1.3 1.2 1.5l2.8.9l.6 1.5l-1.4 2.6c-.3.6-.2 1.4.3 1.9l2.4 2.4c.5.5 1.3.6 1.9.3l2.6-1.4l1.5.6l.9 2.9c.2.6.8 1.1 1.5 1.1h3.4c.7 0 1.3-.5 1.5-1.1l.9-2.9l1.5-.6l2.6 1.4c.6.3 1.4.2 1.9-.3l2.4-2.4c.5-.5.6-1.3.3-1.9l-1.4-2.6l.6-1.5l2.9-.9c.6-.2 1.1-.8 1.1-1.5v-3.4c0-.7-.5-1.4-1.2-1.6z" fill="currentColor"/><circle class="clr-i-outline--badged clr-i-outline-path-3--badged clr-i-badge" cx="30" cy="6" r="5" fill="currentColor"/></svg>
                </button>

                <div className={`submenu ${submenuMedias ? 'active' : 'inactive'}`}>

                    <label onClick={() => messageStore.addConfirm('Você tem certeza que deseja encerrar o atendimento?', handleTerminateChat)}>
                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 36 36"><path class="clr-i-outline clr-i-outline-path-1" d="M18 2a16 16 0 1 0 16 16A16 16 0 0 0 18 2zM4 18a13.93 13.93 0 0 1 3.43-9.15l19.72 19.72A14 14 0 0 1 4 18zm24.57 9.15L8.85 7.43a14 14 0 0 1 19.72 19.72z" fill="currentColor"/></svg>
                        <span className="danger">Encerrar atendimento</span>
                    </label>

                    { chatbotAttendance === false && 
                        <>
                            <hr/>

                            <label>
                                <input onChange={(e) => handleOnUpload(e, 'image')} accept="image/*" type="file"/>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 20" width="16" height="20"><path fill="currentColor" d="M13.822 4.668H7.14l-1.068-1.09a1.068 1.068 0 0 0-.663-.278H3.531c-.214 0-.51.128-.656.285L1.276 5.296c-.146.157-.266.46-.266.675v1.06l-.001.003v6.983c0 .646.524 1.17 1.17 1.17h11.643a1.17 1.17 0 0 0 1.17-1.17v-8.18a1.17 1.17 0 0 0-1.17-1.169zm-5.982 8.63a3.395 3.395 0 1 1 0-6.79 3.395 3.395 0 0 1 0 6.79zm0-5.787a2.392 2.392 0 1 0 0 4.784 2.392 2.392 0 0 0 0-4.784z"></path></svg>
                                <span>Enviar imagem</span>
                            </label>

                            <label>
                                <input onChange={(e) => handleOnUpload(e, 'video')} accept="video/mp4,video/3gpp,video/quicktime" type="file"/>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 20" width="16" height="20"><path fill="currentColor" d="M15.243 5.868l-3.48 3.091v-2.27c0-.657-.532-1.189-1.189-1.189H1.945c-.657 0-1.189.532-1.189 1.189v7.138c0 .657.532 1.189 1.189 1.189h8.629c.657 0 1.189-.532 1.189-1.189v-2.299l3.48 3.09v-8.75z"></path></svg>
                                <span>Enviar vídeo</span>
                            </label>

                            <label>
                                <input onChange={(e) => handleOnUpload(e, 'file')} accept="*" type="file"/>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 20" width="13" height="20"><path fill="currentColor" d="M10.2 3H2.5C1.7 3 1 3.7 1 4.5v10.1c0 .7.7 1.4 1.5 1.4h7.7c.8 0 1.5-.7 1.5-1.5v-10C11.6 3.7 11 3 10.2 3zm-2.6 9.7H3.5v-1.3h4.1v1.3zM9.3 10H3.5V8.7h5.8V10zm0-2.7H3.5V6h5.8v1.3z"></path></svg>
                                <span>Enviar arquivo</span>
                            </label>
                        </>
                    }

                </div>

  
            </div>

        </form>
    )

}
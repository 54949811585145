import React from 'react'

//scss
import './DateDashMonth.scss'

const DateDashMonth = (props) => (

    <div 
        onClick={props.onClick} 
        
        className={`date-dash-month ${props.selected === true && 'active'} ${props.active === true && 'selected'} `}
    >
        <div className="day">
            <span>{props.day}</span>
        </div>
        <div className="month">
            {props.month}
        </div>
    </div>

)
export default DateDashMonth
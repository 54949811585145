import React from 'react'

import { Row, Col, Badge, Table } from 'react-bootstrap'

export default function SendList({data})
{

    return(
        <Row>
            <Col>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>
                                Canal
                            </th>
                            <th>
                                Em fila
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        { data.map((broker, brokerKey) =>
                            <tr key={brokerKey}>
                                <td>
                                    { broker.tipo }
                                </td>
                                <td>
                                    { broker.total }
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Col>
        </Row>
    )

}
import React from "react"
import nextId from "react-id-generator"
import { removeElements, addEdge } from 'react-flow-renderer'

class ModelChatbot {

    constructor(elements, setElements, setSelected, setSelectedUpdate) {

        this.elements = elements
        this.set = setElements
        this.setSelected = setSelected
        this.setSelectedUpdate = setSelectedUpdate
        this.selected = {
            id: '0'
        }

    }

    getSelectedDefault ()
    {

        return  {
            params: {
              nameDialog: 'Diálogo',
              conditions: [],
              messages: [],
              messageUser: {}
            }
          }

    }

    setElements( elements )
    {

        this.set(elements)

    }

    getElements()
    {
        
        return this.elements

    }

    ElementContext(children, id)
    {
        return(
            <div onClick={() => this.handleEditElement(id)} className="el-full">
                {children}
            </div>
        )
    }

    setElementsLoad( elements )
    {

        elements.map((row, key) => {

            if ( elements[key].params ) {

                elements[key].data.label = this.ElementContext( row.params.nameDialog, row.id )

            }

        })
        
        this.elements = elements
        this.set(elements)

    }

    setElementsDefault()
    {

        this.elements = [
            //entrada
            {
                id: '1',
                type: 'input',
                typeDialog: 'start',
                style: {
                    padding: 0,
                },
                data: { 
                    label: this.ElementContext('Início', 1),
                },
                position: { 
                    x: 210, 
                    y: 120
                },
                params: {
                    nameDialog: 'Início',
                    conditions: [
                        {
                            all: [
                                {
                                    if: 'respostaUsuario',
                                    condition: 'existe'
                                },
                            ],
                            target: 3,
                        }
                    ],
                    messages: [],
                    messageUser: {
                        required: false,
                        variable: null
                    },
                    exception: 2,
                }
            },
            {
                id: '2',
                style: {
                    padding: 0,
                },
                type: 'input',
                typeDialog: 'end',
                data: { label: this.ElementContext('Exceções', 2) },
                position: { 
                    x: 420, 
                    y: 120
                },
                params: {
                    nameDialog: 'Exceções',
                    conditions: [
                        {
                            all: [
                                {
                                    if: 'respostaUsuario',
                                    condition: 'existe'
                                }
                            ],
                            target: 4,
                        }
                    ],
                    messages: [],
                    messageUser: {
                        required: false,
                        variable: null
                    },
                    exception: 1,
                }
            },
            //boas vindas
            {
                id: '3',
                style: {
                    padding: 0,
                },
                data: { label: this.ElementContext('Boas vindas', 3) },
                position: { 
                    x: 210, 
                    y: 330
                },
                params: {
                    nameDialog: 'Boas vindas',
                    conditions: [
                        {
                            all: [
                                {
                                    if: 'respostaUsuario',
                                    condition: 'existe',
                                }
                            ],
                            target: 5,
                        }
                    ],
                    messages: [
                        {
                            message: 'Para iniciarmos o atendimento, poderia digitar seu nome?',
                            type: 'text'
                        }
                    ],
                    messageUser: {
                        required: true,
                        variable: 'nome'
                    },
                    exception: 2,
                }
            },
            {
                id: '4',
                style: {
                    padding: 0,
                },
                data: { label: this.ElementContext('Erro padrão', 4) },
                position: { 
                    x: 420, 
                    y: 210
                },
                params: {
                    nameDialog: 'Erro padrão',
                    conditions: [
                        {
                            all: [
                                {
                                    if: 'respostaUsuario',
                                    condition: 'existe'
                                }
                            ],
                            target: 3,
                        }
                    ],
                    messages: [
                        {
                            message: 'Desculpe, não consegui entender!',
                            type: 'text'
                        },
                    ],
                    messageUser: {
                        required: false,
                        variable: null
                    },
                    exception: 2,
                }
            },
            {
                id: '5',
                style: {
                    padding: 0,
                },
                type: 'output',
                typeDialog: 'attendance',
                data: { label: this.ElementContext('Atendimento Humano', 5) },
                position: { 
                    x: 420, 
                    y: 440
                },
                params: {
                    nameDialog: 'Atendimento Humano',
                    conditions: [],
                    messages: [
                        {
                            message: 'Aguarde um momento até que um de nossos operadores te atenda.',
                            type: 'text'
                        },
                    ],
                    messageUser: {
                        required: false,
                        variable: null
                    },
                    exception: 1,
                }
            },
            // ligacoes
            { id: 'e1-3', source: '1', target: '3', arrowHeadType: 'arrowclosed' },
            { id: 'e2-4', source: '2', target: '4', arrowHeadType: 'arrowclosed' },
            { id: 'e4-3', source: '4', target: '3', arrowHeadType: 'arrowclosed' },
            { id: 'e3-5', source: '3', target: '5', arrowHeadType: 'arrowclosed' },
        ]

        this.set(this.elements)

        return this.elements

    }

    handleEditElement( id )
    {


        if ( this.selected.id.toString() !== id.toString() ) this.setSelectedUpdate(new Date())
          
        this.selected = this.elements.filter(obj => obj.id.toString() === id.toString())[0]
        this.setSelected( this.selected )

    }

    onEditElement( selected )
    {

        this.elements.map((row, key) => {

            if ( row.id.toString() === selected.id.toString() ) {

                this.elements[key].id = selected.id
                this.elements[key].params = selected.params
                this.elements[key].data.label = this.ElementContext(selected.params.nameDialog, selected.id)

            }

        })

        this.setElements(this.elements)

    }

    handleOnRemove( elementsToRemove ){

        console.log(elementsToRemove)

        this.setElements((els) => removeElements(elementsToRemove, els))

    }

    onMove( element )
    {

        this.elements.map((row, key) => {


            if ( row.id === element.id ) {

                this.elements[key].position.x = element.position.x
                this.elements[key].position.y = element.position.y

            }

        })
        
        this.setElements(this.elements)

    }

    onConnect(params)
    {

        try {

            var elementFilter = this.elements.filter( obj => 
                obj.id === params.source    
            )[0]

            const conditionDefault = {
                all: [
                  {
                    if: 'repostaUsuario',
                    condition: 'existe',
                  }
                ],
                target: params.target
            }

            
            params.id = `e${params.source}-${params.target}`
            params.arrowHeadType = `arrowclosed`

            if ( this.elements.filter( obj => obj.id === params.id).length > 0 ) throw "Já esta conectado."

            elementFilter.params.conditions.push( conditionDefault )

            this.elements.push(params)
            this.setElements([...this.elements])

        } catch ( err ) {

            console.log( err )

        }

    }

    handleNewElement(x = 0, y = 0)
    {

        try {

            const data = new Date()

            const idElement = nextId()+'-'+data.getDay()+'-'+data.getSeconds()+'-'+data.getMilliseconds()+'-'+data.getMinutes()

            const xRandom = [45, 50, 55, 60, 65, 70, 75, 80, 85, 90]
            const yRandom = [255, 260, 265, 270, 275, 280, 290, 295, 300, 305]

            this.elements.push({
                id: idElement,
                style: {
                    padding: 0,
                },
                data: { label: this.ElementContext('Novo diálogo', idElement) },
                position: { 
                    x: x ? x : xRandom[Math.floor(Math.random() * 10)], 
                    y: y ? y :yRandom[Math.floor(Math.random() * 10)] 
                },
                params: {
                    nameDialog: 'Novo diálogo',
                    conditions: [],
                    messages: [],
                    messageUser: {
                        required: true,
                        variable: null
                    },
                    exception: 2,
                }
            })

            this.setElements([...this.elements])

        } catch ( err ) {

            console.log('err', err)

        }

    }

}

export default ModelChatbot
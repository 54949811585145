import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import InputDefault from '../../Components/Form/InputDefault'
import editMessages, { useContatoOptin } from '../../Firebase/editMessages'
import messageStore from '../../Store/MessageStore'

import "./ChatWeb.scss"

export default function WebChatLogin(props)
{

    const token = props.match.params.token
    const history = useHistory()
    const chatbot = props.location.search.split('?bot=').length > 1 ? props.location.search.split('?bot=')[1] : false
    const [user, setUser] = useState({
        nome: null,
        email: null,
        telefone: null,
        chatbot: chatbot ? chatbot : false
    })

    function validatePhone(phone) {

        phone = phone.replace(' ', '').replace(' ', '').replace(' ', '').replace('(', '').replace(')', '').replace('-', '')

        var regex = new RegExp('^((1[1-9])|([2-9][0-9]))((3[0-9]{3}[0-9]{4})|(9[0-9]{3}[0-9]{5}))$')
        return regex.test(phone)

    }

    async function setProfile(e)
    {
        try {

            e.preventDefault()

            if ( !user.nome ) throw "Nome é obrigatório"
            if ( !user.email ) throw "Preencha um e-mail válido."
            if ( !validatePhone(user.telefone) ) throw "Preencha um telefone válido"

            //seta um id
            const dateNow = new Date()
            user.id = user.email+'-'+dateNow.getFullYear()+dateNow.getMonth()+dateNow.getDay()+dateNow.getMinutes()+dateNow.getSeconds()+dateNow.getMilliseconds()

            window.localStorage.setItem('userchatprofile', JSON.stringify(user))
            editMessages.setContato(user, token)
            history.push('/webchat/auth/'+token+'?bot='+chatbot)

        } catch ( err ) {

            console.log(err)
            messageStore.addError( err )

        }
    }

    function handleOnChange(name, value)
    {
        user[name] = value
        setUser({...user})
    }

    return(
        <div id="chat-web-t2u">

            <form className="first-access" onSubmit={setProfile}>
                <div className="row-input">
                    <InputDefault title="Nome*" required={true} name="nome" value={user.nome} onChange={handleOnChange}/>
                    <InputDefault title="E-mail*" required={true} name="email" value={user.email} onChange={handleOnChange} type="email"/>
                    <InputDefault title="Telefone*" required={true} name="telefone" value={user.telefone} onChange={handleOnChange} mask={'(99) 99999-9999'}/>
                    <button className="button-zig full secondary">Iniciar um chat</button>
                </div>
            </form>

        </div>
    )

}
import React from 'react'
import InputMask from 'react-input-mask'

const InputDefault = (props) => (

    <label className="input-default-zig">
        <span>{props.title}</span>

        <InputMask 
            readOnly={props.readOnly} 
            type={props.type ? props.type : 'text'} 
            onChange={(e) => props.onChange(props.name, e.target.value)} 
            value={props.value} 
            placeholder={props.placeholder ? props.placeholder : props.title}
            mask={props.mask}
            maskChar={props.maskChar}
            required={props.required}
        />
        
    </label>

)

export default InputDefault
import firebase from "firebase"

// Add the Firebase products that you want to use
import "firebase/firestore"


firebase.initializeApp({
    apiKey: "AIzaSyADeu6SU0ykjWZevbFAxYnTm0dHcmogMGM",
    authDomain: "t2u-webchat2.firebaseapp.com",
    projectId: "t2u-webchat2",
    storageBucket: "t2u-webchat2.appspot.com",
    messagingSenderId: "557158704610",
    appId: "1:557158704610:web:95ab43ad537db6153ac5bb"
})

export { firebase }
export const db = firebase.firestore()
import React, { useEffect, useState } from 'react'
import { api } from '../../Api/app'
import messageStore from '../../Store/MessageStore'
import IconButton from '../Icon/IconButton'

import "./NewTemplateOficial.scss"

export default function NewTemplateOficial({getData})
{

    //nome//mensagem//imagem//variaveis

    const [variaveis, setVariaveis] = useState([])

    const [DTO, setDTO] = useState({
        nome: '',
        mensagem: '',
        imagem: '',
    })

    useEffect(() => {

        console.log(DTO)

    }, [DTO])

    useEffect(() => {

        console.log(variaveis)

    }, [variaveis])

    function handleNewVariavel()
    {
        
        variaveis.push({
            name: '',
            value: '',
        })

        setVariaveis([...variaveis])

    }

    async function handleSubmit(e)
    {

        try {

            e.preventDefault()

            DTO.variaveis = {}

            variaveis.map((row) => {
                DTO.variaveis[row.name] = row.value
            })

            const response = await api.post('templates-oficiais', DTO)

            if ( response.data.name === 'Error' ) throw response.data.message

            setDTO({
                nome: '',
                mensagem: '',
                imagem: '',
                variaveis: {}
            })

            setVariaveis([])
            
            messageStore.addSuccess('Template oficial adicionado com sucesso, agora é só aguardar a aprovação.')

            getData()

        } catch ( err ) {

            messageStore.addError(err)

        }

    }

    function handleOnChange(name, value)
    {

        DTO[name] = value
        setDTO({...DTO})

    }

    function handleOnChangeVariavel(name, key, value) 
    {

        variaveis[key][name] = value.toLowerCase()
        setVariaveis([...variaveis])

    }

    const [loadUpload, setLoadUpload] = useState(false)

    async function handleUpload(e)
    {

        try {

            setLoadUpload(true)

            var formData = new FormData()
                formData.append("file", e.target.files[0], e.target.files[0].name)

            let response = await api.post('templates/upload', formData)

            handleOnChange( 'imagem', response.data.link )

            setLoadUpload(false)
            
        } catch ( err ) {

            messageStore.addError('Não foi possivel fazer upload dessa imagem.')

        }

    }

    function handleRemoveVariable(name)
    {

        setVariaveis([...variaveis.filter(obj => obj.name !== name)])

    }

    return(
        <form id="newTemplateOficial" onSubmit={handleSubmit}>
            
            <p>Para o envio de mensagens oficiais no whatsapp, é necessário que você descreva a mensagem e as variaveis que serão usadas antes do envio. O nome é apenas para usar de referência</p>

            <input className="input-default" value={DTO.nome} placeholder="Nome" onChange={(e) => handleOnChange('nome', e.target.value)}/>
            <textarea className="input-default" value={DTO.mensagem} placeholder="Mensagem" onChange={(e) => handleOnChange('mensagem', e.target.value)}></textarea>
            
            <label className="input-default label-upload">
                <input accept="image/jpg, image/jpeg, image/png" id="upload-button" onChange={handleUpload} type="file" name="file"/>
                {loadUpload === true && <i className="fa fa-spin fa-spinner"></i>}
            </label>

            <fieldset>

                <legend>Variaveis de exemplo</legend>

                { variaveis.map((row, key) =>
                    <label>
                        <input className="input-default" placeholder="Nome da variavel" value={row.name} onChange={(e) => handleOnChangeVariavel('name', key, e.target.value)}/>
                        <input className="input-default" placeholder="Exemplo da variavel" value={row.value} onChange={(e) => handleOnChangeVariavel('value', key, e.target.value)}/>
                        <button type="button" onClick={() => handleRemoveVariable(row.name)}>
                            <IconButton icon={"del"}/>
                        </button>
                    </label>
                )}

                <button onClick={handleNewVariavel} type="button" className="button-zig secondary">
                    <span>Adicionar variavel</span>
                </button>

            </fieldset>

            <button className="button-zig primary">
                <span>Confirmar</span>
            </button>

        </form>
    )

}
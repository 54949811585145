import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, CartesianGrid, Tooltip } from 'recharts';
import HorizontalScroll from 'react-scroll-horizontal';
import Skeleton from 'react-loading-skeleton';
import DatePicker from 'react-datepicker';
import br from 'date-fns/locale/pt-BR';
import {
    getDates,
    getDatesPeriodo,
    formatarNumero,
    getDaysDates,
} from '../../Api/helper';
import { api } from '../../Api/app';

//scss
import './Dashboard.scss';
import 'react-datepicker/dist/react-datepicker.css';
//icos
import IconPhone from '../../Images/icos/icon_phone.png';
import IconRocket from '../../Images/icos/icon_rocket.png';
import IconDollar from '../../Images/icos/icon_dollar.png';
import IconStar from '../../Images/icos/icon_star.png';
//components
import ButtonDashIcon from '../../Components/ButtonDashIcon/ButtonDashIcon';
import ToggleSwitch from '../../Components/ToggleSwitch/ToggleSwitch';
import DateDashMonth from '../../Components/DateDashMonth/DateDashMonth';
import datesFormater from '../../Helper/DatesFormater';

export default function Dashboard(props) {
    //tamanho do grafico
    const [widthGraph, setWidthGraph] = useState(0);

    //loading
    const [loading, setLoading] = useState(true);
    const [loadingDia, setLoadingDia] = useState(true);

    //toggles de relatorios
    const [sms, setSms] = useState(1);
    const [email, setemail] = useState(1);
    const [whatsapp, setWhatsapp] = useState(1);
    const [voz, setVoz] = useState(1);

    //filtros e dias
    const [mesesFiltro, setMesesFiltro] = useState([]);
    const [dataInicial, setDataInicial] = useState('');
    const [dataFinal, setDataFinal] = useState('');
    const [dataHorario, setDataHorario] = useState('');

    //resultado da api
    const [data, setData] = useState([]);
    const [dataHoras, setDataHoras] = useState([]);

    //totais
    const [envios, setEnvios] = useState(0);

    const [enviosSms, setEnviosSms] = useState(0);
    const [enviosemail, setEnviosemail] = useState(0);
    const [enviosWhatsApp, setEnviosWhatsApp] = useState(0);
    const [enviosVoz, setEnviosVoz] = useState(0);

    const [enviosSmsDia, setEnviosSmsDia] = useState(0);
    const [enviosemailDia, setEnviosemailDia] = useState(0);
    const [enviosWhatsAppDia, setEnviosWhatsAppDia] = useState(0);
    const [enviosVozDia, setEnviosVozDia] = useState(0);

    const [ativas, setAtivas] = useState(0);
    const [creditosUsados, setCreditosUsados] = useState(0);
    const [visualizacoes, setVisualizacoes] = useState(0);

    useEffect(() => {
        setWidthGraph(document.getElementById('graph-width').offsetWidth);
        setDatesDefault();
    }, []);

    useEffect(() => {
        if (dataHorario) getDadosHoras();
    }, [dataHorario]);

    async function setDatesDefault() {
        //dias padrao
        var diasFiltro = 30;

        var dateInicio = getDatesPeriodo(diasFiltro);
        var dateFinal = getDatesPeriodo(0);

        var filtro = [];
        filtro['inicio'] = dateInicio;
        filtro['final'] = dateFinal;

        //setar datas
        setDates(filtro['inicio'], filtro['final']);
    }

    function handleFilter() {
        var dataInicio = new Date(dataInicial);
        if (!props.match.params.dataInicio)
            dataInicio.setDate(dataInicio.getDate() - 1);

        var dataFim = new Date(dataFinal);
        if (!props.match.params.dataFinal)
            dataFim.setDate(dataFim.getDate() - 1);

        setDataHorario('');
        setDates(dataInicio, dataFim);

        // history.push('/'+ getDateFormat(dataInicio)+'/'+getDateFormat(dataFim))
    }

    async function setDates(inicio, final) {
        var dateInicio = new Date(inicio);
        var dateFinal = new Date(final);

        //quantidade de dias entre as duas datas
        var dias = getDaysDates(dateFinal, dateInicio);
        //tras os dias
        var dates = getDates(dias, inicio, new Date());
        //seta os dias dos periodos
        setMesesFiltro(dates);

        //seta periodo inicial
        var dataInicio = new Date(inicio);
        dataInicio.setDate(dataInicio.getDate() + 1);
        setDataInicial(dataInicio);

        //seta periodo final
        var dataFinal = new Date(final);
        dataFinal.setDate(dataFinal.getDate() + 1);
        setDataFinal(dataFinal);
        //pegar dados de acordo com as datas
        getDados(inicio, final);
    }

    async function getDadosHoras() {
        setLoadingDia(true);

        //totais definidos
        var totalSms = 0;
        var totalemail = 0;
        var totalWhatsApp = 0;
        var totalVoz = 0;

        const dashboardEnvios = await api.get(
            'dashboards/envios-por-hora?data=' + dataHorario
        );

        const response = dashboardEnvios.data.results;

        setLoadingDia(false);

        response.map((row) => {
            totalSms = totalSms + row.sms;
            totalemail = totalemail + row.email;
            totalWhatsApp = totalWhatsApp + row.whatsapp;
            totalVoz = totalVoz + row.voz;

            return true;
        });

        setEnviosSmsDia(formatarNumero(totalSms));
        setEnviosemailDia(formatarNumero(totalemail));
        setEnviosWhatsAppDia(formatarNumero(totalWhatsApp));
        setEnviosVozDia(formatarNumero(totalVoz));

        window.scrollTo({
            top: 10000,
            behavior: 'smooth',
        });

        setDataHoras(response);
    }

    async function getDados(inicio, fim) {
        setLoading(true);

        //totais definidos
        var totalSms = 0;
        var totalemail = 0;
        var totalWhatsApp = 0;
        var totalVoz = 0;

        const dashboardEnvios = await api.get(
            'dashboards/envios-por-periodo?dataInicial=' +
                inicio +
                '&dataFinal=' +
                fim
        );

        const response = dashboardEnvios.data.results;

        setLoading(false);

        response.map((row) => {
            totalSms = totalSms + row.sms;
            totalemail = totalemail + row.email;
            totalWhatsApp = totalWhatsApp + row.whatsapp;
            totalVoz = totalVoz + row.voz;

            return true;
        });

        setAtivas(23);
        setEnvios(
            formatarNumero(totalSms + totalemail + totalWhatsApp + totalVoz)
        );
        setCreditosUsados(formatarNumero(66));
        setVisualizacoes(formatarNumero(6200));
        setEnviosSms(formatarNumero(totalSms));
        setEnviosemail(formatarNumero(totalemail));
        setEnviosWhatsApp(formatarNumero(totalWhatsApp));
        setEnviosVoz(formatarNumero(totalVoz));

        setData(response);
    }

    return (
        <div id="dashboard" className="page">
            <h1>Dashboard</h1>

            <div className="list-reports" style={{ display: 'none' }}>
                <ButtonDashIcon
                    to="/"
                    icon={IconPhone}
                    width="23.5%"
                    title="Ativas"
                    description={loading ? <Skeleton count={1} /> : ativas}
                />
                <ButtonDashIcon
                    to="/"
                    icon={IconRocket}
                    width="23.5%"
                    title="Enviadas"
                    description={loading ? <Skeleton count={1} /> : envios}
                />
                <ButtonDashIcon
                    to="/"
                    icon={IconDollar}
                    width="23.5%"
                    title="Créditos Usados"
                    description={
                        loading ? <Skeleton count={1} /> : creditosUsados
                    }
                />
                <ButtonDashIcon
                    to="/"
                    icon={IconStar}
                    width="23.5%"
                    title="Visualizações"
                    description={
                        loading ? <Skeleton count={1} /> : visualizacoes
                    }
                />
            </div>

            <div className="reports-graph">
                <div className="filter">
                    <div className="date">
                        <p>Filtrar por período</p>

                        <div>
                            <label>
                                <DatePicker
                                    selected={dataInicial}
                                    onChange={(date) => setDataInicial(date)}
                                    startDate={dataInicial}
                                    endDate={dataFinal}
                                    locale={br}
                                    dateFormat="dd/MM/Y"
                                />
                                <i></i>
                            </label>

                            <label>
                                <DatePicker
                                    selected={dataFinal}
                                    onChange={(date) => setDataFinal(date)}
                                    selectsEnd
                                    startDate={dataInicial}
                                    endDate={dataFinal}
                                    minDate={dataInicial}
                                    locale={br}
                                    dateFormat="dd/MM/Y"
                                />
                                <i></i>
                            </label>
                            <button onClick={handleFilter}>Filtrar</button>
                        </div>
                    </div>

                    <div className="toggle">
                        <div>
                            <span>SMS</span>
                            <ToggleSwitch value={sms} setValue={setSms} />
                        </div>

                        <div>
                            <span>Email</span>
                            <ToggleSwitch value={email} setValue={setemail} />
                        </div>

                        <div>
                            <span>WhatsApp</span>
                            <ToggleSwitch
                                value={whatsapp}
                                setValue={setWhatsapp}
                            />
                        </div>

                        <div>
                            <span>Voz</span>
                            <ToggleSwitch value={voz} setValue={setVoz} />
                        </div>
                    </div>
                </div>

                <div className="dates-dash-month">
                    <HorizontalScroll
                        pageLock={false}
                        reverseScroll={true}
                        className={
                            loading
                                ? 'scrolling-dates transition'
                                : 'scrolling-dates'
                        }
                        config={{
                            damping: 10,
                            stiffness: 100,
                        }}
                    >
                        {mesesFiltro.map((row, key) => (
                            <DateDashMonth
                                selected={
                                    dataHorario === row.date ? true : false
                                }
                                key={key}
                                onClick={() =>
                                    dataHorario === row.date
                                        ? setDataHorario('')
                                        : setDataHorario(row.date)
                                }
                                active={row.active}
                                day={row.dia}
                                month={row.mesNome}
                            />
                        ))}
                    </HorizontalScroll>
                </div>

                {!dataHorario && (
                    <>
                        <h3>
                            Envios por períodos{' '}
                            <span>
                                {datesFormater.dateBrReplace(dataInicial)} -{' '}
                                {datesFormater.dateBrReplace(dataFinal)}
                            </span>
                        </h3>

                        <div className="graph-content">
                            <div className="description">
                                {sms === 1 && (
                                    <div
                                        style={{
                                            borderLeft: '2px solid #f9cb46',
                                        }}
                                    >
                                        <b>
                                            {loading ? (
                                                <Skeleton count={1} />
                                            ) : (
                                                enviosSms
                                            )}
                                        </b>
                                        <span>SMS</span>
                                    </div>
                                )}

                                {email === 1 && (
                                    <div
                                        style={{
                                            borderLeft: '2px solid #1ed7fb',
                                        }}
                                    >
                                        <b>
                                            {loading ? (
                                                <Skeleton count={1} />
                                            ) : (
                                                enviosemail
                                            )}
                                        </b>
                                        <span>Email</span>
                                    </div>
                                )}

                                {whatsapp === 1 && (
                                    <div
                                        style={{
                                            borderLeft: '2px solid #499dff',
                                        }}
                                    >
                                        <b>
                                            {loading ? (
                                                <Skeleton count={1} />
                                            ) : (
                                                enviosWhatsApp
                                            )}
                                        </b>
                                        <span>WhatsApp</span>
                                    </div>
                                )}

                                {voz === 1 && (
                                    <div
                                        style={{
                                            borderLeft: '2px solid #499dff',
                                        }}
                                    >
                                        <b>
                                            {loading ? (
                                                <Skeleton count={1} />
                                            ) : (
                                                enviosVoz
                                            )}
                                        </b>
                                        <span>Voz</span>
                                    </div>
                                )}
                            </div>

                            <div className="graph-load" id="graph-width">
                                {loading ? (
                                    <Skeleton height={240} count={1} />
                                ) : (
                                    <BarChart
                                        barSize={10}
                                        width={widthGraph}
                                        height={261}
                                        data={data}
                                    >
                                        <CartesianGrid strokeDasharray="1 1" />
                                        <Tooltip />
                                        <XAxis dataKey="name" />

                                        {sms === 1 && (
                                            <Bar dataKey="sms" fill="#f9cb46" />
                                        )}
                                        {email === 1 && (
                                            <Bar
                                                dataKey="email"
                                                fill="#1ed7fb"
                                            />
                                        )}
                                        {whatsapp === 1 && (
                                            <Bar
                                                dataKey="whatsapp"
                                                fill="#499dff"
                                            />
                                        )}
                                        {voz === 1 && (
                                            <Bar dataKey="voz" fill="#1efb95" />
                                        )}
                                    </BarChart>
                                )}
                            </div>
                        </div>
                    </>
                )}

                {dataHorario && (
                    <>
                        <h3>
                            Envios por horario{' '}
                            <span>
                                {datesFormater.dateBrReplace(dataHorario)}
                            </span>
                        </h3>

                        <div className="graph-content">
                            <div className="description">
                                {sms === 1 && (
                                    <div
                                        style={{
                                            borderLeft: '2px solid #f9cb46',
                                        }}
                                    >
                                        <b>
                                            {loading ? (
                                                <Skeleton count={1} />
                                            ) : (
                                                enviosSmsDia
                                            )}
                                        </b>
                                        <span>SMS</span>
                                    </div>
                                )}

                                {email === 1 && (
                                    <div
                                        style={{
                                            borderLeft: '2px solid #1ed7fb',
                                        }}
                                    >
                                        <b>
                                            {loading ? (
                                                <Skeleton count={1} />
                                            ) : (
                                                enviosemailDia
                                            )}
                                        </b>
                                        <span>email</span>
                                    </div>
                                )}

                                {whatsapp === 1 && (
                                    <div
                                        style={{
                                            borderLeft: '2px solid #499dff',
                                        }}
                                    >
                                        <b>
                                            {loading ? (
                                                <Skeleton count={1} />
                                            ) : (
                                                enviosWhatsAppDia
                                            )}
                                        </b>
                                        <span>WhatsApp</span>
                                    </div>
                                )}

                                {voz === 1 && (
                                    <div
                                        style={{
                                            borderLeft: '2px solid #499dff',
                                        }}
                                    >
                                        <b>
                                            {loading ? (
                                                <Skeleton count={1} />
                                            ) : (
                                                enviosVozDia
                                            )}
                                        </b>
                                        <span>WhatsApp</span>
                                    </div>
                                )}
                            </div>

                            <div className="graph-load" id="graph-width">
                                {loadingDia ? (
                                    <Skeleton height={240} count={1} />
                                ) : (
                                    <BarChart
                                        barSize={10}
                                        width={widthGraph}
                                        height={261}
                                        data={dataHoras}
                                    >
                                        <CartesianGrid strokeDasharray="1 1" />
                                        <Tooltip />
                                        <XAxis dataKey="name" />

                                        {sms === 1 && (
                                            <Bar dataKey="sms" fill="#f9cb46" />
                                        )}
                                        {email === 1 && (
                                            <Bar
                                                dataKey="email"
                                                fill="#1ed7fb"
                                            />
                                        )}
                                        {whatsapp === 1 && (
                                            <Bar
                                                dataKey="whatsapp"
                                                fill="#499dff"
                                            />
                                        )}
                                        {voz === 1 && (
                                            <Bar dataKey="voz" fill="#1efb95" />
                                        )}
                                    </BarChart>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </div>

            <div className="video-phone"></div>
        </div>
    );
}

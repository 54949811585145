import React, {useEffect, useState} from "react";
import ReactDOM from "react-dom";
import h337 from "heatmap.js";
import WidgetRender from "../../Components/TemplateCreation/WidgetRender/WidgetRender";
import { api } from "../../Api/app";


export default function DetalheHeatMap(props) {

  const [loading, setLoading] = useState(false)

    const [template, setTemplate] = useState([])

    const id = props.match.params.id

    const shorturl = props.match.params.shorturl

    useEffect(() => {

        getData()

    }, [props])

    async function getData()
    {

        var response = await api.get(id ? 'templates/'+id : 'queues/template/'+shorturl)

        if ( response.data.estrutura != '[{}]' ) {
            setTemplate( ...[JSON.parse(response.data.estrutura)] )
        }
        
  }

  useEffect(() => {

    var heatmapInstance = h337.create({
      container: document.querySelector('.App')
    })

    var data = {
        max: 99,
        data: window.localStorage.getItem('dataClick') ? JSON.parse(window.localStorage.getItem('dataClick')) : []
    }

    heatmapInstance.setData(data)

  })
  
  return (
    <div id="template-detalhe" className="page">

            <div className="container App">
                {loading === false && template.map((item, key) => (
                    <WidgetRender
                        updateWidgetColumn={() => {}}
                        id={item.id}
                        item={item.template}
                        indice={key}
                        editId={() => {}}
                        setLoading={setLoading}
                        template={template}
                        editWidget={() => {}}
                        removeWidget={() => {}}
                        key={key}
                        edit={false}
                    />
                ))}
            </div>

        </div>
  )

}


import React, { createRef, useEffect, useState } from 'react'
import { Editor } from '@tinymce/tinymce-react'; 

import './Templates.scss'
import './CreationEmail.scss'
import KeyboardLaptop from '../../Images/notebook_frame_keyboard.png'
import HorizontalScroll from 'react-scroll-horizontal'
import { api, baseUrl } from '../../Api/app';
import Axios from 'axios';

export default function CreationEmail(props) {

    const [emails, setemails] = useState([])

    const [emailContent, setEmailContent] = useState('')

    const [templates, setTemplates] = useState([])

    function handleEditorChange(content){
        
        setEmailContent(content)
        props.setMessage(content)

    }

    const [heightPreview, setHeightPreview] = useState(0)

    const [preview, setPreview] = useState('')

    const [modalPreview, setModalPreview] = useState(false)
    
    function formatText(tag)
    {

        var val = emailContent + '[' + tag + ']'

        setEmailContent(val)
        props.setMessage(val)

    }

    useEffect(() => {

        setHeightPreview(document.getElementById('laptop-frame').offsetHeight)
        getTemplates()

    }, [])

    async function getTemplates()
    {

        let templatesLista = [
            {
                nome: 'Academia',
                path: 'academia1',
            },
            {
                nome: 'Aniversário de cachorro',
                path: 'aniversario-cachorrinho',
            },
            {
                nome: 'Aniversário',
                path: 'aniversario-fundo-escuro',
            },
            {
                nome: 'Ano novo',
                path: 'ano-novo-2',
            },
            {
                nome: 'Black Friday 1',
                path: 'Blackfriday1',
            },
            {
                nome: 'Black Friday 2',
                path: 'Blackfriday2',
            },
            // {
            //     nome: 'Dia das mães 01',
            //     path: 'diadasmaes1',
            // },
            // {
            //     nome: 'Dia das mães 02',
            //     path: 'diadasmaes2',
            // },
            // {
            //     nome: 'Dia das mães 03',
            //     path: 'diadasmaes3',
            // },
            {
                nome: 'Dia dos pais',
                path: 'dia-pais-produtos',
            },
            {
                nome: 'Dia dos pais 01',
                path: 'dia-pais-coluna',
            },
            {
                nome: 'Dia dos pais',
                path: 'dia-pais-produtos',
            },
            {
                nome: 'Dia das crianças 1',
                path: 'diadascriancas1',
            },
            {
                nome: 'Dia das crianças 2',
                path: 'diasdascriancas2',
            },
            {
                nome: 'Evento',
                path: 'evento-4',
            },
            {
                nome: 'Férias 1',
                path: 'ferias1',
            },
            {
                nome: 'Férias 2',
                path: 'ferias2',
            },
            {
                nome: 'Natal 1',
                path: 'natal1',
            },
            {
                nome: 'Natal 2',
                path: 'natal9',
            },
            {
                nome: 'Notícias 1',
                path: 'noticias-1',
            },
            {
                nome: 'Notícias 2',
                path: 'noticias-2',
            },
            {
                nome: 'Notícias 3',
                path: 'noticias-5',
            },
            {
                nome: 'Produtos 1',
                path: 'produtos-3',
            },
            {
                nome: 'Produtos 2',
                path: 'produtos-4',
            }
            
        ]

        const loop = async (row) => {

            //console.log( baseUrl+'files/templates-emails/'+row.path )

            let response = await getHtmlTemplate(baseUrl+'files/templates-emails/'+row.path)

            var data = {
                nome: row.nome,
                html: response,
            }

            return Promise.resolve(data)

        }

        let responses = await Promise.all(
            templatesLista.map((row) => loop(row))
        )

        console.log( 'AQUIIIIIIIIII', responses )

        setTemplates([...responses])

    }

    async function getHtmlTemplate(url)
    {


        const HTMLTemplate = await Axios(url)

        let response = HTMLTemplate.data

        response = JSON.stringify(response)
        response = response.split('img/').join(url+'/img/')
        response = response.split('images/').join(url+'/images/')
        response = response.split('./index_files/').join(url+'/index_files/')
        response = response.split('iagente.com.br').join('seusite.com.br')
        response = response.split('iagente').join('Seu site')
        response = response.split('data-mce-src').join('olddata-mce-src')

        return response

    }

    return(

        <div id="template-email" className="separator" style={{flexDirection: 'column'}}>

            <div className="translate" style={{transform: `translateX(${modalPreview ? '-50%' : '0%'})`}}>

                <div className="laptop-frame" id="laptop-frame">

                    <p className="title-shortcode">Utilize alguns de nossos templates como exemplo.</p>

                    <HorizontalScroll
                        pageLock={false}
                        reverseScroll={true}
                        className={'templates-html'}
                        config={{
                            damping:10,
                            stiffness:100,
                        }}
                    >
                        <ul className="templates-html">
                            {templates.map((row, key) => 
                                <li key={key} onClick={() => {setEmailContent(JSON.parse(row.html)); props.setMessage(JSON.parse(row.html))}}>
                                    { row.nome }
                                </li>
                            )}
                        </ul>
                    </HorizontalScroll>

                    <p className="title-shortcode">Utilize os [shortcode] para personalizar suas mensagens.</p>

                    { props.shortcode.length > 0 && 
                        <div className="shortcode">
                            {props.shortcode.map((row, key) => 
                            <span onClick={() => formatText(row)} key={key}>{row}</span>
                            )}
                        </div>
                    }

                    <div className={`display`}>

                        <div>
                            <Editor
                                apiKey="jhxw2xtreuc28h52dqkb1nzaqho0thryobh1otuo6rrm474s"
                                init={
                                {
                                    language: 'pt_BR',
                                    height: 500,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image', 
                                        'charmap print preview anchor',
                                        'searchreplace visualblocks code',
                                        'insertdatetime media table paste wordcount',
                                        'inserttable inserttabledialog tableprops deletetable cell',
                                        'image',
                                        'code'
                                    ],
                                    toolbar: 'undo redo | formatselect fontsizes codeformat fontsizeselect fontselect | bold italic backcolor forecolor | \ alignleft aligncenter alignright | \ link | table | image | code | bullist numlist outdent indent '
                                }}
                                onEditorChange={handleEditorChange}
                                value={emailContent}
                            />
                        </div>

                    </div>

                    <div className="keyboard">
                        <img src={KeyboardLaptop}/>
                    </div>

                </div>

                <div className="preview-box" style={{height: heightPreview}}>
            
                    <div className="back">
                        <button onClick={() => setModalPreview(false)} type="button" className="button-zig column neutral">
                        <i class="fa fa-angle-left" aria-hidden="true"></i> Voltar a edição
                        </button>
                    </div>

                    <div className="separate-back">
                        <div className={`modal ${preview}`}>
                            
                            <div className="navegador">

                                <div className="controls">
                                    <span style={{backgroundColor: '#FE5650'}}></span>
                                    <span style={{backgroundColor: '#FDB43C'}}></span>
                                    <span style={{backgroundColor: '#29C045'}}></span>
                                </div>

                                <div className="arrows">
                                    <i class="fa fa-angle-left" aria-hidden="true"></i>
                                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                                </div>

                                <div className="search">
                                    <div className="input-text">
                                        <i className="fa fa-search"></i>
                                        <span>meu-email.com</span>
                                    </div>
                                </div>

                            </div>

                            <div className="display" dangerouslySetInnerHTML={{__html: emailContent}}/>

                        </div>
                    </div>

                </div>

            </div>

            <div style={{display: 'flex'}}>

                <div className="buttons">

                    { props.step !== 5 && <button>Próximo passo</button>}
                    { props.step !== 1 && <button onClick={() => props.setStep( props.prev )} type="button">Passo anterior</button>}
                    
                </div>

                <div className="message-campgain send-test preview" style={{flex: 0}}>

                    <label>
                        <b style={{whiteSpace: 'nowrap'}}>Pré-visualizar template</b>
                    </label>

                    <div>
                        
                        <button onClick={() => {setPreview('phone'); setModalPreview(true)}} type="button" className="button-zig neutral">
                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><g fill="#626262"><path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z"/><path d="M8 14a1 1 0 1 0 0-2a1 1 0 0 0 0 2z"/></g><rect x="0" y="0" width="16" height="16" fill="rgba(0, 0, 0, 0)" /></svg>
                        </button>

                        <button onClick={() => {setPreview('laptop'); setModalPreview(true)}} type="button" className="button-zig neutral">
                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512"><path fill="#626262" d="M440 368a24.028 24.028 0 0 0 24-24V104a24.028 24.028 0 0 0-24-24H72a24.028 24.028 0 0 0-24 24v240a24.028 24.028 0 0 0 24 24zM80 112h352v224H80z"/><path fill="#626262" d="M16 400h480v32H16z"/><rect x="0" y="0" width="512" height="512" fill="rgba(0, 0, 0, 0)" /></svg>
                        </button>
                        
                    </div>

                </div>

                <div className="message-campgain send-test" style={{flex: 1}}>
                    <label>

                        <b>Teste o  envio da campanha</b>
                        <span>Para enviar mais de um email, separe por viruglas. <br/>Exemplo:joao@hotmail.com,maria@gmail.com</span>

                        <div>
                            <input onChange={(e) => props.setPhones(e.target.value.split(','))} type="text" className="input-default" placeholder="Emails separados por virgula"/>
                            <button type="button" onClick={props.sendAvulsos}>
                                {props.load === true ? <i className="fa fa-spin fa-spinner"></i> : 'Enviar mensagem teste'}
                            </button>
                        </div>

                        <div className="phones">
                            { props.phones.map((row, key) => 
                                <span key={key} className={`phone-number ${row.split('@').length > 1 && row.split('.').length > 1 ? 'valid' : 'invalid'}`}>
                                    {row.split('@').length > 1 && row.split('.').length > 1 ? <i className="fa fa-check"/> : <i className="fa fa-times"/>} {row}
                                </span>
                            )}
                        </div>

                    </label>
                </div>

            </div>

        </div>

    )

}
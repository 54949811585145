import React, { useEffect, useState } from 'react';
import { api } from '../../Api/app';
import { useGlobal } from '../../contexts/GlobalContext';
import CreationEmail from '../../Pages/Templates/CreationEmail';
import messageStore from '../../Store/MessageStore';

const Messages = (props) => {
    const [load, setLoad] = useState(false);

    const [message, setMessage] = useState('');

    const [upload, setUpload] = useState(props.fields.imagem);

    const [phone, setPhone] = useState('');

    const [phones, setPhones] = useState([]);

    const [totalCaracteres, setTotalCaracteres] = useState(0);

    const [shortcode, setShortcode] = useState([]);

    useEffect(() => {
        props.handleOnChange('mensagem', message);
        props.handleOnChange('imagem', upload);
    }, [message, upload]);

    const [urlTemplate, setUrlTemplate] = useState('');

    useEffect(() => {
        const urlPreDef = props.fields.id_template
            ? 'https://t-2u.com/t/' + props.fields.id_template
            : '';

        setShortcode(props.fields.colunas);
        setMessage(props.fields.mensagem);
        setUrlTemplate(urlPreDef);
        getTemplatesOficiais();

        if (props.send === 'Whatsapp') {
            setTotalCaracteres(1500);
        } else if (props.send === 'SMS') {
            setTotalCaracteres(154 - urlPreDef.length);
        } else if (props.send === 'FlashSMS') {
            setTotalCaracteres(200 - urlPreDef.length);
        }
    }, [props]);

    async function handleOnSave(e) {
        try {
            e.preventDefault();
            if (!templateOficial && message.length === 0)
                throw 'Preencha uma mensagem para campanha.';
            props.setStep(props.next);
        } catch (e) {
            messageStore.addError(e);
        }
    }

    async function sendAvulsos() {
        try {
            if (phones.length === 0) throw 'Nenhum destinatário encontrado.';

            if (!messageTemplateOficial && !message)
                throw 'Digite uma mensagem antes de enviar.';

            setLoad(true);

            const imageWhatsapp = props.fields.imagem
                ? props.fields.imagem.split('/files/templates/')[1]
                : null;

            if (messageTemplateOficial) {
                let response = await api.post(
                    'send/' + props.send.toLowerCase(),
                    {
                        mensagem: messageTemplateOficial,
                        destinatarios: phones,
                        imagem: imageWhatsapp,
                        templateId: props.fields.id_template,
                        chatbotId: props.fields.id_chatbot,
                        frontend:
                            window.location.protocol +
                            '//' +
                            window.location.host +
                            '/t/',
                    }
                );
            } else {
                let response = await api.post(
                    'send/' + props.send.toLowerCase(),
                    {
                        mensagem:
                            props.send === 'Email'
                                ? message
                                      .split('data-mce-src')
                                      .join('olddata-mce-src')
                                : message,
                        destinatarios: phones,
                        imagem: imageWhatsapp,
                        templateId: props.fields.id_template,
                        chatbotId: props.fields.id_chatbot,
                        frontend:
                            window.location.protocol +
                            '//' +
                            window.location.host +
                            '/t/',
                    }
                );
            }

            messageStore.addSuccess('Enviado com sucesso.');

            setLoad(false);
        } catch (e) {
            setLoad(false);
            messageStore.addError(e);
        }
    }

    async function handleUpload(e) {
        var formData = new FormData();
        formData.append('file', e.target.files[0], e.target.files[0].name);

        let response = await api.post('templates/upload', formData);

        setUpload(response.data.link);
        //console.log( response.data )
    }

    function formatText(tag) {
        var Field = document.getElementById('textMessage');
        var val = Field.value;
        var selected_txt = val.substring(
            Field.selectionStart,
            Field.selectionEnd
        );
        var before_txt = val.substring(0, Field.selectionStart);
        var after_txt = val.substring(Field.selectionEnd, val.length);
        Field.value += '[' + tag + ']';
        document.getElementById('textMessage').focus();

        setMessage(message + '[' + tag + ']');
    }

    function handlePhones(e) {
        const value = e.target.value
            .replace(' ', '')
            .replace(' ', '')
            .replace(' ', '')
            .replace('(', '')
            .replace(')', '')
            .replace('-', '');

        setPhone(value);
        setPhones(value.split(','));
    }

    function validatePhone(phone) {
        phone = phone
            .replace(' ', '')
            .replace(' ', '')
            .replace(' ', '')
            .replace('(', '')
            .replace(')', '')
            .replace('-', '');

        var regex = new RegExp(
            '^((1[1-9])|([2-9][0-9]))((3[0-9]{3}[0-9]{4})|(9[0-9]{3}[0-9]{5}))$'
        );
        return regex.test(phone);
    }

    const [templatesOficiais, setTemplatesOficiais] = useState([]);
    const [templateOficial, setTemplateOficial] = useState(null);
    const [messageTemplateOficial, setMessageTemplateOficial] = useState('');

    async function getTemplatesOficiais() {
        const response = await api.get('templates-oficiais');
        setTemplatesOficiais([...response.data]);
    }

    function handleChangeTemplateOficial(id) {
        if (id) {
            const messageOficial = templatesOficiais
                .filter((obj) => obj.id === id)[0]
                .text.replaceAll('{{', '[')
                .replaceAll('}}', ']');

            setTemplateOficial(id);
            setMessageTemplateOficial(messageOficial);
            props.handleOnChange('mensagem', messageOficial);
            props.handleOnChange('id_template_oficial', id);
        } else {
            props.handleOnChange('mensagem', message);
            props.handleOnChange('id_template_oficial', null);
            setTemplateOficial(null);
            setMessageTemplateOficial(null);
        }
    }

    useEffect(() => {
        var validation = [];

        console.log(validation);
    }, [messageTemplateOficial]);

    const { user } = useGlobal();

    return (
        <form
            className="step"
            onSubmit={handleOnSave}
            style={{ display: props.step === props.num ? 'block' : 'none' }}
        >
            {props.send !== 'Email' ? (
                <>
                    <h2>{props.nome}</h2>
                    <p>
                        Configure e escreva a mensagem que será enviada via{' '}
                        {props.send}.
                    </p>

                    <div className="separator">
                        <div className="input message-campgain">
                            <div className={'phone'}>
                                <div className={'display ' + props.send}>
                                    <div className="message">
                                        <div className="sender">text2you</div>

                                        {!messageTemplateOficial &&
                                            props.send == 'Whatsapp' &&
                                            upload && (
                                                <img
                                                    className="image-upload"
                                                    src={upload}
                                                />
                                            )}

                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: messageTemplateOficial
                                                    ? messageTemplateOficial
                                                    : message.substr(
                                                          0,
                                                          totalCaracteres
                                                      ),
                                            }}
                                        />

                                        {props.fields.id_template && (
                                            <span>{urlTemplate}</span>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="buttons">
                                <button>Próximo passo</button>
                                {props.step !== 1 && (
                                    <button
                                        onClick={() =>
                                            props.setStep(props.prev)
                                        }
                                        type="button"
                                    >
                                        Passo anterior
                                    </button>
                                )}
                            </div>
                        </div>

                        <div className="list message-campgain">
                            {props.send === 'Whatsapp' && (
                                <>
                                    {!messageTemplateOficial && (
                                        <div className="upload-image">
                                            <label>
                                                <p>
                                                    Mensagem com imagem (formato
                                                    JPG ou PNG)
                                                </p>
                                                <input
                                                    accept="image/jpg, image/jpeg, image/png"
                                                    id="upload-button"
                                                    onChange={handleUpload}
                                                    type="file"
                                                    name="file"
                                                />
                                            </label>

                                            {upload && (
                                                <button
                                                    className="remove-upload"
                                                    onClick={() =>
                                                        setUpload(null)
                                                    }
                                                >
                                                    Remover imagem
                                                </button>
                                            )}
                                        </div>
                                    )}

                                    {user.account.brokers.filter(
                                        (obj) =>
                                            obj.tipo === 'Whatsapp' &&
                                            obj.oficial === true
                                    ).length > 0 && (
                                        <div className="upload-image">
                                            <label>
                                                <p>
                                                    Para enviar whatsapp
                                                    oficial, é necessário ter um
                                                    template de mensagem oficial
                                                    aprovado.
                                                </p>
                                                <select
                                                    style={{ width: 300 }}
                                                    className="input-default"
                                                    onChange={(e) =>
                                                        handleChangeTemplateOficial(
                                                            e.target.value
                                                        )
                                                    }
                                                >
                                                    <option value="">
                                                        Nenhum template oficial
                                                    </option>
                                                    {templatesOficiais
                                                        .filter(
                                                            (obj) =>
                                                                obj.status ===
                                                                'APPROVED'
                                                        )
                                                        .map((row, key) => (
                                                            <option
                                                                key={key}
                                                                value={row.id}
                                                            >
                                                                {row.name}
                                                            </option>
                                                        ))}
                                                </select>
                                            </label>
                                        </div>
                                    )}
                                </>
                            )}

                            <p>
                                Utilize os [shortcode] para personalizar suas
                                mensagens.
                            </p>

                            {shortcode.length > 0 && (
                                <div className="shortcode">
                                    {shortcode.map((row, key) => (
                                        <>
                                            {messageTemplateOficial ? (
                                                <span
                                                    className={
                                                        messageTemplateOficial.split(
                                                            `[${row}]`
                                                        ).length === 1
                                                            ? 'invalid'
                                                            : 'valid'
                                                    }
                                                    key={key}
                                                >
                                                    {row}
                                                </span>
                                            ) : (
                                                <span
                                                    className={
                                                        message.split(
                                                            `[${row}]`
                                                        ).length === 1
                                                            ? 'invalid'
                                                            : 'valid'
                                                    }
                                                    onClick={() =>
                                                        formatText(row)
                                                    }
                                                    key={key}
                                                >
                                                    {row}
                                                </span>
                                            )}
                                        </>
                                    ))}
                                </div>
                            )}

                            <label>
                                <span>
                                    Para envios de link, colocar https:// ou
                                    http://
                                    <br />
                                    Exemplo: https://www.google.com
                                </span>

                                <textarea
                                    disabled={
                                        messageTemplateOficial ? true : false
                                    }
                                    id="textMessage"
                                    value={
                                        messageTemplateOficial
                                            ? messageTemplateOficial
                                            : message.substr(0, totalCaracteres)
                                    }
                                    placeholder="Mensagem da campanha"
                                    className="input-default"
                                    maxLength={totalCaracteres}
                                    onChange={(e) => setMessage(e.target.value)}
                                ></textarea>
                                <span>
                                    {totalCaracteres - message.length < 0
                                        ? 0
                                        : totalCaracteres - message.length}{' '}
                                    caracteres restantes
                                </span>
                            </label>

                            {props.fields.id_template && (
                                <label>
                                    <b>Link automático da campanha</b>
                                    <span>{urlTemplate}</span>
                                </label>
                            )}

                            <label>
                                <b>Teste o envio da campanha</b>
                                <span>Exemplo: 1190909090,1191919191</span>

                                <div>
                                    <input
                                        onChange={(e) => handlePhones(e)}
                                        type="text"
                                        value={phone}
                                        className="input-default"
                                        placeholder="Celulares separados por virgula"
                                    />
                                    <button type="button" onClick={sendAvulsos}>
                                        {load === true ? (
                                            <i className="fa fa-spin fa-spinner"></i>
                                        ) : (
                                            'Enviar mensagem teste'
                                        )}
                                    </button>
                                </div>

                                <div className="phones">
                                    {phones.map((row, key) => (
                                        <span
                                            className={`phone-number ${
                                                validatePhone(row)
                                                    ? 'valid'
                                                    : 'invalid'
                                            }`}
                                        >
                                            {validatePhone(row) ? (
                                                <i className="fa fa-check" />
                                            ) : (
                                                <i className="fa fa-times" />
                                            )}
                                            {row}
                                        </span>
                                    ))}
                                </div>
                            </label>
                        </div>
                    </div>
                </>
            ) : (
                <CreationEmail
                    prev={props.prev}
                    next={props.next}
                    message={message}
                    setMessage={setMessage}
                    setStep={props.setStep}
                    sendAvulsos={sendAvulsos}
                    phones={phones}
                    setPhones={setPhones}
                    load={load}
                    shortcode={shortcode}
                    formatText={formatText}
                />
            )}
        </form>
    );
};

export default Messages;

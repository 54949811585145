import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { api } from '../../Api/app'

//scss
import './Login.scss'
//images
import Logo from '../../Images/logo_color.png'
import messageStore from '../../Store/MessageStore'
import Axios from 'axios'

export default function Login(props) {
    
    const history = useHistory()

    const [login, setLogin] = useState(process.env.REACT_APP_STAGE === 'development' ? 'kaique@tropa.digital' : '')
    const [password, setPassword] = useState(process.env.REACT_APP_STAGE === 'development' ? 'mudar123' : '')

    useEffect(() => {

    }, [props]);

    async function handleLogin(e){

        e.preventDefault()

        try {

            var response = await api.post('session/login', { login, password })
        
            if ( response.data.error === true ){

                throw response.data.message

            }

            window.localStorage.setItem('token', response.data.token)
            window.location.href="/"

        } catch ( e ) {

            messageStore.addError('Erro ao realizar login.')

        }

    }

    return(

        <div id="login">

            <div className="form animate__animated">

                <div className="left animate__animated">

                    <div className="top">
                        <span style={{fontSize: 40}}>text2you</span>
                        <p>Faça login para acessar a plataforma</p>
                    </div>

                    <form onSubmit={handleLogin}>

                        <label>
                            <input autoFocus={'true'} defaultValue={login} placeholder="usuário" onChange={(e) => setLogin(e.target.value)}/>
                            <i className="email"></i>
                        </label>
                        <label>
                            <input placeholder="senha" defaultValue={password} type="password" onChange={(e) => setPassword(e.target.value)}/>
                            <i className="password"></i>
                        </label>

                        <Link to="/login">Esqueceu a senha?</Link>

                        <label>
                            <button>Entrar</button>
                        </label>

                    </form>

                </div>

                <div className="right animate__animated"></div>

            </div>

        </div>

    )

}
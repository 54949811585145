import React, { useEffect, useState } from 'react'
import Scrollbars from 'react-custom-scrollbars'
import { api } from '../../Api/app'
import Messages from '../../Components/Webchat/Mesages'
import SendMessage from '../../Components/Webchat/SendMessage'
import { firebase } from '../../Firebase/index'
import editMessages from '../../Firebase/editMessages'
import { useContatos } from '../../Firebase/useMessages'
import Modal from '../../Components/Modal/Modal'

import "./Web.scss"
import Contact from '../../Components/Webchat/Contact'
import messageStore from '../../Store/MessageStore'

export default function ChatWeb(props)
{

    const accountId = document.getElementById('zigzag') && document.getElementById('zigzag').dataset.account
    const [conversation, setConversation] = useState({})
    const usersRealtime = useContatos(accountId).contatos
    const [users, setUsers] = useState([])
    const [usersLoad, setUsersLoad] = useState(true)
    const [profile, setProfile] = useState({})
    const [filter, setFilter] = useState(false)
    const [search, setSearch] = useState([])
    const [searchInput, setSearchInput] = useState('')

    const [operators, setOperators] = useState([])

    useEffect(() => {
        api.get('profile').then( response => {
            setProfile({
                accountId: response.data.accountId,
                id: response.data.id,
                nome: response.data.nome,
                email: response.data.email,
            })
        })
        api.get('users?accountId='+accountId).then((response) => {
            setOperators(response.data.docs)
        })
    }, [])

    useEffect(() => {
        
        if ( filter === false && !searchInput ) setUsers([...usersRealtime])

        if ( conversation.id ) {

            const newConversation = usersRealtime.filter( obj => obj.id === conversation.id )[0]
            setConversation({...newConversation})

        }

    }, [usersRealtime])

    useEffect(() => {

        var usersFiltereds = usersRealtime

        if ( filter === 'minhas' ) usersFiltereds = usersFiltereds.filter( 
            obj => obj.operador.id === profile.id && obj.active.status === true )

        if ( filter === 'pendente' ) usersFiltereds = usersFiltereds.filter( 
            obj => !obj.operador.id && obj.active.status === true )

        if ( filter === 'encerrado' ) usersFiltereds = usersFiltereds.filter( 
            obj => obj.active.status === false )

        setUsers([...usersFiltereds])

    }, [filter, usersRealtime])

    useEffect(() => {

        handleSearch()

    }, [filter])

    async function handleSearch(e)
    {

        try {

            e.preventDefault()

            if (usersLoad === true) throw "Aguarde o carregamento."
            setUsersLoad(true)
            if (searchInput.length === 0 ) throw "Caracteres insuficientes."
            const newArray = []
            
            const loop = async (row, key) => {

                console.log(row)
                const snapshot = await firebase.firestore().collection(row.idMessages).get()
                const arrayMessages = snapshot.docs.map(doc => doc.data())
                console.log(arrayMessages)


                arrayMessages.map((message) => {
                    let myString = message.mensagem.toLowerCase()
                    let myVariable = searchInput.toLowerCase()
                    let myReg = new RegExp(myVariable + ".*")
                    let myMatch = myString.match(myReg)
                    if (myMatch && message.mensagemTipo === 'text') {
                        message.idMessage = message.idMessage ? message.idMessage : message.key
                        newArray.push({
                            ...row,
                            ultimaMensagem: message,
                        })
                    }
                })


                console.log(row)
                return Promise.resolve(row)

            }

            const responses = await Promise.all(
                users.map((row, key) => loop(row, key))
            )

            const searchUsers = []    
            await newArray.map((row, key) => {
                if ( searchUsers.filter( obj => obj.idMessages === row.idMessages ).length === 0 ) {
                    searchUsers.push({
                        idMessages: row.idMessages,
                        messages: newArray.filter( obj => obj.idMessages === row.idMessages )
                    })
                }
            })

            setUsersLoad(false)
            setSearch([...searchUsers])

        } catch ( err ) {
            setSearch([])
            setUsersLoad(false)
        }

    }

    function handleChangeConversation(item)
    {

        //se for minhas, marca como lido
        if ( item.operador.id === profile.id ) {

            if ( conversation.operador && conversation.operador.id === profile.id ) {
                const newConversation = usersRealtime.filter( obj => obj.id === conversation.id)[0]
                newConversation.totalOperador = 0
                editMessages.editContatoJson(accountId, conversation.id, newConversation)
            }
    
            item.totalOperador = 0
            editMessages.editContatoJson(accountId, item.id, item)
            
        }

        setConversation(item)

    }

    async function handleSetMyChat( id = false )
    {

        var newProfile = profile

        if ( id ) newProfile = operators.filter( obj => obj.id === id )[0]
        if ( id === '' ) newProfile = {}

        editMessages.editContato(accountId, conversation.id, false, false, false, false, false, newProfile)
        conversation.operador = newProfile
        setConversation({...conversation})

    }

    const [chatbots, setChatbots] = useState([])

    useEffect(() => {

        api.get('chatbots').then((response) => {

            setChatbots(response.data)

        })

    }, [])

    const [modalEmbed, setModalEmbed] = useState(false)

    const [embed, setEmbed] = useState({
        titulo: 'Tire suas duvidas',
        backgroundColor: '#5973f7',
        color: '#FFF',
        token: accountId,
        chatbot: ''
    })

    function handleChangeEmbed(name, value)
    {
        embed[name] = value
        setEmbed({...embed})
    }

    function copyCode()
    {

        var copyText = document.getElementById("embedScript");

        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */

        /* Copy the text inside the text field */
        document.execCommand("copy");

        /* Alert the copied text */
        messageStore.addSuccess('Código de incorporação copiado com sucesso.')

    }

    return(
        <div id="webchat-operator">

            <div className="users">

                <form onSubmit={handleSearch} className="search">
                    <input value={searchInput} onChange={(e) => setSearchInput(e.target.value)} placeholder="Buscar"/>
                    <button>
                        <i className="fa fa-search"></i>
                    </button>
                </form>

                <div className="filters">

                    <button className={`${filter === false ? 'active' : 'inactive'}`} onClick={() => setFilter(false)}>
                        <span>Todas conversas</span>
                        <i>{usersRealtime.filter( obj => obj.totalOperador > 0 ).length}</i>
                    </button>

                    <button className={`${filter === 'minhas' ? 'active' : 'inactive'}`} onClick={() => setFilter('minhas')}>
                        <span>Minhas conversas</span>
                        <i>{usersRealtime.filter( obj => obj.totalOperador > 0 && obj.operador.id === profile.id ).length}</i>
                    </button>

                    <button className={`${filter === 'pendente' ? 'active' : 'inactive'}`} onClick={() => setFilter('pendente')}>
                        <span>Aguardando</span>
                        <i>{usersRealtime.filter( obj => !obj.operador.id && obj.active.status === true ).length}</i>
                    </button>

                    <button className={`${filter === 'encerrado' ? 'active' : 'inactive'}`} onClick={() => setFilter('encerrado')}>
                        <span>Encerrados</span>
                    </button>

                </div>

                <div className="scroll-users">

                    <div className={`load-users ${usersLoad ? 'active' : 'inactive'}`}>
                        <i className="fa fa-spin fa-spinner"></i>
                    </div>

                    { search.length > 0 ?
                        <Scrollbars>

                            <h5>Resultados</h5>

                            { search.map((row, key) =>

                                <div className="search-user">
                                    { row.messages.map((message, messageKey) =>
                                        <Contact
                                            row={message}
                                            key={messageKey}
                                            conversation={conversation}
                                            active={message.ultimaMensagem?.idMessage === conversation.ultimaMensagem?.idMessage ? true : false}
                                            handleChangeConversation={handleChangeConversation}
                                            setOperator={(id) => handleSetMyChat(id)}
                                            operators={operators}
                                            profile={profile}
                                        />
                                    )}
                                </div>
                            )}
                        </Scrollbars>
                    :
                        <Scrollbars>
                            <div className="all-user">
                                { users.map((row, key) =>
                                    <Contact
                                        row={row}
                                        key={key}
                                        active={row.id === conversation.id ? true : false}
                                        conversation={conversation}
                                        handleChangeConversation={handleChangeConversation}
                                        setOperator={(id) => handleSetMyChat(id)}
                                        operators={operators}
                                        profile={profile}
                                    />
                                )}
                            </div>
                        </Scrollbars>
                    }
                </div>

            </div>

            <div id="chat-web-t2u">

                <div className="embed-head">

                    <Modal title="Código de incorporação" content={<button className="open-embed">Código de incorporação</button>}>

                        <form id="embed">
                            <label className="input-default-zig">
                                <span>Qual o titulo do chat?</span>
                                <input value={embed.titulo} onChange={(e) => handleChangeEmbed('titulo', e.target.value)}/>
                            </label>
                            <label className="input-default-zig">
                                <span>Deseja usar um bot?</span>
                                <select onChange={(e) => handleChangeEmbed('bot', e.target.value)}>
                                    <option value="">Não quero usar bot</option>
                                    { chatbots.map((row, key) =>
                                        <option value={row.id}>{row.nome}</option>
                                    )}
                                </select>
                            </label>
                            <label className="input-default-zig">
                                <span>Cor de fundo</span>
                                <input type="color" value={embed.backgroundColor} onChange={(e) => handleChangeEmbed('backgroundColor', e.target.value)}/>
                            </label>
                            <label className="input-default-zig">
                                <span>Cor da letra</span>
                                <input type="color" value={embed.color} onChange={(e) => handleChangeEmbed('color', e.target.value)}/>
                            </label>

                            <div className="embed-final">
                                <textarea id="embedScript" onClick={copyCode} className="embed" readOnly={true} value={`<script id="t2u-webchat" type="text/javascript" src="https://text2you.com.br/webchat.js?token=${embed.token}&bot=${embed.bot ? embed.bot : ''}&titulo=${embed.titulo}&color=${embed.color}&backgroundColor=${embed.backgroundColor}"></script>`}/>
                                <div style={{backgroundColor: embed.backgroundColor}} id="openChat">
                                    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><g fill={embed.color}><path d="M2.678 11.894a1 1 0 0 1 .287.801a10.97 10.97 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8.06 8.06 0 0 0 8 14c3.996 0 7-2.807 7-6c0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894zm-.493 3.905a21.682 21.682 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7s-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105z"/><path d="M4 5.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8zm0 2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5z"/></g></svg>
                                </div>
                            </div>

                        </form>

                    </Modal>

                </div>

                { conversation.id &&
                    <div className="messages-conversation">

                        <Messages
                            accountId={accountId}
                            conversationId={conversation.id}
                            origem={'operador'}
                            messageId={conversation.ultimaMensagem.idMessage}
                        />

                        <SendMessage
                            accountId={accountId}
                            user={conversation}
                            origem={'operador'}
                            operator={profile}
                            chatbotAttendance={usersRealtime.filter(obj => obj.id === conversation.id)[0]?.chatbot ? true : false}
                        >

                            { conversation.active.status === true && conversation.operador.id !== profile.id && usersRealtime.filter(obj => obj.id === conversation.id)[0]?.chatbot === false &&
                                <div className="absolute-chat">
                                    <button className="set-my-chat" onClick={() => handleSetMyChat(profile.id)}>Assumir chat</button>
                                </div>
                            }

                        </SendMessage>
                       

                    </div>
                }
            </div>

        </div>
    )

}
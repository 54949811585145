import React, { useState, useEffect } from 'react';
import { api } from '../../Api/app';
import messageStore from '../../Store/MessageStore';

import './ContactList.scss';
import EditListNoModal from './EditListNoModal';

export default function EditList(props) {
    const [open, setOpen] = useState(props.open ? props.open : false);
    const [id, setId] = useState(props.id ? props.id : false);
    const [list, setList] = useState({});
    const [columns, setColumns] = useState([]);
    const [nameList, setNameList] = useState('');
    const [tab, setTab] = useState('list');
    const [typeList, setTypeList] = useState(null);

    useEffect(() => {
        console.log('alterado', columns, id);
        props.callbackChangeColumns(columns, id);
    }, [columns, tab]);

    useEffect(() => {
        if (id) {
            api.get('contactLists/' + id).then((response) => {
                setList({ ...response.data });
                setNameList(response.data.lista);
                setColumns(response.data.colunas);
                setTab('list');
            });
        }
    }, [open, id]);

    function handleOpen() {
        setOpen(true);
    }

    function handleSaveName(e) {
        e.preventDefault();

        api.put('contactLists/' + list._id, {
            lista: nameList,
            colunas: columns,
        })
            .then((response) => {
                messageStore.addSuccess('Nome alterado com sucesso.');
                props.callbackChangeName(list._id, nameList);
            })
            .catch((e) => {
                messageStore.addError('Não foi possivel alterar o nome.');
            });
    }

    function handleNewList(e) {
        e.preventDefault();

        try {
            if (!props.send && !typeList) throw 'Selecione o tipo da lista.';

            if (!nameList) throw 'Dê um nome para lista antes de prosseguir.';

            api.post('contactLists', {
                lista: nameList,
                tipo: props.send ? props.send : typeList,
                colunas: [
                    props.send === 'email' || typeList === 'email'
                        ? 'email'
                        : 'celular',
                    'nome',
                ],
            })
                .then((response) => {
                    if (response.data.error === true) {
                        messageStore.addError(response.data.message);
                    } else {
                        messageStore.addSuccess('Lista salva.');
                        props.callbackNewList(response.data.result);
                        setOpen(false);
                        setNameList('');
                    }
                })
                .catch((e) => {
                    messageStore.addError('Não foi possivel criar a lista.');
                });
        } catch (err) {
            messageStore.addError(err);
        }
    }

    function handleClose() {
        if (open === true) {
            var element = document.getElementById('contact-list');
            element.classList.add('closed');

            setTimeout(function () {
                setOpen(false);
            }, 900);
        }
    }

    return (
        <>
            <div
                type="button"
                className={`button-edit-list ${props.className}`}
                onClick={() =>
                    open === true ? handleClose(false) : handleOpen(false)
                }
            >
                {props.children ? (
                    props.children
                ) : (
                    <button type="button" className="button-zig secondary">
                        Editar
                    </button>
                )}
            </div>

            {open === true && (
                <div id="contact-list" className="modal-contact">
                    <button
                        type="button"
                        className="bg-close"
                        onClick={() =>
                            open === true
                                ? handleClose(false)
                                : handleOpen(false)
                        }
                    ></button>

                    <div className="modal" id="contact-list-modal">
                        <button
                            onClick={() => setOpen(false)}
                            className="close-modal"
                        >
                            X
                        </button>

                        <form
                            className="head"
                            onSubmit={list._id ? handleSaveName : handleNewList}
                        >
                            {!props.send && (
                                <select
                                    style={{ marginRight: 10, width: '30%' }}
                                    className="input-default"
                                    value={typeList}
                                    onChange={(e) =>
                                        setTypeList(e.target.value)
                                    }
                                >
                                    <option value="">Selecione um tipo</option>
                                    <option value="Whatsapp">Whatsapp</option>
                                    <option value="SMS">SMS</option>
                                    <option value="Voz">Voz</option>
                                    <option value="Email">Email</option>
                                </select>
                            )}

                            <input
                                className="input-default"
                                placeholder="Nome da lista"
                                defaultValue={nameList}
                                onChange={(e) => setNameList(e.target.value)}
                            />
                            <button>
                                {list._id ? 'Salvar nome' : 'Salvar'}
                            </button>
                        </form>

                        {props.nameOnly !== true && id && (
                            <EditListNoModal
                                id={id}
                                send={props.send}
                                callbackChangeColumns={
                                    props.callbackChangeColumns
                                }
                            />
                        )}
                    </div>
                </div>
            )}
        </>
    );
}

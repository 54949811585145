import React, { useState, useEffect } from 'react';
import { api } from '../../Api/app';

import './ContactList.scss';
import EditContact from './EditContact';
import ImportContacts from './ImportContacts';
import ListContact from './ListContact';
import EditColumns from './EditColumns';
import CleanCSV from './CleanCSV';
import CleanContacts from './CleanContacts';

export default function EditListNoModal({
    id,
    dataInicial = '',
    dataFinal = '',
    statusHigienizacao = '',
    callbackChangeColumns = () => {},
    send,
    search = '',
}) {
    const [page, setPage] = useState(1);
    const [list, setList] = useState({});
    const [columns, setColumns] = useState([]);
    const [nameList, setNameList] = useState('');
    const [tab, setTab] = useState('list');
    const [contact, setContact] = useState({});
    const [redirect, setRedirect] = useState(null);

    useEffect(() => {
        callbackChangeColumns(columns, id, send);
    }, [columns, tab]);

    useEffect(() => {
        if (id) {
            getList();
        }
    }, [id]);

    async function getList(tab = 'list') {
        api.get(`contactLists/${id}`).then((response) => {
            setList({ ...response.data });
            setNameList(response.data.lista);
            setColumns(response.data.colunas);
            setTab(tab);
        });
    }

    async function handleEditColumn(col) {
        setColumns([...col]);

        const response = await api.put('contactLists/' + list._id, {
            colunas: col,
        });
    }

    return (
        <div className="editListNoModal">
            <div className="button">
                <button
                    type="button"
                    onClick={() => setTab('list')}
                    className={
                        tab === 'list'
                            ? 'button-zig secondary'
                            : 'button-zig neutral'
                    }
                >
                    Contatos
                </button>

                <button
                    type="button"
                    onClick={() => setTab('add')}
                    className={
                        tab === 'add'
                            ? 'button-zig secondary'
                            : 'button-zig neutral'
                    }
                >
                    Adicionar contatos avulsos
                </button>

                <button
                    type="button"
                    onClick={() => setTab('import')}
                    className={
                        tab === 'import'
                            ? 'button-zig secondary'
                            : 'button-zig neutral'
                    }
                >
                    Importar contatos
                </button>

                <button
                    type="button"
                    onClick={() => setTab('columns')}
                    className={
                        tab === 'columns'
                            ? 'button-zig secondary'
                            : 'button-zig neutral'
                    }
                >
                    Editar colunas
                </button>

                <button
                    type="button"
                    onClick={() => setTab('clean')}
                    className={
                        tab === 'clean'
                            ? 'button-zig secondary'
                            : 'button-zig neutral'
                    }
                >
                    Higienizar CSV
                </button>

                <button
                    type="button"
                    onClick={() => setTab('clean-contacts')}
                    className={
                        tab === 'clean-contacts'
                            ? 'button-zig secondary'
                            : 'button-zig neutral'
                    }
                >
                    Higienizar Contatos
                </button>
            </div>

            <div className="content">
                {tab === 'list' && (
                    <ListContact
                        open={tab === 'list' ? true : false}
                        page={page}
                        setPage={setPage}
                        id={id}
                        columns={columns}
                        setTab={setTab}
                        setContact={setContact}
                        send={send}
                        search={search}
                        dataInicial={dataInicial}
                        dataFinal={dataFinal}
                        statusHigienizacao={statusHigienizacao}
                    />
                )}

                {tab === 'add' && (
                    <EditContact
                        open={tab === 'add' ? true : false}
                        list={list}
                        nameList={nameList}
                        columns={columns}
                        handleNewContact={handleEditColumn}
                        handleEditColumn={handleEditColumn}
                        setTab={setTab}
                        send={send}
                    />
                )}

                {tab === 'edit' && (
                    <EditContact
                        open={tab === 'edit' ? true : false}
                        setTab={setTab}
                        list={list}
                        nameList={nameList}
                        columns={columns}
                        contact={contact}
                        handleEditColumn={handleEditColumn}
                        send={send}
                    />
                )}

                {tab === 'columns' && (
                    <EditColumns
                        open={tab === 'columns' ? true : false}
                        setTab={setTab}
                        list={list}
                        nameList={nameList}
                        columns={columns}
                        contact={contact}
                        handleEditColumn={handleEditColumn}
                        redirect={redirect}
                        setRedirect={setRedirect}
                        send={send}
                    />
                )}

                <ImportContacts
                    open={tab === 'import' ? true : false}
                    setTab={setTab}
                    list={list}
                    nameList={nameList}
                    columns={columns}
                    contact={contact}
                    handleEditColumn={handleEditColumn}
                    redirect={redirect}
                    setRedirect={setRedirect}
                    send={send}
                />

                <CleanCSV
                    open={tab === 'clean' ? true : false}
                    setTab={setTab}
                    list={list}
                    nameList={nameList}
                    columns={columns}
                    contact={contact}
                    handleEditColumn={handleEditColumn}
                    redirect={redirect}
                    setRedirect={setRedirect}
                    send={send}
                    id={id}
                    onSubmit={getList}
                />

                <CleanContacts
                    open={tab === 'clean-contacts' ? true : false}
                    setTab={setTab}
                    list={list}
                    nameList={nameList}
                    columns={columns}
                    contact={contact}
                    handleEditColumn={handleEditColumn}
                    redirect={redirect}
                    setRedirect={setRedirect}
                    send={send}
                    id={id}
                    onSubmit={getList}
                />
            </div>
        </div>
    );
}

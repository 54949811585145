import React, { useEffect, useRef, useState } from 'react'
import Datetime from '../../Helper/Datetime'
import { Scrollbars } from 'react-custom-scrollbars'
import { Lightbox } from "react-modal-image"

import "./Messages.scss"
import { useContato, useMessages } from '../../Firebase/useMessages'
import editMessages from '../../Firebase/editMessages'
import messageStore from '../../Store/MessageStore'
import { useHistory } from 'react-router-dom'

export default function Messages({ conversationId, accountId, origem, setMessages = () => {}, chatbot, messageId})
{
    
    const refScroll = useRef()
    const [scrollPosition, setScrollPosition] = useState(1)
    const messages = useMessages(conversationId, accountId, origem)
    const userChanges = useContato(accountId, conversationId)
    const [imageZoom, setImageZoom] = useState(false)
    const history = useHistory()

    useEffect(() => {

        setTimeout(() => {

            var element = document.getElementById(messageId)

            if ( element ) {
                refScroll.current.scrollTop(element.offsetTop)
            }
            
        }, 100)

    }, [messageId])

    useEffect(() => {
        setTimeout(() => refScroll.current.scrollToBottom(), 500)
    }, [conversationId])

    useEffect(() => {

        if ( scrollPosition === 1 ) refScroll.current.scrollToBottom()
        getStatusUpdate()
        setMessages(messages.messages)

    }, [messages])

    async function getStatusUpdate()
    {

        //todas mensagens sem status
        const msgOperador = messages.messages.filter( obj => obj.mensagemStatusOperador === 0 )
        const msgUsuario = messages.messages.filter( obj => obj.mensagemStatusUsuario === 0 )

        const loop = async (row, origem) => {
            let response = editMessages.editStatusMessage(accountId, conversationId, row.key, row, origem)
            return Promise.resolve(response)
        }

        //lista e atualiza status das mensagens
        if (  origem === 'usuario' ) {
            let response = await Promise.all(msgUsuario.map((row) => loop(row, 'usuario')))
        } else if ( origem === 'operador' ) {
            let response = await Promise.all(msgOperador.map((row) => loop(row, 'operador')))
        }

    }

    const [rating, setRating] = useState({
        resolvido: null,
        satisfacao: null,
        obs: null,
    })

    function handleOnChangeRating(name, value)
    {
        rating[name] = value
        setRating({...rating})
    }

    async function handleSetRating(e)
    {

        try {

            e.preventDefault()

            if ( !rating.resolvido ) throw "Responda se o atendimento foi resolvido."
            if ( !rating.satisfacao ) throw "Dê uma nota para o atendimento."

            editMessages.editContato(accountId, conversationId, null, origem, false, false, rating)

            window.localStorage.removeItem('userchatprofile')
            messageStore.addSuccess('Obrigado por avaliar nosso atendimento.')
            history.push(`/webchat/login/${accountId}?bot=${chatbot}`)

        } catch ( err ) {

            messageStore.addError( err )

        }

    }

    return(
        <div className="conversation-webchat">

            { imageZoom && 
                <Lightbox
                    small={imageZoom}
                    large={imageZoom}
                    onClose={() => setImageZoom(false)}
                    hideDownload={true}
                />
            }

            <Scrollbars 
                ref={refScroll}
                onScrollFrame={(e) => setScrollPosition(e.top)}
                className="scroll-messages"
            >

                { messages.messages.map((row, key) => 

                    <div id={row.idMessage ? row.idMessage : row.key} className={`message ${messageId === row.idMessage || messageId === row.key ? 'search' : ''} ${origem === row.origem ? 'sent' : 'received'} `} key={key}>
                        <div className="content-message">

                            { row.mensagemTipo === 'text' && 
                                <span>{row.mensagem}</span> 
                            }
                            
                            { row.mensagemTipo === 'image' && 
                                <div style={{backgroundImage: `url(${row.mensagem})`}} onClick={() => setImageZoom(row.mensagem)} className="image"/> 
                            }
                            
                            { row.mensagemTipo === 'video' && 
                                <video className="video" controls="controls">
                                    <source src={row.mensagem} type="video/mp4" />
                                    seu navegador não suporta HTML5
                                </video> 
                            }

                            { row.mensagemTipo === 'audio' && 
                                <audio className="audio" controls="controls">
                                    <source src={row.mensagem} type="audio/mp3" />
                                    seu navegador não suporta HTML5
                                </audio>
                            }

                            { row.mensagemTipo === 'file' && 
                                <a href={row.mensagem} target="_blank" className="file">
                                    <img src={'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAA8CAYAAADL94L/AAAByElEQVR4Ae3axdJTQRAFYFyegA3u8ALseCDcicsGhxt3x+G32BXc3X3NBnfXYTqp3sZlhuqpOlXZRL46He9ReJyJxGSTEreaPfEHZiX+1uSJvelVNu+Jvjd7Yk9zI8aSUe0eDpjCIYfNSuw5v/zF5In/6mU27478tXriLJvXjdSwPq1lCDTCmxjiCNav8GZYBVMwWKagX8kWjk9vCcMhYWhEFEw1+oV0wZjdPKY6Vn9EwmBDTYPwBoXCYPLGDQTJjkHQNQRJj0FQtmgs+C8wOHIIkh2DoDu5vD5Xfkz9hsTBWDyxhjDYUDqvLRYSY1JilSQGyyxXOt4QKJPX70NDQmI27gyxHcn9bH/5RFMNAUgoDI4afOAMHBiCdiDNj5woGAhgsCEYudSI1lBCRwoPL957slAoDDYEoPXb/ZVs3FE/y9072fDxsx4BMPVfGOpl1VY/y5++4EWM1Fm9LcCKpy8RpnchDGEIQxjCEIYwhCEMYQhDGMIQhjCEIQxhCEMYwhCGMIQhDGEIQxhYNlXiP+XHXLRDM5thQVpyzIfS2YtLceVEkRmzalsgMArPhp258bA6b/LEb8LqPM930VNdvY/fhMmCxw+Of+4BTcPInBo2AAAAAElFTkSuQmCC'}/>
                                    <span>Download arquivo</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34" width="34" height="34"><path fill="currentColor" d="M17 2c8.3 0 15 6.7 15 15s-6.7 15-15 15S2 25.3 2 17 8.7 2 17 2m0-1C8.2 1 1 8.2 1 17s7.2 16 16 16 16-7.2 16-16S25.8 1 17 1z"></path><path fill="currentColor" d="M22.4 17.5h-3.2v-6.8c0-.4-.3-.7-.7-.7h-3.2c-.4 0-.7.3-.7.7v6.8h-3.2c-.6 0-.8.4-.4.8l5 5.3c.5.7 1 .5 1.5 0l5-5.3c.7-.5.5-.8-.1-.8z"></path></svg>
                                </a>
                            }
                            
                            <div className="status">

                                { origem === row.origem && origem === 'usuario' &&
                                    <>
                                        { row.mensagemStatusOperador === 0 && <svg className="status-check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 15" width="16" height="15"><path fill="currentColor" d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.879a.32.32 0 0 1-.484.033l-.358-.325a.319.319 0 0 0-.484.032l-.378.483a.418.418 0 0 0 .036.541l1.32 1.266c.143.14.361.125.484-.033l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"></path></svg>}
                                        { row.mensagemStatusOperador === 1 && <svg className="status-check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 15" width="16" height="15"><path fill="currentColor" d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.879a.32.32 0 0 1-.484.033l-.358-.325a.319.319 0 0 0-.484.032l-.378.483a.418.418 0 0 0 .036.541l1.32 1.266c.143.14.361.125.484-.033l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z" fill="#4fc3f7"></path></svg> }
                                    </>
                                }

                                { origem === row.origem && origem === 'operador' &&
                                    <>
                                        { row.mensagemStatusUsuario === 0 && <svg className="status-check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 15" width="16" height="15"><path fill="currentColor" d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.879a.32.32 0 0 1-.484.033l-.358-.325a.319.319 0 0 0-.484.032l-.378.483a.418.418 0 0 0 .036.541l1.32 1.266c.143.14.361.125.484-.033l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"></path></svg>}
                                        { row.mensagemStatusUsuario === 1 && <svg className="status-check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 15" width="16" height="15"><path fill="currentColor" d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.879a.32.32 0 0 1-.484.033l-.358-.325a.319.319 0 0 0-.484.032l-.378.483a.418.418 0 0 0 .036.541l1.32 1.266c.143.14.361.125.484-.033l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z" fill="#4fc3f7"></path></svg> }
                                    </>
                                }
                                
                            </div>
                        </div>
                        <i>{Datetime.string(row.criado)}</i>
                    </div>
                )}

                { userChanges.contato?.active?.status === false && origem === 'usuario' && 
                    <form onSubmit={handleSetRating} className="rating">
                        
                        <p className="title">Olá <b>{userChanges.contato?.nome}</b>, poderia avaliar esse atendimento?</p>

                        <div className="question">
                            <p>Seu atendimento foi resolvido?</p>
                            <div className="options">
                                <label>
                                    <input checked={rating.resolvido === 'sim' ? true : false} onChange={() => handleOnChangeRating('resolvido', 'sim')} type="radio"/>
                                    <span>Sim</span>
                                </label>
                                <label>
                                    <input checked={rating.resolvido === 'não' ? true : false} onChange={() => handleOnChangeRating('resolvido', 'não')} type="radio"/>
                                    <span>Não</span>
                                </label>
                            </div>
                        </div>

                        <div className="question">
                            <p>Qual nível de satisfação?</p>
                            <div className="stars">
                                <i onClick={() => handleOnChangeRating('satisfacao', 1)} class={`fa fa-star${rating.satisfacao > 0 ? '' : '-o'}`} aria-hidden="true"></i>
                                <i onClick={() => handleOnChangeRating('satisfacao', 2)} class={`fa fa-star${rating.satisfacao > 1 ? '' : '-o'}`} aria-hidden="true"></i>
                                <i onClick={() => handleOnChangeRating('satisfacao', 3)} class={`fa fa-star${rating.satisfacao > 2 ? '' : '-o'}`} aria-hidden="true"></i>
                                <i onClick={() => handleOnChangeRating('satisfacao', 4)} class={`fa fa-star${rating.satisfacao > 3 ? '' : '-o'}`} aria-hidden="true"></i>
                                <i onClick={() => handleOnChangeRating('satisfacao', 5)} class={`fa fa-star${rating.satisfacao > 4 ? '' : '-o'}`} aria-hidden="true"></i>
                            </div>
                        </div>

                        <label className="question">
                            <p>Observação</p>
                            <div className="obs">
                                <textarea onChange={(e) => handleOnChangeRating('obs', e.target.value)} value={rating.obs}/>
                            </div>
                        </label>

                        <button className="button-zig full secondary">Confirmar avaliação</button>

                    </form>
                }

                { userChanges.contato?.active?.status === false && origem === 'operador' && 
                    <div onSubmit={handleSetRating} className="rating">
                        
                        <p className="title">Atendimento encerrado { userChanges.contato?.active?.origem === 'usuario' ? 'pelo usuario' : 'por você.' }</p>

                        { userChanges.contato?.avaliacao && 
                            <>
                                <div className="question">
                                    <p>Atendimento foi resolvido?</p>
                                    <div className="options">
                                        <label>
                                            <input checked={userChanges.contato.avaliacao.resolvido === 'sim' ? true : false} type="radio"/>
                                            <span>Sim</span>
                                        </label>
                                        <label>
                                            <input checked={userChanges.contato.avaliacao.resolvido === 'não' ? true : false} type="radio"/>
                                            <span>Não</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="question">
                                    <p>Qual nível de satisfação?</p>
                                    <div className="stars">
                                        <i class={`fa fa-star${userChanges.contato.avaliacao.satisfacao > 0 ? '' : '-o'}`} aria-hidden="true"></i>
                                        <i class={`fa fa-star${userChanges.contato.avaliacao.satisfacao > 1 ? '' : '-o'}`} aria-hidden="true"></i>
                                        <i class={`fa fa-star${userChanges.contato.avaliacao.satisfacao > 2 ? '' : '-o'}`} aria-hidden="true"></i>
                                        <i class={`fa fa-star${userChanges.contato.avaliacao.satisfacao > 3 ? '' : '-o'}`} aria-hidden="true"></i>
                                        <i class={`fa fa-star${userChanges.contato.avaliacao.satisfacao > 4 ? '' : '-o'}`} aria-hidden="true"></i>
                                    </div>
                                </div>
                                { userChanges.contato.avaliacao.obs !== null &&
                                    <div className="question">
                                        <p>Observação</p>
                                        <div className="obs">
                                            <span>{ userChanges.contato.avaliacao.obs }</span>
                                        </div>
                                    </div>
                                }
                            </>
                        }

                    </div>
                }

            </Scrollbars>

        </div>
    )

}
import React, { useEffect, useState, PureComponent } from 'react';
import { api } from '../../Api/app';
import H1Page from '../../Components/Layout/H1Page';

import datesFormater from '../../Helper/DatesFormater';
import Paginator from '../../Components/Paginator/Paginator';

import './Analytics.scss';
import Modal from '../../Components/Modal/Modal';
import Answers from '../../Components/Modal/Answers';
import { PieChart } from 'react-minimal-pie-chart';
import Datetime from '../../Helper/Datetime';

export default function Analytics(props) {
    const [load, setLoad] = useState(false);

    const [data, setData] = useState({
        docs: [],
    });

    const [page, setPage] = useState(1);

    const [id, setId] = useState(
        props.match.params.id ? props.match.params.id : ''
    );

    const [dataInicial, setDataInicial] = useState('');

    const [dataFinal, setDataFinal] = useState('');

    const [campanha, setCampanha] = useState({});

    useEffect(() => {
        getData();
    }, [page, id, dataInicial, dataFinal]);

    async function getData() {
        try {
            setLoad(true);

            const campgainInfo = await api.get(
                'campaigns/' + id + '?dataInicial='
            );
            setCampanha(campgainInfo.data);

            console.log(campgainInfo);

            const url =
                'queues?campaignId=' +
                id +
                '&dataInicial=' +
                dataInicial +
                '&dataFinal=' +
                dataFinal +
                '&page=' +
                page;
            const response = await api.get(url);
            setData({ ...response.data });

            setLoad(false);
        } catch (e) {
            setLoad(false);
        }
    }

    const RelatorioDeVoz = ({ row }) => {
        const item = JSON.parse(row);
        console.log(item);
        return <div>ola</div>;
    };

    return (
        <div id="analytics" className="page">
            <div className="h1-button">
                <H1Page nome={'Analytics'} />
            </div>

            {/* <div className="filter-page">

                <label>
                    <input onChange={(e) => setDataInicial(e.target.value)} type="date" name="dataInicial"/>
                </label>

                <label>
                    <input onChange={(e) => setDataFinal(e.target.value)} type="date" name="dataFinal"/>
                </label>

            </div> */}

            <div className="row-sintetic">
                <div className="column">
                    <div className="info">
                        <div className="column-info">
                            <div className="label">
                                <span>Campanha</span>
                                <b>{campanha.nome}</b>
                            </div>

                            <div className="label">
                                <span>Data de envios</span>
                                <b>{Datetime.string(campanha.dataEnvio)}</b>
                            </div>

                            <div className="label">
                                <span>Canal</span>
                                <b>{campanha.tipoDeEnvio}</b>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="column">
                    <div className="row">
                        <div className="info">
                            <div className="label">
                                <span>MAILING</span>
                                <b>{campanha.totalPorStatus?.total}</b>
                            </div>

                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 30 30"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M17.1431 11.7825C17.1433 12.064 17.088 12.3429 16.9805 12.6031C16.8729 12.8632 16.7152 13.0997 16.5162 13.2989C16.3173 13.4982 16.0811 13.6563 15.8211 13.7642C15.561 13.8721 15.2823 13.9278 15.0007 13.928C14.7192 13.9282 14.4404 13.873 14.1802 13.7654C13.92 13.6579 13.6835 13.5001 13.4843 13.3012C13.285 13.1023 13.1269 12.8661 13.019 12.606C12.9111 12.346 12.8554 12.0672 12.8552 11.7857C12.8548 11.2171 13.0802 10.6716 13.482 10.2692C13.8838 9.86688 14.4289 9.64059 14.9975 9.64017C15.5661 9.63974 16.1116 9.86521 16.514 10.267C16.9163 10.6687 17.1426 11.2139 17.1431 11.7825ZM18.7502 15.8711V16.4496C18.7502 17.6121 17.1538 18.4821 15.0002 18.4821C12.8466 18.4821 11.2502 17.6111 11.2502 16.4507V15.8711C11.2498 15.757 11.2718 15.644 11.3151 15.5385C11.3584 15.4329 11.4221 15.337 11.5025 15.2561C11.5829 15.1752 11.6785 15.1109 11.7837 15.067C11.889 15.023 12.0019 15.0003 12.1159 15H17.8856C17.9995 15.0004 18.1123 15.0233 18.2174 15.0673C18.3226 15.1113 18.418 15.1756 18.4983 15.2564C18.5786 15.3373 18.6421 15.4332 18.6854 15.5387C18.7286 15.6442 18.7506 15.7571 18.7502 15.8711ZM8.30377 2.67856C7.38025 2.67856 6.49455 3.04543 5.84152 3.69845C5.18849 4.35148 4.82162 5.23718 4.82162 6.1607V23.8393C4.82162 24.7628 5.18849 25.6485 5.84152 26.3015C6.49455 26.9545 7.38025 27.3214 8.30377 27.3214H24.3752C24.5883 27.3214 24.7927 27.2368 24.9434 27.0861C25.0941 26.9354 25.1788 26.731 25.1788 26.5178C25.1788 26.3047 25.0941 26.1003 24.9434 25.9496C24.7927 25.7989 24.5883 25.7143 24.3752 25.7143H8.30377C7.8529 25.7143 7.41714 25.5518 7.07633 25.2566C6.73551 24.9615 6.51246 24.5534 6.44805 24.1071H24.3752C24.5883 24.1071 24.7927 24.0225 24.9434 23.8718C25.0941 23.7211 25.1788 23.5167 25.1788 23.3036V6.1607C25.1788 5.23718 24.8119 4.35148 24.1589 3.69845C23.5058 3.04543 22.6201 2.67856 21.6966 2.67856H8.30377ZM23.5716 6.1607V22.5H6.42877V6.1607C6.42877 5.1257 7.26877 4.2857 8.30377 4.2857H21.6966C22.7327 4.2857 23.5716 5.1257 23.5716 6.1607Z"
                                    fill="black"
                                />
                            </svg>
                        </div>

                        <div className="info">
                            <div className="label">
                                <span>FILA</span>
                                <b>{campanha.totalPorStatus?.fila}</b>
                            </div>

                            <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M9.1875 7.284H7.305V4.14533C7.305 3.96852 7.23915 3.79895 7.12194 3.67392C7.00473 3.5489 6.84576 3.47866 6.68 3.47866H6.5625C6.39674 3.47866 6.23777 3.5489 6.12056 3.67392C6.00335 3.79895 5.9375 3.96852 5.9375 4.14533V8.07466C5.9375 8.25147 6.00335 8.42104 6.12056 8.54607C6.23777 8.67109 6.39674 8.74133 6.5625 8.74133H6.68L6.68375 8.74L6.6875 8.74266H9.1875C9.35326 8.74266 9.51223 8.67242 9.62944 8.5474C9.74665 8.42238 9.8125 8.25281 9.8125 8.076V7.95066C9.8125 7.77385 9.74665 7.60428 9.62944 7.47926C9.51223 7.35423 9.35326 7.284 9.1875 7.284ZM9.1875 0.266663H4.8125C2.5375 0.266663 0.6875 2.24 0.6875 4.66666V9.33333C0.6875 11.76 2.5375 13.7333 4.8125 13.7333H9.1875C11.4625 13.7333 13.3125 11.76 13.3125 9.33333V4.66666C13.3125 2.24 11.4625 0.266663 9.1875 0.266663ZM11.6875 9.33333C11.6875 10.0406 11.4241 10.7189 10.9553 11.2189C10.4864 11.719 9.85054 12 9.1875 12H4.8125C4.14946 12 3.51357 11.719 3.04473 11.2189C2.57589 10.7189 2.3125 10.0406 2.3125 9.33333V4.66666C2.3125 3.95942 2.57589 3.28114 3.04473 2.78104C3.51357 2.28095 4.14946 2 4.8125 2H9.1875C9.85054 2 10.4864 2.28095 10.9553 2.78104C11.4241 3.28114 11.6875 3.95942 11.6875 4.66666V9.33333Z"
                                    fill="black"
                                />
                            </svg>
                        </div>

                        <div className="info">
                            <div className="label">
                                <span>CANCELADOS</span>
                                <b>{campanha.totalPorStatus?.cancelados}</b>
                            </div>

                            <svg
                                width="20"
                                height="19"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M8.75 14C7.51387 14 6.3055 13.6334 5.27769 12.9467C4.24988 12.2599 3.4488 11.2838 2.97576 10.1418C2.50271 8.99974 2.37894 7.74307 2.6201 6.53069C2.86125 5.31831 3.45651 4.20466 4.33059 3.33059C5.20466 2.45651 6.31831 1.86125 7.53069 1.6201C8.74307 1.37894 9.99974 1.50271 11.1418 1.97576C12.2838 2.4488 13.2599 3.24988 13.9467 4.27769C14.6334 5.3055 15 6.51387 15 7.75H16.25C16.25 6.26664 15.8101 4.8166 14.986 3.58323C14.1619 2.34986 12.9906 1.38856 11.6201 0.820907C10.2497 0.25325 8.74168 0.104725 7.28683 0.394114C5.83197 0.683503 4.4956 1.39781 3.4467 2.4467C2.39781 3.4956 1.6835 4.83197 1.39411 6.28683C1.10472 7.74168 1.25325 9.24968 1.82091 10.6201C2.38856 11.9906 3.34986 13.1619 4.58323 13.986C5.8166 14.8101 7.26664 15.25 8.75 15.25V14Z"
                                    fill="black"
                                />
                                <path
                                    d="M7.5 8.74375L5.88125 7.125L5 8.00625L7.5 10.5063L11.875 6.13125L10.9938 5.25L7.5 8.74375Z"
                                    fill="black"
                                />
                                <path
                                    d="M18.75 14C18.75 13.2583 18.5301 12.5333 18.118 11.9166C17.706 11.2999 17.1203 10.8193 16.4351 10.5355C15.7498 10.2516 14.9958 10.1774 14.2684 10.3221C13.541 10.4668 12.8728 10.8239 12.3484 11.3484C11.8239 11.8728 11.4668 12.541 11.3221 13.2684C11.1774 13.9958 11.2516 14.7498 11.5355 15.4351C11.8193 16.1203 12.2999 16.706 12.9166 17.118C13.5333 17.5301 14.2583 17.75 15 17.75C15.9942 17.7488 16.9474 17.3534 17.6504 16.6504C18.3534 15.9474 18.7488 14.9942 18.75 14ZM17.5 14C17.4977 14.4449 17.3752 14.8808 17.1456 15.2619L13.7381 11.8544C14.1192 11.6248 14.5551 11.5024 15 11.5C15.6628 11.5008 16.2982 11.7645 16.7669 12.2331C17.2355 12.7018 17.4992 13.3372 17.5 14ZM12.5 14C12.5023 13.5551 12.6248 13.1192 12.8544 12.7381L16.2619 16.1456C15.8808 16.3752 15.4449 16.4976 15 16.5C14.3372 16.4992 13.7018 16.2355 13.2331 15.7669C12.7645 15.2982 12.5008 14.6628 12.5 14Z"
                                    fill="black"
                                />
                            </svg>
                        </div>
                    </div>

                    <div className="row">
                        <div className="info">
                            <div className="label">
                                <span>ENVIOS</span>
                                <b>{campanha.totalPorStatus?.envios}</b>
                                {campanha.totalPorStatus?.envios > 0 && (
                                    <i className="up">
                                        <svg
                                            width="8"
                                            height="8"
                                            viewBox="0 0 8 8"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M7.5004 0.3125C7.5004 0.22962 7.46747 0.150134 7.40887 0.0915292C7.35026 0.0329241 7.27078 0 7.1879 0H3.43789C3.35501 0 3.27553 0.0329241 3.21692 0.0915292C3.15832 0.150134 3.12539 0.22962 3.12539 0.3125C3.12539 0.39538 3.15832 0.474866 3.21692 0.533471C3.27553 0.592076 3.35501 0.625 3.43789 0.625H6.43352L0.0916447 6.96625C0.0625897 6.99531 0.0395422 7.0298 0.0238177 7.06776C0.00809329 7.10572 0 7.14641 0 7.1875C0 7.22859 0.00809329 7.26928 0.0238177 7.30724C0.0395422 7.3452 0.0625897 7.37969 0.0916447 7.40875C0.1207 7.43781 0.155193 7.46085 0.193155 7.47658C0.231117 7.4923 0.271805 7.50039 0.312895 7.50039C0.353985 7.50039 0.394672 7.4923 0.432634 7.47658C0.470597 7.46085 0.50509 7.43781 0.534145 7.40875L6.8754 1.06687V4.0625C6.8754 4.14538 6.90832 4.22487 6.96692 4.28347C7.02553 4.34208 7.10502 4.375 7.1879 4.375C7.27078 4.375 7.35026 4.34208 7.40887 4.28347C7.46747 4.22487 7.5004 4.14538 7.5004 4.0625V0.3125Z"
                                                fill="#159300"
                                            />
                                        </svg>
                                        {Math.round(
                                            (campanha.totalPorStatus?.envios /
                                                campanha.totalPorStatus
                                                    ?.total) *
                                                100
                                        )}
                                        %
                                    </i>
                                )}
                            </div>

                            <svg
                                width="13"
                                height="14"
                                viewBox="0 0 13 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M12.6375 1.42133L12.04 0.925334C11.9448 0.84686 11.8244 0.811659 11.7051 0.82739C11.5857 0.843122 11.4769 0.908513 11.4025 1.00933L4.70751 10.172C4.67301 10.2212 4.62897 10.2618 4.5784 10.2912C4.52782 10.3205 4.47191 10.3379 4.41447 10.342C4.35703 10.3462 4.29942 10.3371 4.24558 10.3154C4.19174 10.2936 4.14294 10.2597 4.10251 10.216L1.36376 7.35867C1.27711 7.26926 1.16088 7.21995 1.04034 7.22145C0.919795 7.22295 0.804677 7.27513 0.720007 7.36667L0.191257 7.944C0.107211 8.03603 0.0608047 8.15985 0.0622106 8.28832C0.0636165 8.41679 0.11272 8.53942 0.198757 8.62933L4.27126 12.876C4.45001 13.0627 4.72251 13.0427 4.87626 12.832L12.7163 2.10133C12.7898 1.99981 12.8228 1.8714 12.8081 1.74405C12.7933 1.6167 12.732 1.50071 12.6375 1.42133Z"
                                    fill="black"
                                />
                            </svg>
                        </div>

                        <div className="info">
                            <div className="label">
                                <span>RECEBIDOS</span>
                                <b>{campanha.totalPorStatus?.recebidos}</b>
                                {campanha.totalPorStatus?.recebidos > 0 && (
                                    <i className="up">
                                        <svg
                                            width="8"
                                            height="8"
                                            viewBox="0 0 8 8"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M7.5004 0.3125C7.5004 0.22962 7.46747 0.150134 7.40887 0.0915292C7.35026 0.0329241 7.27078 0 7.1879 0H3.43789C3.35501 0 3.27553 0.0329241 3.21692 0.0915292C3.15832 0.150134 3.12539 0.22962 3.12539 0.3125C3.12539 0.39538 3.15832 0.474866 3.21692 0.533471C3.27553 0.592076 3.35501 0.625 3.43789 0.625H6.43352L0.0916447 6.96625C0.0625897 6.99531 0.0395422 7.0298 0.0238177 7.06776C0.00809329 7.10572 0 7.14641 0 7.1875C0 7.22859 0.00809329 7.26928 0.0238177 7.30724C0.0395422 7.3452 0.0625897 7.37969 0.0916447 7.40875C0.1207 7.43781 0.155193 7.46085 0.193155 7.47658C0.231117 7.4923 0.271805 7.50039 0.312895 7.50039C0.353985 7.50039 0.394672 7.4923 0.432634 7.47658C0.470597 7.46085 0.50509 7.43781 0.534145 7.40875L6.8754 1.06687V4.0625C6.8754 4.14538 6.90832 4.22487 6.96692 4.28347C7.02553 4.34208 7.10502 4.375 7.1879 4.375C7.27078 4.375 7.35026 4.34208 7.40887 4.28347C7.46747 4.22487 7.5004 4.14538 7.5004 4.0625V0.3125Z"
                                                fill="#159300"
                                            />
                                        </svg>
                                        {Math.round(
                                            (campanha.totalPorStatus
                                                ?.recebidos /
                                                campanha.totalPorStatus
                                                    ?.total) *
                                                100
                                        )}
                                        %
                                    </i>
                                )}
                            </div>

                            <svg
                                width="18"
                                height="13"
                                viewBox="0 0 18 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M17.7625 1.145L17.165 0.680001C17.0698 0.606432 16.9494 0.573431 16.83 0.588179C16.7106 0.602928 16.6019 0.664232 16.5275 0.758751L9.83249 9.34875C9.798 9.39485 9.75396 9.43295 9.70338 9.46046C9.65281 9.48797 9.59689 9.50424 9.53945 9.50816C9.48201 9.51207 9.42441 9.50354 9.37057 9.48315C9.31673 9.46276 9.26792 9.43099 9.22749 9.39L8.77999 8.98375C8.73975 8.9425 8.69104 8.91047 8.63723 8.88985C8.58342 8.86924 8.52577 8.86053 8.46827 8.86433C8.41077 8.86814 8.35478 8.88436 8.30415 8.91188C8.25352 8.9394 8.20945 8.97757 8.17499 9.02375L7.70249 9.6275C7.62839 9.72718 7.59223 9.84999 7.60047 9.97391C7.60872 10.0978 7.66084 10.2148 7.74749 10.3038L9.39749 11.8863C9.57624 12.0613 9.84874 12.0425 10.0025 11.845L17.8425 1.785C17.9164 1.68938 17.9494 1.56841 17.9345 1.44851C17.9195 1.3286 17.8576 1.21949 17.7625 1.145ZM12.6375 1.145L12.04 0.680001C11.9448 0.606432 11.8244 0.573431 11.705 0.588179C11.5856 0.602928 11.4769 0.664232 11.4025 0.758751L4.70749 9.34875C4.673 9.39485 4.62896 9.43295 4.57838 9.46046C4.52781 9.48797 4.47189 9.50424 4.41445 9.50816C4.35701 9.51207 4.29941 9.50354 4.24557 9.48315C4.19173 9.46276 4.14292 9.43099 4.10249 9.39L1.36374 6.71125C1.2771 6.62743 1.16087 6.5812 1.04032 6.58261C0.91978 6.58401 0.804662 6.63294 0.719992 6.71875L0.191242 7.26C0.107196 7.34628 0.0607894 7.46236 0.0621953 7.5828C0.0636012 7.70324 0.112705 7.81821 0.198742 7.9025L4.27124 11.8838C4.44999 12.0588 4.72249 12.04 4.87624 11.8425L12.7162 1.7825C12.7898 1.68732 12.8228 1.56694 12.8081 1.44754C12.7933 1.32815 12.732 1.21942 12.6375 1.145Z"
                                    fill="black"
                                />
                            </svg>
                        </div>

                        <div className="info">
                            <div className="label">
                                <span>CONFIRMADOS</span>
                                <b>{campanha.totalPorStatus?.confirmados}</b>
                                {campanha.totalPorStatus?.confirmados > 0 && (
                                    <i className="up">
                                        <svg
                                            width="8"
                                            height="8"
                                            viewBox="0 0 8 8"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M7.5004 0.3125C7.5004 0.22962 7.46747 0.150134 7.40887 0.0915292C7.35026 0.0329241 7.27078 0 7.1879 0H3.43789C3.35501 0 3.27553 0.0329241 3.21692 0.0915292C3.15832 0.150134 3.12539 0.22962 3.12539 0.3125C3.12539 0.39538 3.15832 0.474866 3.21692 0.533471C3.27553 0.592076 3.35501 0.625 3.43789 0.625H6.43352L0.0916447 6.96625C0.0625897 6.99531 0.0395422 7.0298 0.0238177 7.06776C0.00809329 7.10572 0 7.14641 0 7.1875C0 7.22859 0.00809329 7.26928 0.0238177 7.30724C0.0395422 7.3452 0.0625897 7.37969 0.0916447 7.40875C0.1207 7.43781 0.155193 7.46085 0.193155 7.47658C0.231117 7.4923 0.271805 7.50039 0.312895 7.50039C0.353985 7.50039 0.394672 7.4923 0.432634 7.47658C0.470597 7.46085 0.50509 7.43781 0.534145 7.40875L6.8754 1.06687V4.0625C6.8754 4.14538 6.90832 4.22487 6.96692 4.28347C7.02553 4.34208 7.10502 4.375 7.1879 4.375C7.27078 4.375 7.35026 4.34208 7.40887 4.28347C7.46747 4.22487 7.5004 4.14538 7.5004 4.0625V0.3125Z"
                                                fill="#159300"
                                            />
                                        </svg>
                                        {Math.round(
                                            (campanha.totalPorStatus
                                                ?.confirmados /
                                                campanha.totalPorStatus
                                                    ?.total) *
                                                100
                                        )}
                                        %
                                    </i>
                                )}
                            </div>

                            <svg
                                width="18"
                                height="12"
                                viewBox="0 0 18 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M17.7003 0.560266L17.1028 0.0952655C17.0076 0.0216963 16.8873 -0.0113049 16.7679 0.00344339C16.6485 0.0181917 16.5397 0.079496 16.4653 0.174015L9.77033 8.76402C9.73583 8.81011 9.69179 8.84821 9.64122 8.87572C9.59064 8.90323 9.53473 8.9195 9.47729 8.92342C9.41985 8.92734 9.36224 8.91881 9.3084 8.89842C9.25456 8.87802 9.20576 8.84625 9.16533 8.80527L8.71783 8.39902C8.67758 8.35777 8.62888 8.32573 8.57506 8.30512C8.52125 8.2845 8.46361 8.2758 8.40611 8.2796C8.34861 8.2834 8.29261 8.29962 8.24198 8.32714C8.19135 8.35466 8.14729 8.39283 8.11283 8.43902L7.64033 9.04277C7.56623 9.14244 7.53006 9.26525 7.53831 9.38918C7.54656 9.5131 7.59868 9.63004 7.68533 9.71902L9.33533 11.3015C9.51408 11.4765 9.78658 11.4578 9.94033 11.2603L17.7803 1.20027C17.8542 1.10465 17.8873 0.983679 17.8723 0.86377C17.8573 0.743861 17.7955 0.634753 17.7003 0.560266ZM12.5753 0.560266L11.9778 0.0952655C11.8826 0.0216963 11.7623 -0.0113049 11.6429 0.00344339C11.5235 0.0181917 11.4147 0.079496 11.3403 0.174015L4.64533 8.76402C4.61083 8.81011 4.56679 8.84821 4.51622 8.87572C4.46564 8.90323 4.40973 8.9195 4.35229 8.92342C4.29485 8.92734 4.23724 8.91881 4.1834 8.89842C4.12956 8.87802 4.08076 8.84625 4.04033 8.80527L1.30158 6.12652C1.21493 6.0427 1.0987 5.99647 0.97816 5.99787C0.857616 5.99928 0.742498 6.0482 0.657827 6.13402L0.129077 6.67527C0.0450318 6.76154 -0.00137487 6.87763 3.10374e-05 6.99807C0.00143694 7.11851 0.0505407 7.23347 0.136577 7.31777L4.20908 11.299C4.38783 11.474 4.66033 11.4553 4.81408 11.2578L12.6541 1.19777C12.7276 1.10258 12.7606 0.9822 12.7459 0.862809C12.7311 0.743418 12.6698 0.634682 12.5753 0.560266Z"
                                    fill="#4FC3F7"
                                />
                            </svg>
                        </div>

                        <div className="info">
                            <div className="label">
                                <span>ERROS</span>
                                <b>{campanha.totalPorStatus?.erros}</b>

                                {campanha.totalPorStatus?.erros > 0 && (
                                    <i className="down">
                                        <svg
                                            width="8"
                                            height="8"
                                            viewBox="0 0 8 8"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M7.5004 0.3125C7.5004 0.22962 7.46747 0.150134 7.40887 0.0915292C7.35026 0.0329241 7.27078 0 7.1879 0H3.43789C3.35501 0 3.27553 0.0329241 3.21692 0.0915292C3.15832 0.150134 3.12539 0.22962 3.12539 0.3125C3.12539 0.39538 3.15832 0.474866 3.21692 0.533471C3.27553 0.592076 3.35501 0.625 3.43789 0.625H6.43352L0.0916447 6.96625C0.0625897 6.99531 0.0395422 7.0298 0.0238177 7.06776C0.00809329 7.10572 0 7.14641 0 7.1875C0 7.22859 0.00809329 7.26928 0.0238177 7.30724C0.0395422 7.3452 0.0625897 7.37969 0.0916447 7.40875C0.1207 7.43781 0.155193 7.46085 0.193155 7.47658C0.231117 7.4923 0.271805 7.50039 0.312895 7.50039C0.353985 7.50039 0.394672 7.4923 0.432634 7.47658C0.470597 7.46085 0.50509 7.43781 0.534145 7.40875L6.8754 1.06687V4.0625C6.8754 4.14538 6.90832 4.22487 6.96692 4.28347C7.02553 4.34208 7.10502 4.375 7.1879 4.375C7.27078 4.375 7.35026 4.34208 7.40887 4.28347C7.46747 4.22487 7.5004 4.14538 7.5004 4.0625V0.3125Z"
                                                fill="#159300"
                                            />
                                        </svg>
                                        {Math.round(
                                            (campanha.totalPorStatus?.erros /
                                                campanha.totalPorStatus
                                                    ?.total) *
                                                100
                                        )}
                                        %
                                    </i>
                                )}
                            </div>

                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M18.75 15C18.75 14.2583 18.5301 13.5333 18.118 12.9166C17.706 12.2999 17.1203 11.8193 16.4351 11.5354C15.7498 11.2516 14.9958 11.1774 14.2684 11.322C13.541 11.4667 12.8728 11.8239 12.3484 12.3483C11.8239 12.8728 11.4668 13.541 11.3221 14.2684C11.1774 14.9958 11.2516 15.7498 11.5355 16.4351C11.8193 17.1203 12.2999 17.7059 12.9166 18.118C13.5333 18.5301 14.2583 18.75 15 18.75C15.9942 18.7488 16.9474 18.3534 17.6504 17.6504C18.3534 16.9474 18.7488 15.9942 18.75 15ZM17.5 15C17.4977 15.4449 17.3752 15.8808 17.1456 16.2619L13.7381 12.8544C14.1192 12.6248 14.5551 12.5024 15 12.5C15.6628 12.5008 16.2982 12.7645 16.7669 13.2331C17.2355 13.7018 17.4992 14.3372 17.5 15ZM12.5 15C12.5023 14.5551 12.6248 14.1192 12.8544 13.7381L16.2619 17.1456C15.8808 17.3752 15.4449 17.4976 15 17.5C14.3372 17.4992 13.7018 17.2355 13.2331 16.7668C12.7645 16.2982 12.5008 15.6628 12.5 15Z"
                                    fill="black"
                                />
                                <path
                                    d="M8.7501 1.25011C7.70866 1.24408 6.67737 1.45501 5.72193 1.86948C4.76649 2.28394 3.90785 2.89286 3.20069 3.65742C2.49352 4.42199 1.95335 5.32546 1.61457 6.31029C1.27579 7.29511 1.14583 8.33969 1.23296 9.3775C1.3201 10.4153 1.62243 11.4236 2.12068 12.3381C2.61894 13.2527 3.30221 14.0534 4.12696 14.6894C4.95172 15.3253 5.89988 15.7825 6.91105 16.0319C7.92222 16.2812 8.97425 16.3172 10.0001 16.1376V15.0001C10.0001 13.674 10.5269 12.4023 11.4646 11.4646C12.4023 10.5269 13.674 10.0001 15.0001 10.0001H16.1376C16.3266 8.92431 16.277 7.82012 15.9924 6.76557C15.7078 5.71103 15.195 4.73186 14.4904 3.89726C13.7857 3.06266 12.9064 2.39299 11.9145 1.93557C10.9226 1.47816 9.84237 1.24416 8.7501 1.25011ZM7.5001 11.6189L5.0001 9.11886L5.99385 8.12511L7.5001 9.63136L10.8814 6.25011L11.8751 7.24386L7.5001 11.6189Z"
                                    fill="black"
                                />
                            </svg>
                        </div>
                    </div>
                </div>

                <div className="column" style={{ width: 200, minWidth: 200 }}>
                    {load === false && (
                        <PieChart
                            data={campanha.chart}
                            label={({ dataEntry }) =>
                                `${Math.round(dataEntry.percentage)} %`
                            }
                            animate={true}
                            labelStyle={{
                                fontSize: '5px',
                            }}
                        />
                    )}
                </div>
            </div>

            <table className="table-default">
                <thead>
                    <tr>
                        <th width="200">Campanha</th>
                        <th>Origem</th>
                        <th>Destinatário</th>
                        <th>Mensagem</th>
                        {campanha.tipoDeEnvio === 'Voz' && <th>Tentativas</th>}
                        <th>Status mensagem</th>
                        <th width="180">Data de agendamento</th>
                        <th>Data de envio</th>
                    </tr>
                </thead>

                <tbody>
                    {load === false &&
                        data.docs.map((row, key) => (
                            <tr
                                style={{
                                    backgroundColor: row.campaign
                                        ? ''
                                        : 'rgba(255,0,0,.03)',
                                }}
                                key={key}
                            >
                                <td>
                                    {row.campaign
                                        ? row.campaign.nome
                                        : 'Envio Teste'}
                                </td>
                                <td>{row.tipoDeEnvio}</td>
                                <td>
                                    {row.telefone} {row.email}
                                </td>
                                <td>
                                    {row.tipoDeEnvio === 'Email' && (
                                        <Modal
                                            title="Conteúdo e-mail"
                                            content={
                                                <button className="button-zig column secondary">
                                                    <i
                                                        style={{
                                                            marginRight: 5,
                                                        }}
                                                        class="fa fa-code"
                                                    ></i>{' '}
                                                    Conteúdo HTML
                                                </button>
                                            }
                                        >
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: row.mensagem,
                                                }}
                                            />
                                        </Modal>
                                    )}

                                    {row.tipoDeEnvio === 'Voz' && (
                                        <audio controls>
                                            <source source={row.mensagem} />
                                        </audio>
                                    )}

                                    {row.tipoDeEnvio !== 'Voz' &&
                                        row.tipoDeEnvio !== 'Email' &&
                                        row.mensagem}
                                </td>
                                {campanha.tipoDeEnvio === 'Voz' && (
                                    <td>{row.relatorioDeVoz.length}</td>
                                )}
                                <td style={{ whiteSpace: 'nowrap' }}>
                                    {row.statusPadrao.replace('Leitura', '')}

                                    {row.statusPadrao === 'Em Fila' && (
                                        <svg
                                            className="status-check"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 16 15"
                                            width="16"
                                            height="15"
                                        >
                                            <path
                                                fill="currentColor"
                                                d="M9.75 7.713H8.244V5.359a.5.5 0 0 0-.5-.5H7.65a.5.5 0 0 0-.5.5v2.947a.5.5 0 0 0 .5.5h.094l.003-.001.003.002h2a.5.5 0 0 0 .5-.5v-.094a.5.5 0 0 0-.5-.5zm0-5.263h-3.5c-1.82 0-3.3 1.48-3.3 3.3v3.5c0 1.82 1.48 3.3 3.3 3.3h3.5c1.82 0 3.3-1.48 3.3-3.3v-3.5c0-1.82-1.48-3.3-3.3-3.3zm2 6.8a2 2 0 0 1-2 2h-3.5a2 2 0 0 1-2-2v-3.5a2 2 0 0 1 2-2h3.5a2 2 0 0 1 2 2v3.5z"
                                            ></path>
                                        </svg>
                                    )}
                                    {row.statusPadrao === 'Enviado' && (
                                        <svg
                                            className="status-check"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 16 15"
                                            width="16"
                                            height="15"
                                        >
                                            <path
                                                fill="currentColor"
                                                d="M10.91 3.316l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"
                                            ></path>
                                        </svg>
                                    )}
                                    {row.statusPadrao === 'Recebido' && (
                                        <svg
                                            className="status-check"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 16 15"
                                            width="16"
                                            height="15"
                                        >
                                            <path
                                                fill="currentColor"
                                                d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.879a.32.32 0 0 1-.484.033l-.358-.325a.319.319 0 0 0-.484.032l-.378.483a.418.418 0 0 0 .036.541l1.32 1.266c.143.14.361.125.484-.033l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"
                                            ></path>
                                        </svg>
                                    )}
                                    {row.statusPadrao ===
                                        'Confirmado Leitura' && (
                                        <svg
                                            className="status-check"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 16 15"
                                            width="16"
                                            height="15"
                                        >
                                            <path
                                                fill="currentColor"
                                                d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.879a.32.32 0 0 1-.484.033l-.358-.325a.319.319 0 0 0-.484.032l-.378.483a.418.418 0 0 0 .036.541l1.32 1.266c.143.14.361.125.484-.033l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"
                                                fill="#4fc3f7"
                                            ></path>
                                        </svg>
                                    )}
                                </td>
                                <td>
                                    {datesFormater.dateBR(row.dataAgendada)}
                                </td>
                                <td style={{ whiteSpace: 'nowrap' }}>
                                    {row.dataEnviada
                                        ? datesFormater.dateBR(row.dataEnviada)
                                        : 'Pendente'}
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>

            <div className="tfoot">
                <Paginator
                    range={3}
                    totalPage={data.pages}
                    setPage={setPage}
                    page={page}
                    totalDocs={data.total}
                />
            </div>
        </div>
    );
}

import React, { useEffect, useState } from 'react'
import Datetime from 'react-datetime'
import InputMask from 'react-input-mask'
import "react-datetime/css/react-datetime.css"
import br from 'date-fns/locale/pt-BR'
import moment from 'moment'
import 'moment/locale/pt-br'

const Send = (props) => {

    const [load, setLoad] = useState(true)

    const [typeSend, setTypeSend] = useState('agora')

    async function handleOnSave(e)
    {

        e.preventDefault()
        
        props.handleOnSave()
        
    }

    useEffect(() => {

        props.handleOnChange( 'dataEnvio', '' )

    }, [typeSend])

    return(
        <form className="step" onSubmit={handleOnSave} style={{display: props.step === props.num ? 'block' : 'none'}}>

            <h2>{props.nome}</h2>
            <p>Selecione a forma que deseja enviar sua campanha.</p>

            <div className="separator">

                <div className="input">

                    <div className="typesend">
                        <button type="button" className={typeSend === 'agendar' ? 'active' : ''} onClick={() => setTypeSend('agendar')}>Quero agendar os envios</button>
                        <button type="button" className={typeSend !== 'agendar' ? 'active': ''} onClick={() => setTypeSend('agora')}>Quero enviar agora</button>
                    </div>

                    { typeSend === 'agendar' &&
                    <div>

                        <label>
                            <Datetime
                                inputProps={
                                    {
                                        placeholder: "Data de envio"
                                    }
                                }
                                onChange={(e) => props.handleOnChange( 'dataEnvio', e._d )}
                                className="input-datetime" 
                                locale="br"
                            />
                        </label>

                        <div style={{fontSize: '13px', marginTop: 10, color: '#333'}}>Selecione a data e hora que a campanha será inicializada.<br/>O término da campanha se dará ao final dos disparos</div>

                    </div>
                    }

                    <div className="buttons">

                        <button>{props.load ? <i className="fa fa-spin fa-spinner"></i> : 'Concluir'}</button>
                        { props.step !== 1 && <button onClick={() => props.setStep( (props.prev) )} type="button">Passo anterior</button>}
                        
                    </div>
                </div>

            </div>

        </form>
    )

}

export default Send
import React, { useEffect, useState } from 'react';
import { api } from '../../Api/app';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import messageStore from '../../Store/MessageStore';
import PreviewTemplate from '../../Components/TemplateCreation/PreviewTemplate/PreviewTemplate';
import H1Page from '../../Components/Layout/H1Page';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import IconButton from '../../Components/Icon/IconButton';
import datesFormater from '../../Helper/DatesFormater';
import Paginator from '../../Components/Paginator/Paginator';

import './Analytics.scss';
import Modal from '../../Components/Modal/Modal';
import Answers from '../../Components/Modal/Answers';

export default function Envios(props) {
    const [load, setLoad] = useState(false);

    const [campaigns, setCampaigns] = useState([]);

    const [data, setData] = useState({
        docs: [],
    });

    const [page, setPage] = useState(1);

    const [id, setId] = useState(
        props.match.params.id ? props.match.params.id : ''
    );

    const [status, setStatus] = useState('');

    const [dataInicial, setDataInicial] = useState('');

    const [dataFinal, setDataFinal] = useState('');

    const [tipoDeEnvio, setTipoDeEnvio] = useState('');

    useEffect(() => {
        api.get('campaigns').then((response) => {
            setCampaigns(response.data.docs);
        });
    }, [props]);

    useEffect(() => {
        getData();
    }, [page, id, status, dataInicial, dataFinal, tipoDeEnvio]);

    async function getData() {
        try {
            const url =
                'queues?campaignId=' +
                id +
                '&tipoDeEnvio=' +
                tipoDeEnvio +
                '&status=' +
                status +
                '&dataInicial=' +
                dataInicial +
                '&dataFinal=' +
                dataFinal +
                '&page=' +
                page;
            const response = await api.get(url);

            console.log(url, response.data);

            setData({ ...response.data });

            setLoad(false);
        } catch (e) {
            setLoad(false);
        }
    }

    return (
        <div id="analytics" className="page">
            <div className="h1-button">
                <H1Page nome={'Analytics'} />
            </div>

            <div className="filter-page">
                <label>
                    <select value={id} onChange={(e) => setId(e.target.value)}>
                        <option value="">Selecione uma campanha</option>
                        {campaigns.map((row, key) => (
                            <option value={row._id} key={key}>
                                {row.nome}
                            </option>
                        ))}
                    </select>
                </label>

                <label>
                    <select
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                    >
                        <option value="">Selecione um status</option>
                        <option>Em fila</option>
                        <option>Enviado</option>
                        <option>Recebido</option>
                        <option>Confirmado Leitura</option>
                        <option>Erro</option>
                        <option>Cancelado</option>
                    </select>
                </label>

                <label>
                    <select
                        value={tipoDeEnvio}
                        onChange={(e) => setTipoDeEnvio(e.target.value)}
                    >
                        <option value="">Selecione uma origem</option>
                        <option>Whatsapp</option>
                        <option>SMS</option>
                        <option>Email</option>
                        <option>Voz</option>
                    </select>
                </label>

                <label>
                    <input
                        onChange={(e) => setDataInicial(e.target.value)}
                        type="date"
                        name="dataInicial"
                    />
                </label>

                <label>
                    <input
                        onChange={(e) => setDataFinal(e.target.value)}
                        type="date"
                        name="dataFinal"
                    />
                </label>
            </div>

            <table className="table-default">
                <thead>
                    <tr>
                        <th width="200">Campanha</th>
                        <th>Origem</th>
                        <th>Destinatário</th>
                        <th>Mensagem</th>
                        <th>Status mensagem</th>
                        <th width="180">Data de agendamento</th>
                        <th>Data de envio</th>
                    </tr>
                </thead>

                <tbody>
                    {load === false &&
                        data.docs.map((row, key) => (
                            <tr
                                style={{
                                    backgroundColor: row.campaign
                                        ? ''
                                        : 'rgba(255,0,0,.03)',
                                }}
                                key={key}
                            >
                                <td>
                                    {row.campaign
                                        ? row.campaign.nome
                                        : 'Envio Teste'}
                                </td>
                                <td>{row.tipoDeEnvio}</td>
                                <td>
                                    {row.telefone} {row.email}
                                </td>
                                <td>
                                    {row.tipoDeEnvio === 'Email' && (
                                        <Modal
                                            title="Conteúdo e-mail"
                                            content={
                                                <button className="button-zig column secondary">
                                                    <i
                                                        style={{
                                                            marginRight: 5,
                                                        }}
                                                        class="fa fa-code"
                                                    ></i>{' '}
                                                    Conteúdo HTML
                                                </button>
                                            }
                                        >
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: row.mensagem,
                                                }}
                                            />
                                        </Modal>
                                    )}

                                    {row.tipoDeEnvio === 'Voz' && (
                                        <audio controls>
                                            <source source={row.mensagem} />
                                        </audio>
                                    )}

                                    {row.tipoDeEnvio !== 'Voz' &&
                                        row.tipoDeEnvio !== 'Email' &&
                                        row.mensagem}
                                </td>
                                <td style={{ whiteSpace: 'nowrap' }}>
                                    {row.statusPadrao}

                                    {row.statusPadrao === 'Em Fila' && (
                                        <svg
                                            className="status-check"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 16 15"
                                            width="16"
                                            height="15"
                                        >
                                            <path
                                                fill="currentColor"
                                                d="M9.75 7.713H8.244V5.359a.5.5 0 0 0-.5-.5H7.65a.5.5 0 0 0-.5.5v2.947a.5.5 0 0 0 .5.5h.094l.003-.001.003.002h2a.5.5 0 0 0 .5-.5v-.094a.5.5 0 0 0-.5-.5zm0-5.263h-3.5c-1.82 0-3.3 1.48-3.3 3.3v3.5c0 1.82 1.48 3.3 3.3 3.3h3.5c1.82 0 3.3-1.48 3.3-3.3v-3.5c0-1.82-1.48-3.3-3.3-3.3zm2 6.8a2 2 0 0 1-2 2h-3.5a2 2 0 0 1-2-2v-3.5a2 2 0 0 1 2-2h3.5a2 2 0 0 1 2 2v3.5z"
                                            ></path>
                                        </svg>
                                    )}
                                    {row.statusPadrao === 'Enviado' && (
                                        <svg
                                            className="status-check"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 16 15"
                                            width="16"
                                            height="15"
                                        >
                                            <path
                                                fill="currentColor"
                                                d="M10.91 3.316l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"
                                            ></path>
                                        </svg>
                                    )}
                                    {row.statusPadrao === 'Recebido' && (
                                        <svg
                                            className="status-check"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 16 15"
                                            width="16"
                                            height="15"
                                        >
                                            <path
                                                fill="currentColor"
                                                d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.879a.32.32 0 0 1-.484.033l-.358-.325a.319.319 0 0 0-.484.032l-.378.483a.418.418 0 0 0 .036.541l1.32 1.266c.143.14.361.125.484-.033l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"
                                            ></path>
                                        </svg>
                                    )}
                                    {row.statusPadrao ===
                                        'Confirmado Leitura' && (
                                        <svg
                                            className="status-check"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 16 15"
                                            width="16"
                                            height="15"
                                        >
                                            <path
                                                fill="currentColor"
                                                d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.879a.32.32 0 0 1-.484.033l-.358-.325a.319.319 0 0 0-.484.032l-.378.483a.418.418 0 0 0 .036.541l1.32 1.266c.143.14.361.125.484-.033l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"
                                                fill="#4fc3f7"
                                            ></path>
                                        </svg>
                                    )}
                                </td>
                                <td>
                                    {datesFormater.dateBR(row.dataAgendada)}
                                </td>
                                <td style={{ whiteSpace: 'nowrap' }}>
                                    {row.dataEnviada
                                        ? datesFormater.dateBR(row.dataEnviada)
                                        : 'Pendente'}
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>

            <div className="tfoot">
                <Paginator
                    range={3}
                    totalPage={data.pages}
                    setPage={setPage}
                    page={page}
                />
            </div>

            <div style={{ padding: '0px 10px', marginBottom: 20 }}>
                Total de <b>{data.total}</b> registros
            </div>
        </div>
    );
}

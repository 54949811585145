import React, { useEffect, useState } from 'react';
import ReactFlow, { ReactFlowProvider, Background } from 'react-flow-renderer';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { api } from '../../Api/app';
import MenuConditions from '../../Components/Chatbot/MenuConditions';
import MenuFixed from '../../Components/Chatbot/MenuFixed';
import MenuMessages from '../../Components/Chatbot/MenuMessages';
import H1Page from '../../Components/Layout/H1Page';
import messageStore from '../../Store/MessageStore';

import './Chatbot.scss';
import Modelchatbot from './ModelChatbot';

export default function Chatbot(props) {
    const id = props.match.params.id;

    const history = useHistory();

    const [tabEdition, setTabEdition] = useState('conteudo');

    const selectedDefault = {
        params: {
            nameDialog: 'Diálogo',
            conditions: [],
            messages: [],
            messageUser: {},
            exception: 2,
        },
    };

    const [name, setName] = useState('');

    const [palavrasAtendimento, setPalavrasAtendimento] = useState([]);

    const [mensagemDeEntrada, setMensagemDeEntrada] = useState([]);

    const [elements, setElements] = useState([]);

    const [selected, setSelected] = useState(selectedDefault);

    const [edition, setEdition] = useState(false);

    const [selectedUpdate, setSelectedUpdate] = useState(false);

    const [loadSelected, setLoadSelected] = useState(false);

    const Model = new Modelchatbot(
        elements,
        setElements,
        setSelected,
        setSelectedUpdate
    );

    useEffect(() => {
        setTabEdition('conteudo');
        setLoadSelected(true);

        setTimeout(() => {
            setLoadSelected(false);
        }, 400);
    }, [selectedUpdate]);

    useEffect(() => {
        getData();
    }, [props]);

    useEffect(() => {
        if (selected.id) setEdition(true);

        if (
            selected.typeDialog === 'end' ||
            selected.typeDialog === 'attendance'
        )
            setTabEdition('conteudo');
    }, [selected, edition]);

    async function getData() {
        const response = await api.get('chatbots/' + id);

        setName(response.data.nome);
        setPalavrasAtendimento(response.data.atendimento);
        setMensagemDeEntrada(response.data.mensagemDeEntrada);

        if (id) {
            Model.setElementsLoad(JSON.parse(response.data.estrutura));
        } else {
            Model.setElementsDefault();
        }
    }

    async function handleOnSave(msg = false) {
        try {
            const response = await api.put('chatbots/' + id, {
                nome: name,
                estrutura: JSON.stringify(elements),
                atendimento: palavrasAtendimento,
                mensagemDeEntrada: msg === false ? mensagemDeEntrada : msg,
            });

            if (msg === false) {
                messageStore.addSuccess('Chatbot atualizado com sucesso.');
            }

            if (props.location.state) {
                history.push({
                    pathname: '/nova-campanha/' + props.location.state.send,
                    state: props.location.state,
                });
            }
        } catch (err) {
            console.log(err);
            messageStore.addError('Erro ao salvar chatbot.');
        }
    }

    function onChangeNameDialog(value) {
        selected.params.nameDialog = value;
        setSelected({ ...selected });
        Model.onEditElement(selected);
    }

    function onSubmitEditing(e) {
        setEdition(false);
        e.preventDefault();
    }

    function handleElementRemove(params) {
        try {
            console.log(selected);

            if (
                selected.typeDialog === 'start' ||
                selected.typeDialog === 'attendance' ||
                selected.typeDialog === 'end'
            )
                throw 'Você não pode remover esse diálogo, ele é obrigatório no fluxo.';

            params.map((row, key) => {
                if (row.id === selected.id) {
                    setSelected(selectedDefault);
                    setEdition(false);
                }
            });

            Model.handleOnRemove(params);
        } catch (err) {
            messageStore.addError(err);
        }
    }

    return (
        <div id="chatbot">
            <H1Page
                state={props.location.state}
                nome={
                    <input
                        type="text"
                        onChange={(e) => setName(e.target.value)}
                        defaultValue={name}
                    />
                }
            />

            <div className="flex">
                <MenuFixed
                    Model={Model}
                    handleOnSave={handleOnSave}
                    name={name}
                    setName={setName}
                    palavrasAtendimento={palavrasAtendimento}
                    setPalavrasAtendimento={setPalavrasAtendimento}
                    id={id}
                    mensagemDeEntrada={mensagemDeEntrada}
                    setMensagemDeEntrada={setMensagemDeEntrada}
                />

                <ReactFlowProvider>
                    <ReactFlow
                        id={'react-flow'}
                        elements={elements}
                        onElementsRemove={(params) =>
                            handleElementRemove(params)
                        }
                        onConnect={(params) => Model.onConnect(params)}
                        onNodeDrag={(event, element) => Model.onMove(element)}
                        snapToGrid={true}
                        snapGrid={[5, 5]}
                    >
                        <Background
                            variant="dots"
                            gap={15}
                            size={1}
                            color={'#CCC'}
                        />
                    </ReactFlow>
                </ReactFlowProvider>

                <form
                    onSubmit={onSubmitEditing}
                    className={`edit-dialog ${edition ? 'opened' : 'closed'}`}
                >
                    <div className="relative">
                        <div className="head">
                            <div className="title">
                                <input
                                    onChange={(e) =>
                                        onChangeNameDialog(e.target.value)
                                    }
                                    value={selected.params.nameDialog}
                                />

                                <button
                                    onClick={() => {
                                        setEdition(false);
                                        setSelected(selectedDefault);
                                    }}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="1em"
                                        height="1em"
                                        preserveAspectRatio="xMidYMid meet"
                                        viewBox="0 0 16 16"
                                    >
                                        <g fill="#626262">
                                            <path
                                                clip-rule="evenodd"
                                                d="M8 8.707l3.646 3.647l.708-.707L8.707 8l3.647-3.646l-.707-.708L8 7.293L4.354 3.646l-.707.708L7.293 8l-3.646 3.646l.707.708L8 8.707z"
                                            />
                                        </g>
                                        <rect
                                            x="0"
                                            y="0"
                                            width="16"
                                            height="16"
                                            fill="rgba(0, 0, 0, 0)"
                                        />
                                    </svg>
                                </button>
                            </div>

                            <ul>
                                <li
                                    className={`${
                                        tabEdition === 'conteudo'
                                            ? 'active'
                                            : 'false'
                                    }`}
                                    onClick={() => setTabEdition('conteudo')}
                                >
                                    Conteúdo
                                </li>
                                {selected.typeDialog !== 'attendance' && (
                                    <li
                                        className={`${
                                            tabEdition === 'condicoes'
                                                ? 'active'
                                                : 'false'
                                        }`}
                                        onClick={() =>
                                            setTabEdition('condicoes')
                                        }
                                    >
                                        Condições de saída
                                    </li>
                                )}
                            </ul>
                        </div>

                        <div className={`content ${tabEdition}`}>
                            <MenuMessages
                                Model={Model}
                                elements={elements}
                                selected={selected}
                                selectedUpdate={selectedUpdate}
                                setSelected={setSelected}
                                loadSelected={loadSelected}
                            />

                            <MenuConditions
                                Model={Model}
                                elements={elements}
                                selected={selected}
                                selectedUpdate={selectedUpdate}
                                setSelected={setSelected}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

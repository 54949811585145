import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { api } from '../../Api/app';
import FormDefault from '../../Components/Form/FormDefault';
import InputDefault from '../../Components/Form/InputDefault';
import InputRow from '../../Components/Form/InputRow';
import IconButton from '../../Components/Icon/IconButton';
import H1Page from '../../Components/Layout/H1Page';
import Paginator from '../../Components/Paginator/Paginator';
import { Button } from '../../Components/TemplateCreation/Widgets/Button/Button';
import messageStore from '../../Store/MessageStore';
import Select from 'react-select';

//scss
import './administracao.scss';

export default function UsuariosForm(props) {
    const id = props.match.params.id;

    const accountId = props.match.params.accountId;

    const [load, setLoad] = useState(true);
    const [data, setData] = useState({
        accountId: accountId,
    });

    const [brokers, setBrokers] = useState([]);

    const [page, setPage] = useState(1);

    const history = useHistory();

    const [permissoes, setPermissoes] = useState([]);

    useEffect(() => {
        getData();
    }, [props]);

    async function getData() {
        try {
            const responseBrokers = await api.get('brokers');

            setBrokers([...responseBrokers.data.docs]);

            if (!accountId) {
                const response = await api.get('users/' + id);
                setData({ ...response.data });
                response.data.permissoes.map((row, key) => {
                    permissoes.push({
                        label: row,
                        value: row,
                    });
                });
            }
            setLoad(false);
        } catch (e) {
            console.log(e);
            messageStore.addError('Erro ao listar.');
        }
    }

    function handleOnChange(name, value) {
        if (value === 'true') value = true;
        if (value === 'false') value = false;

        data[name] = value;
        setData({ ...data });

        console.log(data);
    }

    async function handleUpload(e) {
        if (e.target.files.length) {
            var formData = new FormData();
            formData.append('file', e.target.files[0], e.target.files[0].name);

            const response = await api.put('profile/upload', formData);

            data.foto = response.data.link;

            setData({ ...data });

            console.log(response.data);
        }
    }

    async function handleOnSave(e) {
        try {
            e.preventDefault();

            if (!accountId) {
                data.permissoes = [];

                permissoes.map((row, key) => {
                    data.permissoes.push(row.label);
                });
                const responsePut = await api.put('users/' + id, data);
                if (responsePut.data.error === true)
                    throw responsePut.data.message;
                if (!responsePut.data.params)
                    throw 'Todos os dados são obrigatórios';
                console.log(responsePut);
            }

            if (accountId) {
                const responsePost = await api.post('users', data);
                if (responsePost.data.error === true)
                    throw responsePost.data.message;
                if (!responsePost.data.result)
                    throw 'Todos os dados são obrigatórios';
                console.log(responsePost);
            }

            messageStore.addSuccess('Conta atualizada com sucesso.');

            history.goBack();
        } catch (e) {
            messageStore.addError(e);
        }
    }

    return (
        <div id="administracao" className="page">
            <H1Page nome="Gerenciar usuário" />

            <div className="row">
                <FormDefault onSubmit={handleOnSave}>
                    <h3>Imagem</h3>

                    <div className="change-photo">
                        <div
                            className="image"
                            style={{
                                backgroundImage: 'url(' + data.foto + ')',
                            }}
                        ></div>
                        <input
                            id="upload-button"
                            onChange={handleUpload}
                            type="file"
                            name="file"
                        />
                    </div>

                    {load === false && (
                        <InputRow>
                            <label className="input-default-zig">
                                <span>Permissões de chat</span>
                                <Select
                                    defaultValue={permissoes}
                                    placeholder="Selecionar canais"
                                    isMulti
                                    onChange={setPermissoes}
                                    options={[
                                        {
                                            value: 'whatsapp',
                                            label: 'Whatsapp',
                                        },
                                        {
                                            value: 'telegram',
                                            label: 'Telegram',
                                        },
                                        {
                                            value: 'facebook',
                                            label: 'Facebook',
                                        },
                                        {
                                            value: 'instagram',
                                            label: 'Instagram',
                                        },
                                    ]}
                                />
                            </label>
                        </InputRow>
                    )}

                    <InputRow>
                        <label class="input-default-zig">
                            <span>Administrador</span>
                            <select
                                onChange={(e) =>
                                    handleOnChange('isAdmin', e.target.value)
                                }
                                name="isAdmin"
                                value={data.isAdmin}
                            >
                                <option value={''}>Selecionar</option>
                                <option value={'false'}>Não</option>
                                <option value={'true'}>Sim</option>
                            </select>
                        </label>

                        <label class="input-default-zig">
                            <span>Status</span>
                            <select
                                onChange={(e) =>
                                    handleOnChange('isActive', e.target.value)
                                }
                                name="isActive"
                                value={data.isActive}
                            >
                                <option value={''}>Selecionar</option>
                                <option value={'false'}>Inativo</option>
                                <option value={'true'}>Ativo</option>
                            </select>
                        </label>
                    </InputRow>

                    <InputRow>
                        <InputDefault
                            title="Nome"
                            name="nome"
                            value={data.nome}
                            onChange={handleOnChange}
                        />
                        <InputDefault
                            title="E-mail"
                            name="email"
                            value={data.email}
                            onChange={handleOnChange}
                        />
                    </InputRow>

                    <InputRow>
                        <InputDefault
                            title="CPF"
                            name="cpf"
                            value={data.cpf}
                            onChange={handleOnChange}
                            mask={'999.999.999-99'}
                        />
                        <InputDefault
                            title="Data de nascimento"
                            name="nascimento"
                            value={data.nascimento}
                            onChange={handleOnChange}
                            mask={'99/99/9999'}
                        />
                    </InputRow>

                    <InputRow>
                        <InputDefault
                            title="Senha"
                            name="password"
                            type="password"
                            value={data.password}
                            onChange={handleOnChange}
                        />
                        <InputDefault
                            title="Confirmar senha"
                            name="passwordConfirm"
                            type="password"
                            value={data.passwordConfirm}
                            onChange={handleOnChange}
                        />
                    </InputRow>

                    <button type="submit">Salvar</button>
                </FormDefault>
            </div>
        </div>
    );
}

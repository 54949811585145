import React, { useEffect, useState } from 'react'
import { api, baseUrl } from '../../Api/app'
import H1Page from '../../Components/Layout/H1Page'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import Modal from '../../Components/Modal/Modal'
import Skeleton from 'react-loading-skeleton'
import IconButton from '../../Components/Icon/IconButton'
import CleanCSV from '../../Components/ContactList/CleanCSV'
import messageStore from '../../Store/MessageStore'

export default function Higienizacao(props){

    const history = useHistory()
    const [load, setLoad] = useState(true)
    const [data, setData] = useState([])

    const [progressWhatsapp, setProgressWhatsapp] = useState([])

    useEffect(() => {

        getData()

    }, [])

    async function getData()
    {

        setLoad(true)

        const response = await api.get('contact-imports/higienizar')

        setData([...response.data])

        response.data.map((row, key) => {
            progressWhatsapp[key] = {
                percent: 0,
                filename: row.filename
            }
        })

        setProgressWhatsapp([...progressWhatsapp])

        setLoad(false)

    }

    async function handleOnProgressWhatsapp(key, filename)
    {

        try {

            const response = await api.put('contact-imports/whatsapp-validar', {
                filename: filename
            })

            progressWhatsapp[key] = {
                percent: parseInt((response.data.total - response.data.totalPendente) / response.data.total * 100),
                filename: filename
            }
            setProgressWhatsapp([...progressWhatsapp])

            console.log(progressWhatsapp)

            if ( response.data.success == true ) {

                messageStore.addSuccess('Todos os números foram válidos com sucesso.')
                throw "Finalizado."

            }

            setTimeout(() => {
                handleOnProgressWhatsapp(key, filename)
            }, 4000)

        } catch ( err ) {

        }

    }

    return(
        
        <div className="page">

            <div className="h1-button">

                <H1Page nome={"Higienizar CSV"}/>

                <div className="buttons">
             
                    <Modal title="Higienizar novo arquivo" content={<button className="button-zig primary">
                        <IconButton icon="new"/>
                        <span>Higienizar novo arquivo</span>
                    </button>}>

                        <CleanCSV
                            onSubmit={getData}
                            open={true}
                        />

                    </Modal>
                </div>
                
            </div>

            <table className="table-default">

                <thead>
                    <tr>
                        <th>
                            Arquivo
                        </th>
                        <th style={{textAlign: 'right'}}>
                            Total contatos
                        </th>
                        <th style={{textAlign: 'right'}}>
                            Total contatos válidos
                        </th>
                        <th style={{textAlign: 'right'}}>
                            Total contatos mal formatados
                        </th>
                        <th style={{textAlign: 'right'}}>
                            Total contatos duplicados
                        </th>
                        <th width={10}></th>
                        <th width={10}></th>
                        <th width={10}></th>
                        <th width={10}></th>
                    </tr>
                </thead>

                <tbody>

                    { load === true && 
                        [0,1,2].map((row, key) => 
                            <tr key={key}>
                                <td colSpan={8}><Skeleton/></td>
                            </tr>
                        )
                    }

                    { load === false && data.map((row, key) => 
                        <tr key={key}>
                            <td>{row.nomeArquivo}</td>
                            <td align={'right'}>{row.total}</td>
                            <td align={'right'}>{row.validos}</td>
                            <td align={'right'}>{row.invalidos}</td>
                            <td align={'right'}>{row.duplicados}</td>
                            <td>
                                <button onClick={() => handleOnProgressWhatsapp(key, row.filename)} className="button-zig column full" style={{backgroundColor: 'rgba(green, .1)'}}>
                                    { progressWhatsapp[key].percent > 0 ? `Validando números (${progressWhatsapp[key]?.percent}%)` : 'Validar Whatsapp' }
                                </button>
                            </td>
                            <td>
                                <a target="_blank" href={baseUrl+'contact-imports/higienizar-download/'+row.filename+'?tipo=validos'} className="button-zig column full secondary">Download válidos</a>
                            </td>
                            <td>
                                <a target="_blank" href={baseUrl+'contact-imports/higienizar-download/'+row.filename+'?tipo=invalidos'} className="button-zig column full danger">Download mal formatados</a>
                            </td>
                            <td>
                                <a target="_blank" href={baseUrl+'contact-imports/higienizar-download/'+row.filename+'?tipo=duplicados'} className="button-zig column full neutral">Download duplicados</a>
                            </td>
                        </tr>
                    )}

                </tbody>

            </table>

        </div>

    )

}
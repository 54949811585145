import React from 'react';

import './Tooltip.scss';

export default function Tooltip({ children }) {
    return (
        <div className="tooltip">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false"
                width="1em"
                height="1em"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24"
            >
                <g fill="none">
                    <circle
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="#626262"
                        strokeWidth="2"
                    />
                    <path
                        d="M10 8.484C10.5 7.494 11 7 12 7c1.246 0 2 .989 2 1.978s-.5 1.483-2 2.473V13m0 3.5v.5"
                        stroke="#626262"
                        strokeWidth="2"
                        stroke-linecap="round"
                    />
                </g>
                <rect
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                    fill="rgba(0, 0, 0, 0)"
                />
            </svg>
            <div className="textTooltip">{children}</div>
        </div>
    );
}

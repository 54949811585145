import React, { useEffect, useState } from 'react'
import { api } from '../../Api/app'
import Skeleton from 'react-loading-skeleton'
import { Link } from 'react-router-dom'
import messageStore from '../../Store/MessageStore'
import PreviewTemplate from '../../Components/TemplateCreation/PreviewTemplate/PreviewTemplate'
import H1Page from '../../Components/Layout/H1Page'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

import IconButton from '../../Components/Icon/IconButton'
import datesFormater from '../../Helper/DatesFormater'
import EditList from '../../Components/ContactList/EditList'

export default function GestaoContatos(props){

    const [load, setLoad] = useState(true)
    const [data, setData] = useState([])

    const history = useHistory()
    
    useEffect(() => {

        getData()

    }, [props])

    async function handleNew()
    {



    }

    async function getData()
    {

        const response = await api.get('contactLists')
        setData([...response.data.docs])
        setLoad(false)

    }

    async function handleDelete(id)
    {

        const response = await api.delete('contactLists/'+id)

        getData()

    }

    async function handleRedirect(item)
    {

        history.push({
            pathname: '/gestao-contatos/'+item._id,
            state: { item }
        })

    }

    return(
        
        <div className="page">

            <div className="h1-button">

                <H1Page nome={"Minhas listas"}/>

                <div className="buttons">

                    <EditList send={props.send} className="full" callbackChangeColumns={() => {}} callbackNewList={() => getData()}>
                        <button type="button" className="button-zig primary">
                            <IconButton icon="new"/>
                                <span>Criar nova lista</span>
                        </button>
                    </EditList>

                </div>
                
            </div>

            <table className="table-default">

                <thead>
                    <tr>
                        <th colSpan={4}>
                            Lista
                        </th>
                    </tr>
                </thead>

                <tbody>
                    { load === true && 
                        [0,1,2].map((row, key) => 
                            <tr key={key}>
                                <td><Skeleton/></td>
                            </tr>
                        )
                    }
                    { load === false && data.map((row, key) => 
                        <tr key={key}>
                            <td width="100%">
                                <b>{row.lista}</b><br/>
                                <span>Criado em {datesFormater.dateBR(row.createdAt)}</span><br/>
                                <span>Por <b>{ row.user && row.user.nome }</b></span>
                            </td>
                            <td>
                                <a onClick={() => handleRedirect(row)} className="button-zig secondary">
                                    <IconButton icon="view"/>
                                    <span>Contatos</span>
                                </a>
                            </td>
                            <td>
                                <EditList nameOnly={true} send={row.tipo} callbackChangeColumns={() => {}} id={row._id} callbackChangeName={() => getData()}>
                                    <button type="button" className="button-zig primary">
                                        <IconButton icon="edit"/>
                                        <span>Editar</span>
                                    </button>
                                </EditList>
                            </td>
                            <td>
                                <a className="button-zig danger" onClick={() => messageStore.addConfirm('Deseja remover o template "'+row.lista+'"', () => handleDelete(row._id))}>
                                    <IconButton icon="del"/>
                                    <span>Remover</span>
                                </a>
                            </td>
                        </tr>
                    )}
                </tbody>

            </table>

        </div>

    )

}
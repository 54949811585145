  
import { db, firebase } from './index'
import Datetime from '../Helper/Datetime.js'
import nextId from 'react-id-generator'

const editMessages = {

    async getTime()
    {

        return firebase.firestore.FieldValue.serverTimestamp()

    },

    async setContato (
        user = {}, 
        accountId = '', 
        ultimaMensagem = {}
    ){
        
        db.collection(`u-${accountId}`).doc(user.id).set({
            totalOperador: 0,
            totalUsuario: 0,
            ultimaMensagem: ultimaMensagem,
            idMessages: 'm-'+accountId+'-'+user.id,
            nome: user.nome,
            email: user.email,
            telefone: user.telefone,
            chatbot: user.chatbot,
            criado: Datetime.today()+' - '+Datetime.hour(new Date()),
            active: {
                status: true,
            },
            operador: {}
        })
        
    },

    async editContato(
        accountId, 
        conversationId,
        ultimaMensagem,
        origem,
        chatbot,
        active,
        rating,
        operator
    ) {

        const getUser = await db.collection(`u-${accountId}`).doc(conversationId).get()
        const userCollection = getUser.data()

        if ( chatbot ) userCollection.chatbot = chatbot === 'false' ? false : chatbot

        if ( active ) userCollection.active = active

        if ( rating ) userCollection.avaliacao = rating

        if ( operator ) {
            userCollection.operador = operator
            userCollection.totalOperador = 0
            userCollection.chatbot = false
        }

        if ( ultimaMensagem ) {

            if ( origem == 'usuario' ) {
                userCollection.totalOperador = parseInt(userCollection.totalOperador) + 1
                userCollection.totalUsuario = 0
            } else if ( origem === 'operador' ) {
                userCollection.totalUsuario = parseInt(userCollection.totalUsuario) + 1
                userCollection.totalOperador = 0
            }

            userCollection.ultimaMensagem = ultimaMensagem

        }

        db.collection(`u-${accountId}`).doc(conversationId).set(userCollection)

        console.log('editContato', userCollection)

    },

    async editContatoJson(
        accountId, 
        conversationId,
        json,
    ) {

        console.log('editContatoJson', accountId, conversationId, json)
        db.collection(`u-${accountId}`).doc(conversationId).set(json)

    },

    async SendMessage(
        user = {},
        accountId = '', 
        message,
        messageType = 'text',
        origem = 'usuario',
        operator = {},
        chatbot = {},
        time
    ) {

        console.log('time', time)

        const newMessage = {
            mensagem: message,
            mensagemTipo: messageType,
            mensagemStatusUsuario: origem === 'usuario' ? 1 : 0,
            mensagemStatusOperador: origem === 'operador' ? 1 : 0,
            origem: origem,
            criado: Datetime.today()+' '+Datetime.hour(new Date()),
            time: time ? time : firebase.firestore.FieldValue.serverTimestamp(),
            operador: operator,
            chatbot: chatbot,
            idMessage: Datetime.today()+'-'+Datetime.hour(new Date()).replace(':','-')+nextId()
        }

        db.collection(`m-${accountId}-${user.id}`).add(newMessage)
        this.editContato(accountId, user.id, newMessage, origem )

    },

    async editStatusMessage(
        accountId,
        conversationId,
        key,
        json,
        origem = 'usuario'
    ) {

        if ( origem === 'usuario' ) {
            json.mensagemStatusUsuario = 1
        } else {
            json.mensagemStatusOperador = 1
        }

        console.log('editStatusMessage', json)
        db.collection(`m-${accountId}-${conversationId}`).doc(key).set(json)

    },

}
  
  export default editMessages
import { useEffect, useState } from 'react'
import React from 'react'

const Paginator = ({setPage, page, totalPage = 0, range, totalDocs}) => {

    const [data, setData] = useState([])

    useEffect(() => {

        const newData = []

        for (var i = (page - range); i < (page + range); i++) {

            try {

                if ( i <= 0 ) throw i
                if ( i > totalPage ) throw i

                newData.push(i)

            } catch ( e ){


            }

        }

        setData([...newData])

    }, [page, totalPage])

    return(
        <>
        { data.length > 0 &&
            <ul className="paginator">
                <li>
                    <button className={page === 1 ? 'button-zig primary' : 'button-zig neutral'} type="button" onClick={() => page != 1 && setPage(1)}>Primeira</button>
                </li>
                {data.map((row, key) =>
                    <li key={key}>
                        <button className={page === row ? 'button-zig primary' : 'button-zig neutral'} type="button" onClick={() => row != page && setPage(row)}>{row}</button>
                    </li>
                )}
                <li>
                    <button className={page === totalPage ? 'button-zig primary' : 'button-zig neutral'} type="button" onClick={() => page != totalPage && setPage(totalPage)}>Ultíma</button>
                </li>

                <li>
                    <p>{totalDocs} registros</p>
                </li>
            </ul>
        }
        </>
    )

}

export default Paginator
import React, {useEffect, useState} from 'react'
import { api, baseUrl } from '../../Api/app'
import messageStore from '../../Store/MessageStore'

import "./ContactList.scss"

export default function CleanCSV(props)
{

    const open = props.open
    const [load, setLoad] = useState(false)
    const [progress, setProgress] = useState(false)
    const [filename, setFilename] = useState('')

    const [page, setPage] = useState(0)
    const [total, setTotal] = useState(0)

    const [messageFinal, setMessageFinal] = useState(null)
    const [linkCsv, setLinkCsv] = useState(null)

    const [lists, setLists] = useState([])
    const [contactListId, setContactListId] = useState(null)

    const [nameArquivo, setNameArquivo] = useState('')

    useEffect(() => {

        getLists()

    }, [])

    async function getLists()
    {

        const response = await api.get('contactLists')

        setLists([...response.data.docs])

    }

    async function handleUpload(e)
    {

        try {

            setLoad(true)

            setNameArquivo(e.target.files[0].name)
            setMessageFinal(null)
            setLinkCsv(null)

            var formData = new FormData()
                formData.append("file", e.target.files[0], e.target.files[0].name)

            let response = await api.post('contact-imports/upload', formData)

            console.log( response.data )

            setFilename( response.data.filename )
            handleSplit( response.data.filename )
            setLoad( false )

        } catch ( e ) {

            console.log( e )
            messageStore.addError('Houve um erro ao ler o arquivo.')
            setLoad(false)
            setNameArquivo('')

        }
        
    }

    async function handleSplit(filename)
    {

        try {

            let response = await api.post('contact-imports/split', {
                filename: filename
            })

            console.log('SPLIT', response.data)

            setTotal( response.data.total )

        } catch ( e ) {

            return 'Houve um erro ao configurar o arquivo.'

        }

    }

    async function handleProcess( pageRefresh = 1 )
    {

        try {

            const data = {
                filename: filename,
                nomeArquivo: nameArquivo,
                contactListId: contactListId,
                page: pageRefresh,
                total: total,
            }

            setProgress(true)

            let response = await api.post('contact-imports/higienizar', data)

            console.log('PROCCESS', response.data)

            if ( response.data.page > 1 && response.data.total >= response.data.page ) {

                handleProcess( response.data.page )
                setPage(response.data.page)

            } else {

                if ( contactListId ) {

                    const transferer = await api.post('contact-imports/higienizar-vincular', {
                        filename: filename,
                        contactListId: contactListId
                    })

                    messageStore.addSuccess('Higinização completa.')
                    props.onSubmit('clean')
                    setMessageFinal(response.data.message)
                    setLinkCsv(response.data.link)
                    setPage(0)
                    setProgress(false)
                    
                } else {

                    messageStore.addSuccess('Higinização completa.')

                    props.onSubmit('clean')
                    setMessageFinal(response.data.message)
                    setLinkCsv(response.data.link)
                    setPage(0)
                    setProgress(false)
                    
                }


            }

            console.log( response.data )

        } catch ( e ) {

            messageStore.addError( e )

        }

    }

    return(

        <div id="higienizacao" style={{display: open ? 'block' : 'none'}}>

            <div className="overflow">

                <table className="table-default">
                
                    <thead>
                        <tr>
                            <th style={{whiteSpace: 'nowrap'}}>
                                Selecione um arquivo (CSV/TXT)
                            </th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td colSpan={2}>
                                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"><path d="M464 720a48 48 0 1 0 96 0a48 48 0 1 0-96 0zm16-304v184c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V416c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8zm475.7 440l-416-720c-6.2-10.7-16.9-16-27.7-16s-21.6 5.3-27.7 16l-416 720C56 877.4 71.4 904 96 904h832c24.6 0 40-26.6 27.7-48zm-783.5-27.9L512 239.9l339.8 588.2H172.2z" fill="#626262"/><rect x="0" y="0" width="1024" height="1024" fill="rgba(0, 0, 0, 0)" /></svg> É <b>obrigatório</b> que a primeira coluna seja <b>celular</b> ou <b>e-mail</b>, o restante é liberado.
                            </td>
                        </tr>

                        { props.id ? 
                            <tr>
                                <td width={20}>
                                    Importar contatos válidos
                                </td>
                                <td>
                                    <input type="checkbox" value={contactListId} onChange={(e) => setContactListId( contactListId ? null : props.id )}/>
                                </td>
                            </tr>
                        :
                            <tr>
                                <td width={20}>
                                    Lista
                                </td>
                                <td>
                                    <select onChange={(e) => setContactListId(e.target.value)}>
                                        <option>Selecionar lista</option>
                                        { lists.map((row, key) =>
                                        <option value={row._id}>{row.lista} - {row.tipo}</option>
                                        )}
                                    </select>
                                </td>
                            </tr>
                        }
                        <tr>
                            <td style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                                Arquivo*
                            </td>
                            <td>
                                <input id="upload-button" onChange={handleUpload} type="file" name="file"/>
                                { load === true && <i className="fa fa-spin fa-spinner fa-2x" style={{marginLeft: 10}}></i> }
                            </td>
                        </tr>
                        { linkCsv && 
                            <>
                                <tr>
                                    <td colSpan={2}>
                                        <div className="message-clean">
                                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"><path d="M464 720a48 48 0 1 0 96 0a48 48 0 1 0-96 0zm16-304v184c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V416c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8zm475.7 440l-416-720c-6.2-10.7-16.9-16-27.7-16s-21.6 5.3-27.7 16l-416 720C56 877.4 71.4 904 96 904h832c24.6 0 40-26.6 27.7-48zm-783.5-27.9L512 239.9l339.8 588.2H172.2z" fill="#626262"/><rect x="0" y="0" width="1024" height="1024" fill="rgba(0, 0, 0, 0)" /></svg> {messageFinal}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                       <a target="_blank" className="button-zig secondary" style={{display: 'inline-block'}} href={baseUrl+'contact-imports/higienizar-download/'+filename+'?tipo=validos'}>Download válidos</a>
                                       <a target="_blank" className="button-zig danger" style={{display: 'inline-block', marginLeft: 10}} href={baseUrl+'contact-imports/higienizar-download/'+filename+'?tipo=invalidos'}>Download mal formatados</a>
                                       <a target="_blank" className="button-zig neutral" style={{display: 'inline-block', marginLeft: 10}} href={baseUrl+'contact-imports/higienizar-download/'+filename+'?tipo=duplicados'}>Download duplicados</a>
                                    </td>
                                </tr>
                            </>
                        }
                    </tbody>

                </table>

            </div>

            { progress &&
                <div className="process-load">
                    <div style={{
                        width: (page / total * 100)+'%'
                    }}></div>
                </div>
            }

            <div className="tfoot">
                
                { progress === false &&
                    <button type="button" className="button-zig primary" style={{flexDirection: 'row'}} onClick={() => handleProcess(page)}>
                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32"><path d="M26 20h-6v-2h6z" fill="#626262"/><path d="M30 28h-6v-2h6z" fill="#626262"/><path d="M28 24h-6v-2h6z" fill="#626262"/><path d="M17.003 20a4.895 4.895 0 0 0-2.404-4.173L22 3l-1.73-1l-7.577 13.126a5.699 5.699 0 0 0-5.243 1.503C3.706 20.24 3.996 28.682 4.01 29.04a1 1 0 0 0 1 .96h14.991a1 1 0 0 0 .6-1.8c-3.54-2.656-3.598-8.146-3.598-8.2zm-5.073-3.003A3.11 3.11 0 0 1 15.004 20c0 .038.002.208.017.469l-5.9-2.624a3.8 3.8 0 0 1 2.809-.848zM15.45 28A5.2 5.2 0 0 1 14 25h-2a6.5 6.5 0 0 0 .968 3h-2.223A16.617 16.617 0 0 1 10 24H8a17.342 17.342 0 0 0 .665 4H6c.031-1.836.29-5.892 1.803-8.553l7.533 3.35A13.025 13.025 0 0 0 17.596 28z" fill="#626262"/><rect x="0" y="0" width="32" height="32" fill="rgba(0, 0, 0, 0)" /></svg>
                        <span style={{marginLeft: '10px'}}>
                            Higienizar arquivo
                        </span>
                    </button>
                }

            </div>
            
        </div>

    )

}
  
import { useEffect, useState } from 'react'
import { useCollection } from 'react-firebase-hooks/firestore'
import { db, firebase } from './index.js'
import Datetime from '../Helper/Datetime.js'

function useContatos ( 
    accountId = "",
) {

    const [contatos, setContatos] = useState([])

    const [usersCollection, loadingMessages, error] = useCollection(
        db.collection(`u-${accountId}`)
        .orderBy('ultimaMensagem.time', 'desc')
    )

    useEffect(() => {
        const newUsers = usersCollection?.docs.map(doc => ({
            ...doc.data(),
            id: doc.id
        })) || []

        setContatos(newUsers)

    }, [usersCollection])

    return {
        contatos,
        loadingMessages,
        error
    }

}

function useContato ( 
    accountId = "",
    conversationId = ""
) {
    
    const [contato, setContato] = useState([])
    const [usersCollection, loadingMessages, error] = useCollection(
        db.collection(`u-${accountId}`)
        .doc(conversationId)
    )

    useEffect(() => {

        setContato(usersCollection?.data())

    }, [usersCollection])

    return {
        contato,
        loadingMessages,
        error
    }

}

function useMessages ( 
    conversationId = "", 
    accountId = "",
    origem = "usuario"
) {

    const [messages, setMessages] = useState([])

    const [msgCollection, loadingMessages, error] = useCollection(
        db.collection(`m-${accountId}-${conversationId}`)
        .orderBy('time')
    )

    useEffect(() => {
        const newMessages = msgCollection?.docs.map(doc => ({
            ...doc.data(),
            key: doc.id
        })) || []

        setMessages(newMessages)

    }, [msgCollection])

    return {
        messages,
        loadingMessages,
        error
    }

}

export { 
    useContatos,
    useContato,
    useMessages
}
import React, { useState, useEffect } from 'react';
import { api } from '../../Api/app';
import messageStore from '../../Store/MessageStore';
import InputDefault from '../Form/InputDefault';
import FormDefault from '../Form/FormDefault';
import InputRow from '../Form/InputRow';
import IconButton from '../Icon/IconButton';
import CreatableSelect from 'react-select/creatable';

import './ContactList.scss';

export default function EditContact(props) {
    const open = props.open;
    const [load, setLoad] = useState(true);
    const [columns, setColumns] = useState([]);
    const [values, setValues] = useState({
        contactListId: props.list._id,
    });

    useEffect(() => {
        setColumns([...props.columns]);

        if (props.contact) {
            console.log(props.contact);
            setValues({ ...props.contact });
        }
    }, []);

    function handleNewColumn() {
        columns.push('coluna ' + (columns.length + 1));
        setColumns([...columns]);
    }

    function handleDeleteColumn(name) {
        const list = columns.filter((obj) => obj !== name);

        console.log(list);

        setColumns([...list]);
    }

    function handleChangeColumn(name, value) {
        const list = [];

        columns.map((row) => {
            if (row == name) {
                list.push(value.toLowerCase());
            } else {
                list.push(row);
            }
        });

        setColumns([...list]);

        console.log(list);
    }

    function validatePhone(phone) {
        phone = phone
            .replace(' ', '')
            .replace(' ', '')
            .replace(' ', '')
            .replace('(', '')
            .replace(')', '')
            .replace('-', '');

        console.log(phone);

        var regex = new RegExp(
            '^((1[1-9])|([2-9][0-9]))((3[0-9]{3}[0-9]{4})|(9[0-9]{3}[0-9]{5}))$'
        );
        return regex.test(phone);
    }

    async function handleNewContact(e) {
        try {
            console.log('values', values, columns);

            e.preventDefault();

            if (columns[0] === 'Celular' && !values.field1)
                throw 'Campo celular é obrigatório.';
            if (columns[0] === 'Email' && !values.field1)
                throw 'Campo email é obrigatório.';

            if (
                columns[0] === 'Celular' &&
                validatePhone(values.field1) === false
            )
                throw 'Campo celular incorreto.';
            if (
                columns[0] === 'Email' &&
                values.field1.split('@').length <= 1 &&
                values.field1.split('.').length <= 1
            )
                throw 'Campo e-mail incorreto.';

            var listEdit = await api.put('contactLists/' + props.list._id);

            if (listEdit.data.error === true)
                throw 'Houve um erro ao salvar, tente novamente mais tarde.';

            if (props.contact) {
                var contactEdit = await api.put(
                    'contacts/' + props.contact._id,
                    values
                );

                if (contactEdit.data.error === true)
                    throw 'Houve um erro ao salvar, tente novamente mais tarde.';

                props.handleEditColumn(columns);
            } else {
                var contactEdit = await api.post('contacts/', values);

                if (contactEdit.data.error === true)
                    throw 'Houve um erro ao salvar, tente novamente mais tarde.';

                console.log(contactEdit.data.result._id);

                values._id = contactEdit.data.result._id;

                props.handleEditColumn(columns);
            }

            props.setTab('list');

            messageStore.addSuccess('Contato salvo com sucesso.');
        } catch (err) {
            console.log(err);
            messageStore.addError(err);
        }
    }

    function handleOnChange(name, value) {
        console.log(name);

        if (name === 'field1')
            value = value.replace('+', '').replace('(', '').replace(')', '');

        values[name] = value;
        setValues({ ...values });
    }

    return (
        <form
            onSubmit={handleNewContact}
            style={{ display: open ? 'block' : 'none' }}
        >
            <div className="overflow">
                <table
                    className="table-default"
                    style={{ position: 'relative', zIndex: 999 }}
                >
                    <thead>
                        <tr>
                            <th>Coluna</th>
                            <th>Valor</th>
                        </tr>
                    </thead>

                    <tbody>
                        {columns.map((coluna, indice) => (
                            <tr>
                                <th
                                    style={{
                                        position: 'relative',
                                        zIndex: 9999,
                                    }}
                                >
                                    {coluna !== 'celular' &&
                                    coluna !== 'email' ? (
                                        <CreatableSelect
                                            placeholder={coluna}
                                            onChange={(e) =>
                                                handleChangeColumn(
                                                    coluna,
                                                    e.value
                                                )
                                            }
                                            name={coluna}
                                            defaultValue={coluna}
                                            options={[
                                                {
                                                    label: 'Primeiro nome',
                                                    value: 'primeiro nome',
                                                },
                                                {
                                                    label: 'Sobrenome',
                                                    value: 'sobrenome',
                                                },
                                                {
                                                    label: 'Nome completo',
                                                    value: 'Nome completo',
                                                },
                                                {
                                                    label: 'CPF',
                                                    value: 'CPF',
                                                },
                                                {
                                                    label: 'CNPJ',
                                                    value: 'CNPJ',
                                                },
                                                {
                                                    label: 'Telefone residencial',
                                                    value: 'Telefone residencial',
                                                },
                                                {
                                                    label: 'Telefone comercial',
                                                    value: 'Telefone comercial',
                                                },
                                                {
                                                    label: 'Sexo',
                                                    value: 'Sexo',
                                                },
                                                {
                                                    label: 'CEP residencial',
                                                    value: 'CEP residencial',
                                                },
                                                {
                                                    label: 'Endereço residencial',
                                                    value: 'Endereço residencial',
                                                },
                                                {
                                                    label: 'Bairro residencial',
                                                    value: 'Bairro residencial',
                                                },
                                                {
                                                    label: 'Complemento residencial',
                                                    value: 'Complemento residencial',
                                                },
                                                {
                                                    label: 'UF residencial',
                                                    value: 'UF residencial',
                                                },
                                                {
                                                    label: 'Cidade residencial',
                                                    value: 'Cidade residencial',
                                                },
                                                {
                                                    label: 'CEP comercial',
                                                    value: 'CEP comercial',
                                                },
                                                {
                                                    label: 'Endereço comercial',
                                                    value: 'Endereço comercial',
                                                },
                                                {
                                                    label: 'Bairro comercial',
                                                    value: 'Bairro comercial',
                                                },
                                                {
                                                    label: 'Complemento comercial',
                                                    value: 'Complemento comercial',
                                                },
                                                {
                                                    label: 'UF comercial',
                                                    value: 'UF comercial',
                                                },
                                                {
                                                    label: 'Cidade comercial',
                                                    value: 'Cidade comercial',
                                                },
                                            ]}
                                        />
                                    ) : (
                                        <InputDefault
                                            readOnly={true}
                                            value={coluna}
                                        />
                                    )}
                                </th>

                                <td>
                                    <InputDefault
                                        onChange={handleOnChange}
                                        name={'field' + (indice + 1)}
                                        placeholder={coluna}
                                        value={values['field' + (indice + 1)]}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>

                    <tfoot>
                        <tr>
                            <td>
                                <button
                                    type="button"
                                    className="button-zig secondary"
                                    style={{ flexDirection: 'row' }}
                                    onClick={handleNewColumn}
                                >
                                    <IconButton icon="new" />
                                    <span style={{ marginLeft: '10px' }}>
                                        Adicionar nova coluna
                                    </span>
                                </button>
                            </td>
                            <td></td>
                        </tr>
                    </tfoot>
                </table>
            </div>

            <div className="tfoot">
                <button
                    className="button-zig primary"
                    style={{ flexDirection: 'row' }}
                >
                    <IconButton icon="save" />
                    <span style={{ marginLeft: '10px' }}>Confirmar</span>
                </button>
            </div>
        </form>
    );
}

import React, { useEffect, useState } from 'react'

export default function CountdownReverse({dateDuration}) {

    const [days, setDays] = useState(0)
    const [hours, setHours] = useState(0)
    const [minutes, setMinutes] = useState(0)
    const [seconds, setSeconds] = useState(0)

    useEffect(() => {

        let isMounted = true

        intervalId()
        setInterval(() => !isMounted ? false : intervalId(), 1000)

        return () => {
            clearInterval(intervalId)
            isMounted = false
        }

    }, [dateDuration])

    function intervalId()
    {

        var countDownDate = new Date(dateDuration).getTime()

        var now = new Date().getTime()
            
        var distance = countDownDate - now
            
        setDays(Math.floor(distance / (1000 * 60 * 60 * 24)) * -1)
        setHours(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) * -1)
        setMinutes(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)) * -1)
        setSeconds(Math.floor((distance % (1000 * 60)) / 1000) * -1)

    }

    return(
        <span className="seconds">{(minutes - 1) > 0 ? (minutes-1)+':' : ''}{seconds}</span>
    )

}

import React, { useEffect, useState } from 'react'
import { api } from '../../Api/app'
import WidgetRender from '../../Components/TemplateCreation/WidgetRender/WidgetRender'

import './Templates.scss'

export default function Detalhe(props) {

    const [loading, setLoading] = useState(false)

    const [template, setTemplate] = useState([])

    const id = props.match.params.id

    const shorturl = props.match.params.shorturl

    useEffect(() => {

        getData()

    }, [props])

    async function getData()
    {

        var response = await api.get(id ? 'templates/'+id : 'queues/template/'+shorturl)

        if ( response.data.estrutura != '[{}]' ) {
            setTemplate( ...[JSON.parse(response.data.estrutura)] )
        }
        
    }

    useEffect(() => {

        var el = document.getElementById('click-offset')

        el.addEventListener('mousedown', getClick)
      
        return () => {

            el.removeEventListener('mousedown', getClick)

        }

    }, [])

    function setClick(x, y)
    {
        

        var dataClick = window.localStorage.getItem('dataClick')

        if ( dataClick ) {

            dataClick = JSON.parse(dataClick)

        } else {

            dataClick = []

        }

        var totalClickPosition = dataClick.filter(obj => obj.x === x && obj.y === y).length > 0 ? parseInt(dataClick.filter(obj => obj.x === x && obj.y === y)[0].value) : 0

        var newDataClick = dataClick.filter( obj => obj.x !== x && obj.y !== y )

        var newClick = {
            value: parseInt(totalClickPosition) + 20,
            x: x,
            y: y,
        }

        newDataClick.push(newClick)

        window.localStorage.setItem('dataClick', JSON.stringify(newDataClick))

    }
    
    function getClick(e)
    {

         // Get the target
         const target = e.currentTarget
        
         // Get the bounding rectangle of target
         const rect = target.getBoundingClientRect()
     
         // Mouse position
         const x = e.clientX - rect.left
         const y = e.clientY - rect.top

         console.log(x,y)

         setClick(x,y)

    }

    return(

        <div id="template-detalhe" className="page">

            <div className="container" id="click-offset">
                {loading === false && template.map((item, key) => (
                    <WidgetRender
                        updateWidgetColumn={() => {}}
                        id={item.id}
                        item={item.template}
                        indice={key}
                        editId={() => {}}
                        setLoading={setLoading}
                        template={template}
                        editWidget={() => {}}
                        removeWidget={() => {}}
                        key={key}
                        edit={false}
                        shorturl={shorturl}
                    />
                ))}
            </div>

        </div>

    )

}
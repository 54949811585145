import React, { Profiler } from 'react'
import Datetime from '../../Helper/Datetime'

export default function Contact({
    row,
    key,
    conversation,
    handleChangeConversation,
    active = false,
    operators,
    setOperator,
    profile
})
{

    return (
        <div className={`user ${active ? 'active' : 'inactive'}`} onClick={() => handleChangeConversation(row)} key={key}>
                                
            <div className="profile">
                <span className="name">{row.nome}</span>

                { row.ultimaMensagem.mensagemTipo === 'text' && 
                    <span className="last">{row.ultimaMensagem.mensagem}</span>
                }

                { row.ultimaMensagem.mensagemTipo === 'image' &&
                    <span className="last">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 20" width="16" height="20"><path fill="currentColor" d="M13.822 4.668H7.14l-1.068-1.09a1.068 1.068 0 0 0-.663-.278H3.531c-.214 0-.51.128-.656.285L1.276 5.296c-.146.157-.266.46-.266.675v1.06l-.001.003v6.983c0 .646.524 1.17 1.17 1.17h11.643a1.17 1.17 0 0 0 1.17-1.17v-8.18a1.17 1.17 0 0 0-1.17-1.169zm-5.982 8.63a3.395 3.395 0 1 1 0-6.79 3.395 3.395 0 0 1 0 6.79zm0-5.787a2.392 2.392 0 1 0 0 4.784 2.392 2.392 0 0 0 0-4.784z"></path></svg>
                        Foto
                    </span>
                }

                { row.ultimaMensagem.mensagemTipo === 'video' &&
                    <span className="last">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 20" width="16" height="20"><path fill="currentColor" d="M15.243 5.868l-3.48 3.091v-2.27c0-.657-.532-1.189-1.189-1.189H1.945c-.657 0-1.189.532-1.189 1.189v7.138c0 .657.532 1.189 1.189 1.189h8.629c.657 0 1.189-.532 1.189-1.189v-2.299l3.48 3.09v-8.75z"></path></svg>
                        Vídeo
                    </span>
                }

                { row.ultimaMensagem.mensagemTipo === 'audio' &&
                    <span className="last">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 20" width="12" height="20"><path fill="currentColor" d="M6 11.745a2 2 0 0 0 2-2V4.941a2 2 0 0 0-4 0v4.803a2 2 0 0 0 2 2.001zm3.495-2.001c0 1.927-1.568 3.495-3.495 3.495s-3.495-1.568-3.495-3.495H1.11c0 2.458 1.828 4.477 4.192 4.819v2.495h1.395v-2.495c2.364-.342 4.193-2.362 4.193-4.82H9.495v.001z"></path></svg>
                        Aúdio
                    </span>
                }

                { row.ultimaMensagem.mensagemTipo === 'file' &&
                    <span className="last">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 20" width="13" height="20"><path fill="currentColor" d="M10.2 3H2.5C1.7 3 1 3.7 1 4.5v10.1c0 .7.7 1.4 1.5 1.4h7.7c.8 0 1.5-.7 1.5-1.5v-10C11.6 3.7 11 3 10.2 3zm-2.6 9.7H3.5v-1.3h4.1v1.3zM9.3 10H3.5V8.7h5.8V10zm0-2.7H3.5V6h5.8v1.3z"></path></svg>
                        Arquivo
                    </span>
                }

                <div className="status">

                    { row.operador.nome ? 
                        <span className="operator">
                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M12 2C6.486 2 2 6.486 2 12v4.143C2 17.167 2.897 18 4 18h1a1 1 0 0 0 1-1v-5.143a1 1 0 0 0-1-1h-.908C4.648 6.987 7.978 4 12 4s7.352 2.987 7.908 6.857H19a1 1 0 0 0-1 1V18c0 1.103-.897 2-2 2h-2v-1h-4v3h6c2.206 0 4-1.794 4-4c1.103 0 2-.833 2-1.857V12c0-5.514-4.486-10-10-10z" fill="currentColor"/></svg>
                            { row.operador.nome }
                        </span> 
                    :

                        row.active.status === true &&
                        <>
                            { row.chatbot === false ?
                                <span className="warning">Aguardando atendimento</span> 
                            :
                                <span className="warning">Chatbot atendendo</span>
                            }
                        </>

                    }
                    { row.active.status === false && <span className="danger">
                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 36 36"><path class="clr-i-outline clr-i-outline-path-1" d="M18 2a16 16 0 1 0 16 16A16 16 0 0 0 18 2zM4 18a13.93 13.93 0 0 1 3.43-9.15l19.72 19.72A14 14 0 0 1 4 18zm24.57 9.15L8.85 7.43a14 14 0 0 1 19.72 19.72z" fill="currentColor"/></svg>
                        Encerrado
                    </span> }
                </div>

            </div>

            <div className="infos">

                <span className="date">
                    { Datetime.string(row.ultimaMensagem.criado) }
                </span>

                { row?.active?.status === true && row.operador?.id === profile.id && 
                    <select onChange={(e) => setOperator(e.target.value)}>
                        <option value="">Transferir operador</option>
                        { operators?.filter(obj => obj.id !== profile.id).map((row, key) =>
                            <option value={row.id}>{row.nome}</option>
                        )}
                    </select>
                }
            </div>

            { row.totalOperador > 0 && 
                <div className="total">
                    { row.totalOperador }
                </div>
            }

        </div>
    )

}
import React, { useEffect, useState } from 'react'
import { api } from '../../Api/app'
import H1Page from '../../Components/Layout/H1Page'

import Paginator from '../../Components/Paginator/Paginator'
import ToggleSwitch from '../../Components/ToggleSwitch/ToggleSwitch'
import Datetime from '../../Helper/Datetime'

import './Analytics.scss'

export default function RelatoriosTemplate(props){

    const [load, setLoad] = useState(false)

    const [campaigns, setCampaigns] = useState([])

    const [data, setData] = useState({
        docs: [],
    })

    const [page, setPage] = useState(1)

    const [id, setId] = useState(props.match.params.id ? props.match.params.id : '')

    const [tipoRelatorio, setTipoRelatorio] = useState('views')

    const [dataInicial, setDataInicial] = useState('')

    const [dataFinal, setDataFinal] = useState('')

    useEffect(() => {

        api.get('campaigns').then( response => {

            setCampaigns( response.data.docs )

        })

    }, [props])

    useEffect(() => {

        getData()

    }, [page, id, dataInicial, dataFinal, tipoRelatorio])

    async function getData()
    {

        try {

            setLoad(true)

            const response = await api.get('callback/'+tipoRelatorio+'?campaignId='+id+'&dataInicial='+dataInicial+'&dataFinal='+dataFinal)

            setData({...response.data})

            console.log( response.data )
            setLoad(false)

        } catch ( e ) {

            console.log(e)
            setLoad(false)

        }

    }
    
    return(
        
        <div id="analytics" className="page">

            <div className="h1-button">

                <H1Page nome={"Relatório de template"}/>
                
            </div>

            <div className="filter-page">

                <label>
                    <select value={id} onChange={(e) => setId(e.target.value)}>
                        <option value="">Selecione uma campanha</option>
                        { campaigns.map((row, key) => 
                            <option value={row._id} key={key}>{row.nome}</option>
                        )}
                    </select>
                </label>

                <label>
                    <input onChange={(e) => setDataInicial(e.target.value)} type="date" name="dataInicial"/>
                </label>

                <label>
                    <input onChange={(e) => setDataFinal(e.target.value)} type="date" name="dataFinal"/>
                </label>

            </div>

            <div className="toggle">

                <div onClick={() => setTipoRelatorio('views')}>
                    <span>Aberturas</span>
                    <ToggleSwitch value={tipoRelatorio === 'views' ? 1 : 0} setValue={() => {}}/>
                </div>

                <div onClick={() => setTipoRelatorio('clicks')}>
                    <span>Cliques</span>
                    <ToggleSwitch value={tipoRelatorio === 'clicks' ? 1 : 0} setValue={() => {}}/>
                </div>

            </div>

            <table className="table-default">

                <thead>
                    <tr>
                        <th width="200">Campanha</th>
                        <th>Origem</th>
                        <th>Destinatário</th>

                        { tipoRelatorio === 'clicks' &&
                        <>
                            <th>Tipo de clique</th>
                            <th>Nome do clique</th>
                            <th>Origem do clique</th>
                        </>
                        }

                        { tipoRelatorio === 'views' &&
                        <>
                            <th>Sistema operacional</th>
                            <th>Browser</th>
                        </>
                        }

                        <th width={20}>Data</th>
                    </tr>
                </thead>

                <tbody>
                  
                    { load === false && data.docs.map((row, key) => 
                        <tr style={{backgroundColor: row.campaign ? '' : 'rgba(255,0,0,.03)'}} key={key}>
                            <td>
                                {row.campaign ? row.campaign.nome : 'Envio Teste'}
                            </td>
                            <td>
                                {row.campaign.tipoDeEnvio}
                            </td>
                            <td>
                                {row.contact.field1}
                            </td>
                            { tipoRelatorio === 'clicks' &&
                                <>
                                    <td>
                                        { row.tipo }
                                    </td>
                                    <td>
                                        { row.texto }
                                    </td>
                                    <td>
                                        { row.link }
                                    </td>
                                </>
                            }

                            { tipoRelatorio === 'views' &&
                                <>
                                    <td>
                                        { row.so }
                                    </td>
                                    <td>
                                        { row.browser }
                                    </td>
                                </>
                            }
                                
                            <td style={{whiteSpace: 'nowrap'}}>
                                {Datetime.string(row.createdAt)}
                            </td>
                        </tr>
                    )}
                </tbody>

            </table>

            <div className="tfoot">
                <Paginator 
                    range={3} 
                    totalPage={data.pages} 
                    setPage={setPage}
                    page={page}
                />

            </div>

            <div style={{padding: '0px 10px', marginBottom: 20,}}>Total de <b>{data.total}</b> registros</div>

        </div>

    )

}
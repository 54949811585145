import React, { useEffect, useState } from 'react'
import { api } from '../../Api/app'
import H1Page from '../../Components/Layout/H1Page'
import { firebase } from '../../Firebase/index'
import Datetime from '../../Helper/Datetime'
import "./RelatorioChat.scss"

export default function RelatorioChat(props){

    const accountId = document.getElementById('zigzag') && document.getElementById('zigzag').dataset.account
    const [data, setData] = useState([])
    const [operadores, setOperadores] = useState([])
    const [operador, setOperador] = useState(null)
    const [filtro, setFiltro] = useState(null)
    const [resolvido, setResolvido] = useState(null)
    const [satisfacao, setSatisfacao] = useState(null)
    const [dataInicial, setDataInicial] = useState(Datetime.yesterday())
    const [dataFinal, setDataFinal] = useState(Datetime.today())

    useEffect(() => {

        api.get('users').then((response) => {
            setOperadores(response.data.docs)
        })

    }, [])

    useEffect(() => {

        getData()

    }, [operador, filtro, resolvido, satisfacao, dataInicial, dataFinal])

    async function getData()
    {

        console.log('criado','>=',dataInicial+' - 00:00')
        console.log('criado','<=',dataFinal+' - 23:59')

        const dataAMenos = new Date(dataInicial+'T00:00:00')
        dataAMenos.setDate( dataAMenos.getDate() - 1 )

        const snapshot = await firebase
        .firestore()
        .collection(`u-${accountId}`)
        .where('criado','>=',dataAMenos.toISOString()+' 00:00:00')
        .where('criado','<=',dataFinal+' 23:59:59')
        .get()
        var usersFiltereds = snapshot.docs.map(doc => doc.data())

        if ( filtro === '0' ) usersFiltereds = usersFiltereds.filter( 
            obj => !obj.operador.id && obj.active.status === true )
        if ( filtro === '1' ) usersFiltereds = usersFiltereds.filter( 
            obj => obj.active.status === false )
        if ( filtro === '2' ) usersFiltereds = usersFiltereds.filter( 
            obj => obj.chatbot !== false )

        if ( operador ) usersFiltereds = usersFiltereds.filter( 
            obj => obj.operador.id === operador )

        if ( resolvido === '1' ) usersFiltereds = usersFiltereds.filter( 
            obj => obj.avaliacao?.resolvido === 'sim' )
        if ( resolvido === '2' ) usersFiltereds = usersFiltereds.filter( 
            obj => obj.avaliacao?.resolvido === 'nao' )

        if ( satisfacao === '1' ) usersFiltereds = usersFiltereds.filter( 
            obj => obj.avaliacao?.satisfacao === 1 )
        if ( satisfacao === '2' ) usersFiltereds = usersFiltereds.filter( 
            obj => obj.avaliacao?.satisfacao === 2 )
        if ( satisfacao === '3' ) usersFiltereds = usersFiltereds.filter( 
            obj => obj.avaliacao?.satisfacao === 3 )
        if ( satisfacao === '4' ) usersFiltereds = usersFiltereds.filter( 
            obj => obj.avaliacao?.satisfacao === 4 )
        if ( satisfacao === '5' ) usersFiltereds = usersFiltereds.filter( 
            obj => obj.avaliacao?.satisfacao === 5 )

        setData(usersFiltereds)

    }
    
    return(
        
        <div id="relatorio-chat" className="page">

            <div className="h1-button">

                <H1Page nome={"Relatórios de chat"}/>
                
            </div>

            <div className="filter-page">

                <label>
                    <select value={operador} onChange={(e) => setOperador(e.target.value)}>
                        <option value="">Selecione um operador</option>
                        { operadores.map((row, key) => 
                            <option value={row.id} key={key}>{row.nome}</option>
                        )}
                    </select>
                </label>

                <label>
                    <select value={filtro} onChange={(e) => setFiltro(e.target.value)}>
                        <option value="">Selecione um status</option>
                        <option value="0">Aguardando atendimento</option>
                        <option value="1">Encerrado</option>
                        <option value="2">Chatbot</option>
                    </select>
                </label>

                <label>
                    <select value={resolvido} onChange={(e) => setResolvido(e.target.value)}>
                        <option value="">Atendimento resolvido</option>
                        <option value="1">Sim</option>
                        <option value="2">Não</option>
                    </select>
                </label>

                <label>
                    <select value={satisfacao} onChange={(e) => setSatisfacao(e.target.value)}>
                        <option value="">Satisfação</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                    </select>
                </label>

                <label>
                    <input onChange={(e) => setDataInicial(e.target.value)} type="date" value={dataInicial}/>
                </label>

                <label>
                    <input onChange={(e) => setDataFinal(e.target.value)} type="date" value={dataFinal}/>
                </label>

            </div>

            <table className="table-default">

                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>E-mail</th>
                        <th>Telefone</th>
                        <th>Operador</th>
                        <th>Status</th>
                        <th>Atendimento resolvido</th>
                        <th>Satisfação</th>
                        <th>Observação</th>
                        <th>Data de atendimento</th>
                    </tr>
                </thead>

                <tbody>
                  
                    { data.map((row, key) => 
                        <tr key={key}>
                            <td>{row.nome}</td>
                            <td>{row.email}</td>
                            <td>{row.telefone}</td>
                            <td>{row.operador?.nome}</td>
                            <td>
                                regras aqui
                            </td>
                            <td>{row.avaliacao?.resolvido}</td>
                            <td>{row.avaliacao?.satisfacao}</td>
                            <td>{row.avaliacao?.obs}</td>
                            <td>{row.criado}</td>
                        </tr>
                    )}
                </tbody>

            </table>

            <div style={{padding: '0px 10px', marginBottom: 20,}}>Total de <b>{data.length}</b> registros</div>

        </div>

    )

}
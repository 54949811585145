import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';
import { api } from '../../Api/app';
import datesFormater from '../../Helper/DatesFormater';
import messageStore from '../../Store/MessageStore';
import IconButton from '../Icon/IconButton';
import Paginator from '../Paginator/Paginator';

import './ContactList.scss';
import DetailContact from './DetailContact';

export default function ListContact(props) {
    const id = props.id;
    const columns = props.columns;
    const page = props.page;
    const open = props.open;
    const search = props.search;
    const dataInicial = props.dataInicial;
    const dataFinal = props.dataFinal;
    const statusHigienizacao = props.statusHigienizacao;

    const [load, setLoad] = useState(true);
    const [contacts, setContacts] = useState([]);

    const [ids, setIds] = useState([]);

    useEffect(() => {
        setContacts([]);
        setLoad(true);
        getContacts();
    }, [open, page, search, dataInicial, dataFinal, statusHigienizacao]);

    async function getContacts() {
        setLoad(true);

        api.get(
            `contacts?contactListId=${id}&page=${page}&search=${search}&dataInicial=${dataInicial}&dataFinal=${dataFinal}&statusHigienizacao=${statusHigienizacao}`
        ).then((response) => {
            setContacts(response.data);
            setLoad(false);
        });
    }

    async function handleDelContact(id) {
        await api.delete('contacts/' + id);
        getContacts();

        const docs = contacts.docs.filter((obj) => obj._id !== id);

        contacts.docs = docs;
        setContacts({ ...contacts });
    }

    function handleEdit(contact) {
        props.setTab('edit');
        props.setContact(contact);
    }

    function handleSetIds(id) {
        if (ids.filter((obj) => obj === id).length > 0) {
            console.log(
                'existe já ',
                ids.filter((obj) => obj !== id),
                id
            );
            setIds([...ids.filter((obj) => obj !== id)]);
        } else {
            ids.push(id);
            console.log('não existe', id);
            setIds([...ids]);
        }
    }

    const [selectAll, setSelectAll] = useState(false);

    function handleSelectAll() {
        if (selectAll) {
            setSelectAll(false);
            setIds([]);
        } else {
            setSelectAll(true);
            contacts.docs.map((row, key) => {
                ids.push(row._id);
            });
            setIds([...ids]);
        }
    }

    async function handleHigienizeIds() {
        try {
            const response = await api.post(
                'contacts-validate/enviar-lote-por-contatos',
                {
                    ids,
                }
            );
            getContacts();
            messageStore.addSuccess(
                'Os contatos selecionados foram enviados a uma fila de higienização, aguarde até que o status altere.'
            );
        } catch (err) {
            messageStore.addError(
                'Houve um erro ao tentar higienizar sua seleção de contatos, tente novamente mais tarde.'
            );
        }
    }

    async function handleRemoveIds() {
        try {
            const response = await api.post('contacts-delete', {
                ids,
            });
            getContacts();
            messageStore.addSuccess(
                'Os contatos selecionados foram removidos.'
            );
            setSelectAll(false);
        } catch (err) {
            messageStore.addError(
                'Houve um erro ao tentar remover sua seleção de contatos, tente novamente mais tarde.'
            );
        }
    }

    const [idView, setIdView] = useState(false);

    return (
        <div style={{ display: open ? 'block' : 'none' }}>
            <DetailContact id={idView} setId={setIdView} />
            <div className="overflow">
                <table className="table-default">
                    <thead>
                        <tr>
                            <th width="10">
                                <input
                                    checked={selectAll}
                                    onChange={handleSelectAll}
                                    type="checkbox"
                                />
                            </th>
                            <th width="10"></th>
                            <th width="10"></th>
                            <th width="10"></th>
                            <th width="10">Origem</th>
                            <th width="10">Usuario</th>
                            {props.columns.length !== 0 &&
                                load === false &&
                                props.columns.map((row, key) => <th>{row}</th>)}
                            <th>Data de inserção</th>
                            <th>Status de Higienização</th>
                        </tr>
                    </thead>

                    <tbody>
                        {load === true && (
                            <tr>
                                <td colSpan={columns.length + 5}>
                                    <Skeleton />
                                </td>
                            </tr>
                        )}

                        {load === false && contacts.docs.length == 0 && (
                            <tr>
                                <td colSpan={columns.length + 5}>
                                    Nenhum contato encontrado.
                                </td>
                            </tr>
                        )}

                        {columns.length !== 0 &&
                            load === false &&
                            contacts.docs.map((row, key) => (
                                <tr key={key}>
                                    <td>
                                        <input
                                            type="checkbox"
                                            onChange={() =>
                                                handleSetIds(row._id)
                                            }
                                            checked={
                                                ids.filter(
                                                    (obj) => obj === row._id
                                                ).length > 0
                                                    ? true
                                                    : false
                                            }
                                        />
                                    </td>
                                    <td>
                                        <button
                                            type="button"
                                            className="button-zig danger"
                                            onClick={() =>
                                                messageStore.addConfirm(
                                                    'Deseja realmente remover esse contato?',
                                                    () =>
                                                        handleDelContact(
                                                            row._id
                                                        )
                                                )
                                            }
                                        >
                                            <IconButton icon="del" />
                                        </button>
                                    </td>
                                    <td>
                                        <button
                                            type="button"
                                            className="button-zig primary"
                                            onClick={() => handleEdit(row)}
                                        >
                                            <IconButton icon="edit" />
                                        </button>
                                    </td>
                                    <td>
                                        <button
                                            type="button"
                                            className="button-zig secondary"
                                            onClick={() => setIdView(row.id)}
                                        >
                                            <IconButton icon="view" />
                                        </button>
                                    </td>
                                    <td>{row.origem}</td>
                                    <td style={{ whiteSpace: 'nowrap' }}>
                                        {row.user && row.user.nome}
                                    </td>
                                    {columns.map((coluna, indice) => (
                                        <td>{row['field' + (indice + 1)]}</td>
                                    ))}
                                    <td>
                                        {datesFormater.dateBR(row.createdAt)}
                                    </td>
                                    <td>
                                        {row.validacao.status
                                            ? row.validacao.status
                                            : 'Não higienizado'}
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                    role="img"
                                    width="1em"
                                    height="1em"
                                    preserveAspectRatio="xMidYMid meet"
                                    viewBox="0 0 16 16"
                                >
                                    <g fill="currentColor">
                                        <path
                                            fill-rule="evenodd"
                                            d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5z"
                                        />
                                    </g>
                                </svg>
                            </td>
                            <td colSpan={columns.length + 6}>
                                <div style={{ display: 'flex' }}>
                                    <button
                                        onClick={handleHigienizeIds}
                                        className="button-zig secondary"
                                    >
                                        Higienizar seleção
                                    </button>
                                    <button
                                        onClick={handleRemoveIds}
                                        className="button-zig danger"
                                        style={{ marginLeft: 10 }}
                                    >
                                        Remover seleção
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>

            <div className="tfoot">
                <Paginator
                    range={4}
                    totalPage={contacts.pages}
                    setPage={props.setPage}
                    page={page}
                    totalDocs={contacts.total}
                />
            </div>
        </div>
    );
}

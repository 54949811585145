import React, { useEffect, useRef, useState } from 'react';
import { api } from '../../Api/app';
import Messages from './Messages';
import SendMessage from './SendMessage';
import { Scrollbars } from 'react-custom-scrollbars';
import { Lightbox } from 'react-modal-image';
import messageStore from '../../Store/MessageStore';
import Select from 'react-select';

export default function Conversation({ conversation, updateContacts, tab }) {
    const [load, setLoad] = useState(true);

    const [messages, setMessages] = useState([]);

    const [scrollPosition, setScrollPosition] = useState(false);

    const [imageZoom, setImageZoom] = useState(false);

    const refScroll = useRef();

    useEffect(() => {
        console.log(conversation);
        if (load === false) {
            setTimeout(() => {
                var element = document.getElementById(conversation.id);

                if (element) {
                    refScroll.current.scrollTop(element.offsetTop);
                }
            }, 1000);
        }
    }, [load]);

    useEffect(() => {
        console.log(scrollPosition);

        if (scrollPosition >= 1 || scrollPosition === false) {
            handleScrollToBottom();
        }
    }, [scrollPosition, messages, load]);

    useEffect(() => {
        setLoad(true);
        setScrollPosition(false);
        getMessages();

        let rotationInterval = setInterval(() => {
            getMessages();
        }, 5000);

        return () => {
            setLoad(true);
            console.log('getMessages, clearInterval');
            clearInterval(rotationInterval);
        };
    }, [conversation]);

    async function getMessages() {
        try {
            const responseMessages = [];

            const response = await api.get(
                'conversations/' + conversation.queueId + '/whatsapp'
            );

            response.data.map((row, key) => {
                responseMessages.push({
                    id: row.id,
                    messageText: row.mensagem,
                    messageDate: new Date(row.createdAt),
                    status: row.statusPadrao,
                    figurinha: row.figurinha,
                    audio: row.audio,
                    foto: row.imagem,
                    video: row.video,
                    contatoJSON: row.contatoJSON,
                    localizacaoJSON: row.localizacaoJSON,
                    arquivo: row.arquivo,
                    botoes: row.botoes,
                    type:
                        row.chatbotId || row.userId || row.campanha
                            ? 'sent'
                            : 'received',
                    chatbotId: row.chatbotId,
                    author: row.userId || row.chatbotId ? row.nome : false,
                    nome: row.nome,
                    campanha: row.campanha,
                });

                if (
                    key === response.data.length - 1 &&
                    row.createdAt !== conversation.dateMessage
                ) {
                    sendMessage({
                        id: row.id,
                        type: row.chatbotId || row.userId ? 'sent' : 'received',
                        messageText: row.mensagem,
                        messageDate: row.createdAt,
                        status: row.statusPadrao,
                        figurinha: row.figurinha,
                        audio: row.audio,
                        video: row.video,
                        contatoJSON: row.contatoJSON,
                        localizacaoJSON: row.localizacaoJSON,
                        foto: row.imagem,
                        arquivo: row.arquivo,
                        type: row.userId ? 'sent' : 'received',
                        chatbotId: row.chatbotId,
                        author: row.userId || row.chatbotId ? row.nome : false,
                        nome: row.nome,
                        campanha: row.campanha,
                    });
                }
            });

            setMessages([...responseMessages]);
            setLoad(false);
        } catch (err) {
            console.log(err);
        }
    }

    async function sendMessage(messageResponse) {
        conversation.audio = messageResponse.audio;
        conversation.video = messageResponse.video;
        conversation.contatoJSON = messageResponse.contatoJSON;
        conversation.localizacaoJSON = messageResponse.localizacaoJSON;
        conversation.figurinha = messageResponse.figurinha;
        conversation.foto = messageResponse.foto;
        conversation.arquivo = messageResponse.arquivo;
        conversation.lastMessage = messageResponse.messageText;
        conversation.dateMessage = messageResponse.messageDate;
        conversation.type = messageResponse.type;
        conversation.chatbotId = messageResponse.chatbotId;
        conversation.author = messageResponse.author;

        updateContacts(conversation);

        messages.push(messageResponse);
        setMessages([...messages]);

        if (messageResponse.type === 'sent') {
            setTimeout(() => {
                handleScrollToBottom();
            }, 10);
        }
    }

    async function updateStatusMessage(messageResponse) {
        const newMessages = [];

        messages.map((row) => {
            if (row.messageDate === messageResponse.messageDate) {
                messageResponse.status = 'Enviado';
                newMessages.push(messageResponse);
            } else {
                newMessages.push(row);
            }
        });

        setMessages([...newMessages]);
    }

    function handleScrollToBottom() {
        refScroll.current.scrollToBottom();
    }

    async function handleInactiveChat() {
        conversation.ativo = false;
        updateContacts(conversation);
        const responseTerminated = api.put(
            `queues/${conversation.queueId}/encerrar`
        );
    }

    const [operadores, setOperadores] = useState([]);
    const accountId =
        document.getElementById('zigzag') &&
        document.getElementById('zigzag').dataset.account;
    const [operador, setOperador] = useState({ label: '', value: '' });

    useEffect(() => {
        api.get('users?accountId=' + accountId).then((response) => {
            response.data.docs.map((row, key) => {
                operadores.push({
                    label: row.nome,
                    value: row.id,
                });
                setOperadores([...operadores]);
            });
        });
    }, []);

    useEffect(() => {
        setOperador({
            ...operadores.filter((obj) => obj.value === conversation.userId)[0],
        });
    }, [operadores, conversation]);

    async function handleTransfer() {
        conversation.userId = operador.value;
        updateContacts(conversation);
        const response = await api.put(
            '/conversations/' + conversation.queueId + '/transferir',
            {
                userId: conversation.userId,
            }
        );

        messageStore.addSuccess('Chat transferido com sucesso.');
    }

    return (
        <div id="conversation">
            {imageZoom && (
                <Lightbox
                    small={imageZoom}
                    large={imageZoom}
                    onClose={() => setImageZoom(false)}
                    hideDownload={true}
                />
            )}

            <div className="relative">
                <div className="header">
                    <span>{conversation.phone}</span>

                    <form className="transfer">
                        <p>Transferir para outro operador</p>
                        <Select
                            value={operador}
                            placeholder="Transferir chat "
                            options={operadores}
                            onChange={setOperador}
                        />
                        <buton
                            onClick={() =>
                                messageStore.addConfirm(
                                    'Deseja mesmo transferir esse chat?',
                                    handleTransfer
                                )
                            }
                            type="submit"
                            className="button-zig primary"
                        >
                            Transferir
                        </buton>
                    </form>
                </div>

                <Scrollbars
                    ref={refScroll}
                    onScrollFrame={(e) =>
                        e.top !== 0 && setScrollPosition(e.top)
                    }
                    className="messages"
                    id={'scroll-element'}
                >
                    <Messages
                        messages={messages}
                        setLoad={setLoad}
                        conversation={conversation}
                        load={load}
                        setImageZoom={setImageZoom}
                    />
                </Scrollbars>

                {scrollPosition !== false && scrollPosition < 1 && (
                    <div className="button-scroll">
                        <button
                            className={`scroll-to-bottom`}
                            onClick={() => refScroll.current.scrollToBottom()}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 19 20"
                                width="19"
                                height="20"
                            >
                                <path
                                    fill="currentColor"
                                    d="M3.8 6.7l5.7 5.7 5.7-5.7 1.6 1.6-7.3 7.2-7.3-7.2 1.6-1.6z"
                                ></path>
                            </svg>
                        </button>
                    </div>
                )}

                {conversation.ativo === true && (
                    <div className="chatStatus">
                        <button
                            onClick={() =>
                                messageStore.addConfirm(
                                    'Deseja encerrar essa conversa?',
                                    handleInactiveChat
                                )
                            }
                            className="cancelar"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                aria-hidden="true"
                                focusable="false"
                                width="1em"
                                height="1em"
                                preserveAspectRatio="xMidYMid meet"
                                viewBox="0 0 32 32"
                            >
                                <path
                                    d="M9.19 18.56A25.66 25.66 0 0 1 5 6.08V6a1 1 0 0 1 1-1h5.28l1.5 3.77l-2.84 2.87l.06.48a13 13 0 0 0 1.46 4.17l1.46-1.46a9.34 9.34 0 0 1-.84-2.52l2.13-2.15A2 2 0 0 0 14.65 8l-1.52-3.74A2 2 0 0 0 11.27 3H5.76A3 3 0 0 0 3 6.23A28 28 0 0 0 7.79 20z"
                                    fill="#626262"
                                />
                                <path
                                    d="M27.77 18.86L24 17.35a2 2 0 0 0-2.17.41l-2.17 2.15A9.17 9.17 0 0 1 15.45 18L30 3.41L28.59 2L2 28.59L3.41 30l7-7c3.38 3.18 8.28 5.62 15.39 6h.2a3 3 0 0 0 3-3v-5.28a2 2 0 0 0-1.23-1.86zM27 26v.06a1 1 0 0 1-1.06.94c-6.51-.37-11-2.54-14.11-5.42L14 19.44A10.77 10.77 0 0 0 19.88 22l.48.06l2.87-2.85L27 20.72z"
                                    fill="#626262"
                                />
                                <rect
                                    x="0"
                                    y="0"
                                    width="32"
                                    height="32"
                                    fill="rgba(0, 0, 0, 0)"
                                />
                            </svg>
                            Encerrar atendimento
                        </button>
                    </div>
                )}

                <SendMessage
                    conversation={conversation}
                    sendMessage={sendMessage}
                    updateStatusMessage={updateStatusMessage}
                    handleScrollToBottom={handleScrollToBottom}
                    tab={tab}
                />
            </div>
        </div>
    );
}

class DatesFormater
{

    dateBR(data)
    {

        var myDate = new Date( data )
        var dia = myDate.getDate().toString().padStart(2, '0')
        var mes = (parseFloat(myDate.getMonth().toString()) + 1).toString().padStart(2, '0')
        var ano = myDate.getFullYear()

        var hora = myDate.getHours().toString().padStart(2, '0')
        var minuto = myDate.getMinutes().toString().padStart(2, '0')

        return dia+'/'+mes+'/'+ano+' '+hora+':'+minuto

    }

    dateBRSecond(data)
    {

        var myDate = new Date( data )
        var dia = myDate.getDate().toString().padStart(2, '0')
        var mes = (parseFloat(myDate.getMonth().toString()) + 1).toString().padStart(2, '0')
        var ano = myDate.getFullYear()

        var hora = myDate.getHours().toString().padStart(2, '0')
        var minuto = myDate.getMinutes().toString().padStart(2, '0')
        var segundo = myDate.getSeconds().toString().padStart(2, '0')

        return dia+'/'+mes+'/'+ano+' '+hora+':'+minuto+':'+segundo

    }

    dateBrReplace(data)
    {
        
        var myDate = new Date( data )
        var dia = myDate.getDate().toString().padStart(2, '0')
        var mes = (parseFloat(myDate.getMonth().toString()) + 1).toString().padStart(2, '0')
        var ano = myDate.getFullYear()

        var hora = myDate.getHours().toString().padStart(2, '0')
        var minuto = myDate.getMinutes().toString().padStart(2, '0')

        return dia+'/'+mes+'/'+ano

    }

    dateQuery(data)
    {
        var myDate = new Date( data )
        return myDate.getFullYear()+'-'+myDate.getMonth()+'-'+myDate.getDate()
    }

}

const datesFormater = new DatesFormater({})
export default datesFormater
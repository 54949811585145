import React, { useEffect, useState } from 'react';
import { api } from '../../Api/app';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import messageStore from '../../Store/MessageStore';
import PreviewTemplate from '../../Components/TemplateCreation/PreviewTemplate/PreviewTemplate';
import H1Page from '../../Components/Layout/H1Page';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import IconButton from '../../Components/Icon/IconButton';
import datesFormater from '../../Helper/DatesFormater';
import Modal from '../../Components/Modal/Modal';
import NewTemplateOficial from '../../Components/TemplateOficial/NewTemplateOficial';

import './TemplatesOficiais.scss';

export default function TemplatesOficiais(props) {
    const [load, setLoad] = useState(true);
    const [data, setData] = useState([]);

    const history = useHistory();

    useEffect(() => {
        getData();
    }, [props]);

    async function getData() {
        setLoad(true);
        var response = await api.get('templates-oficiais');
        setData(...[response.data]);
        setLoad(false);
    }

    async function handleDelete(id) {
        setLoad(true);
        await api.delete('templates-oficiais/' + id);
        getData();
        setLoad(false);
    }

    return (
        <div className="page" id="templates-oficiais">
            <div className="h1-button">
                <H1Page nome={'Meus templates'} />

                <div className="buttons">
                    <Modal
                        title="Novo template oficial"
                        content={
                            <button className="button-zig primary">
                                <IconButton icon="new" />
                                <span>Novo template oficial</span>
                            </button>
                        }
                    >
                        <NewTemplateOficial getData={getData} />
                    </Modal>
                </div>
            </div>

            <table className="table-default">
                <thead>
                    <tr>
                        <th>ID Integração</th>
                        <th>Nome</th>
                        <th>Mensagem</th>
                        <th>Variaveis exemplo</th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    {load === true &&
                        [0, 1, 2].map((row, key) => (
                            <tr key={key}>
                                <td colSpan={6}>
                                    <Skeleton />
                                </td>
                            </tr>
                        ))}
                    {load === false &&
                        data.map((row, key) => (
                            <tr key={key}>
                                <td>{row._id}</td>
                                <td>{row.name}</td>
                                <td>
                                    {row.text
                                        .replaceAll('{{', '[')
                                        .replaceAll('}}', ']')}
                                </td>
                                <td>
                                    {row.fields.map((field, fieldKey) => (
                                        <span className="variable">
                                            {field}
                                        </span>
                                    ))}
                                </td>
                                <td>
                                    {row.status
                                        .replace(
                                            'WAITING_APPROVAL',
                                            'Aguardando aprovação'
                                        )
                                        .replace('APPROVED', 'Aprovado')}
                                </td>
                                <td>
                                    <a
                                        className="button-zig danger"
                                        onClick={() =>
                                            messageStore.addConfirm(
                                                'Deseja remover o template "' +
                                                    row.name +
                                                    '"',
                                                () => handleDelete(row.id)
                                            )
                                        }
                                    >
                                        <IconButton icon="del" />
                                        <span>Remover</span>
                                    </a>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </div>
    );
}

import React, { useEffect, useState } from 'react';
import { api } from '../../Api/app';
import { useGlobal } from '../../contexts/GlobalContext';
import messageStore from '../../Store/MessageStore';
import Tooltip from '../Tooltip/Tooltip';

export default function MenuFixed({
    Model,
    handleOnSave,
    name,
    id,
    palavrasAtendimento,
    setPalavrasAtendimento,
    mensagemDeEntrada,
    setMensagemDeEntrada,
}) {
    const [phones, setPhones] = useState([]);

    const [phone, setPhone] = useState('');

    const [load, setLoad] = useState(false);

    const [modalTest, setModalTest] = useState(false);

    const [modalAttendace, setModalAttendance] = useState(false);

    const [embed, setEmbed] = useState(false);

    document.addEventListener(
        'click',
        function (event) {
            if (!event.target.closest('#test')) setModalTest(false);
            if (!event.target.closest('#attendance')) setModalAttendance(false);
            if (!event.target.closest('#embed')) setEmbed(false);
        },
        false
    );

    //submenu click right
    useEffect(() => {
        document.addEventListener('contextmenu', handleContextMenu);

        return () => {
            document.removeEventListener('contextmenu', handleContextMenu);
        };
    }, [Model]);

    useEffect(() => {
        getBrokers();
    }, []);

    function handleContextMenu(e) {
        if (e.target.closest('#react-flow')) {
            e.preventDefault();

            const translateFlow = document
                .getElementsByClassName('react-flow__nodes')[0]
                .style.transform.replace('translate(', '')
                .replace(') scale(1)', '')
                .replace(' ', '')
                .split(',');

            console.log(translateFlow);

            var pageX = e.pageX - 417 - parseInt(translateFlow[0]);
            var pageY = e.pageY - 210 - parseInt(translateFlow[1]);

            Model.handleNewElement(pageX, pageY);
        }
    }

    function validatePhone(phone) {
        phone = phone
            .replace(' ', '')
            .replace(' ', '')
            .replace(' ', '')
            .replace('(', '')
            .replace(')', '')
            .replace('-', '');

        var regex = new RegExp(
            '^((1[1-9])|([2-9][0-9]))((3[0-9]{3}[0-9]{4})|(9[0-9]{3}[0-9]{5}))$'
        );
        return regex.test(phone);
    }

    async function sendAvulsos(e) {
        try {
            e.preventDefault();

            setLoad(true);

            await handleOnSave(mensagemDeEntrada);

            if (phones.length === 0) throw 'Nenhum destinatário encontrado.';

            const response = await api.post('send/whatsapp', {
                mensagem:
                    'Responda essa mensagem para iniciar o fluxo do ' + name,
                destinatarios: phones,
                imagem: '',
                templateId: '',
                chatbotId: id,
                frontend:
                    window.location.protocol +
                    '//' +
                    window.location.host +
                    '/t/',
            });

            messageStore.addSuccess('Enviado com sucesso.');

            setLoad(false);
        } catch (e) {
            messageStore.addError(e);
            setLoad(false);
        }
    }

    function handlePhones(e) {
        console.log(e.target.value);

        const value = e.target.value
            .replace(' ', '')
            .replace(' ', '')
            .replace(' ', '')
            .replace('(', '')
            .replace(')', '')
            .replace('-', '');

        console.log(value);

        setPhone(value);
        setPhones(value.split(','));
    }

    const [colorEmbed, setColorEmbed] = useState('#FFFFFF');
    const [backgroundEmbed, setBackgroundEmbed] = useState('#5973f7');
    const [broker, setBroker] = useState('');
    const [generate, setGenerate] = useState(true);

    async function getBrokers() {
        const response = await api.get('brokers');
        const phone = response.data.docs.filter(
            (obj) => obj.oficial === true && obj.numero
        )[0]?.numero;

        if (phone)
            setBroker(
                phone
                    .replace('(', '')
                    .replace(')', '')
                    .replace('-', '')
                    .replace(' ', '')
            );
    }

    const [mensagemEntradaUnico, setMensagemEntradaUnico] = useState('');

    async function handleOnSaveNovaMensagemEntrada(e) {
        setMensagemEntradaUnico('');
        mensagemDeEntrada.push(mensagemEntradaUnico);
        setMensagemDeEntrada(mensagemDeEntrada);
        handleOnSave(mensagemDeEntrada);
    }

    async function handleRemoveMensagem(mensagem) {
        const newMensagens = mensagemDeEntrada.filter(
            (obj) => obj !== mensagem
        );
        setMensagemDeEntrada(newMensagens);
        handleOnSave(newMensagens);
    }

    function copyCode() {
        var copyText = document.getElementById('embedScript');

        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */

        /* Copy the text inside the text field */
        document.execCommand('copy');

        /* Alert the copied text */
        messageStore.addSuccess('Código de incorporação copiado com sucesso.');
    }

    const { user } = useGlobal();

    return (
        <div className="menu-fixed">
            <button
                className="newDialog action"
                onClick={() => Model.handleNewElement()}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    focusable="false"
                    width="1em"
                    height="1em"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 1024 1024"
                >
                    <path
                        d="M696 480H544V328c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v152H328c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h152v152c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V544h152c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8z"
                        fill="#626262"
                    />
                    <path
                        d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448s448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372s372 166.6 372 372s-166.6 372-372 372z"
                        fill="#626262"
                    />
                    <rect
                        x="0"
                        y="0"
                        width="1024"
                        height="1024"
                        fill="rgba(0, 0, 0, 0)"
                    />
                </svg>
                <span>Novo diálogo</span>
            </button>

            {user.account.brokers.filter(
                (obj) => obj.tipo === 'Whatsapp' && obj.oficial === true
            ).length === 0 && (
                <div className="menu-relative" id="test">
                    <button
                        className={`testFlow action ${
                            modalTest ? 'active' : 'inactive'
                        }`}
                        onClick={() => setModalTest(!modalTest)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                            focusable="false"
                            width="1em"
                            height="1em"
                            preserveAspectRatio="xMidYMid meet"
                            viewBox="0 0 24 24"
                        >
                            <path
                                d="M13.293 2.707l.818.818L3.318 14.318C2.468 15.168 2 16.298 2 17.5s.468 2.332 1.318 3.183C4.169 21.532 5.299 22 6.5 22s2.331-.468 3.182-1.318L20.475 9.889l.818.818l1.414-1.414l-8-8l-1.414 1.414zm3.182 8.354l-2.403-2.404l-1.414 1.414l2.403 2.404l-1.414 1.415l-.99-.99l-1.414 1.414l.99.99l-1.415 1.415l-2.403-2.404L7 15.728l2.403 2.404l-1.136 1.136c-.945.944-2.59.944-3.535 0C4.26 18.795 4 18.168 4 17.5s.26-1.295.732-1.768L15.525 4.939l3.535 3.535l-2.585 2.587z"
                                fill="#626262"
                            />
                            <rect
                                x="0"
                                y="0"
                                width="24"
                                height="24"
                                fill="rgba(0, 0, 0, 0)"
                            />
                        </svg>
                        <span>Testar bot</span>
                    </button>

                    <form
                        onSubmit={sendAvulsos}
                        className={`submenuModal ${
                            modalTest === true ? 'active' : 'inactive'
                        }`}
                    >
                        <div className="text">
                            <p>
                                Digite celulares separados por virgula, para
                                receber o fluxo em seu celular e poder testar.
                            </p>
                        </div>

                        <div className="form">
                            <input
                                onChange={(e) => handlePhones(e)}
                                type="text"
                                value={phone}
                                className="input-default"
                                placeholder="Celulares separados por virgula"
                            />
                            <button type="submit">
                                {load === true ? (
                                    <i className="fa fa-spin fa-spinner"></i>
                                ) : (
                                    'Enviar'
                                )}
                            </button>
                        </div>

                        <div className="phones">
                            {phones.map((row, key) => (
                                <span
                                    className={`phone-number ${
                                        validatePhone(row) ? 'valid' : 'invalid'
                                    }`}
                                >
                                    {validatePhone(row) ? (
                                        <i className="fa fa-check" />
                                    ) : (
                                        <i className="fa fa-times" />
                                    )}
                                    {row}
                                </span>
                            ))}
                        </div>
                    </form>
                </div>
            )}

            <div className="menu-relative" id="attendance">
                <button
                    className={`attendance action ${
                        modalAttendace ? 'active' : 'inactive'
                    }`}
                    onClick={() => setModalAttendance(!modalAttendace)}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        focusable="false"
                        width="1em"
                        height="1em"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                    >
                        <path
                            d="M12 2C6.486 2 2 6.486 2 12v4.143C2 17.167 2.897 18 4 18h1a1 1 0 0 0 1-1v-5.143a1 1 0 0 0-1-1h-.908C4.648 6.987 7.978 4 12 4s7.352 2.987 7.908 6.857H19a1 1 0 0 0-1 1V18c0 1.103-.897 2-2 2h-2v-1h-4v3h6c2.206 0 4-1.794 4-4c1.103 0 2-.833 2-1.857V12c0-5.514-4.486-10-10-10z"
                            fill="#626262"
                        />
                        <rect
                            x="0"
                            y="0"
                            width="24"
                            height="24"
                            fill="rgba(0, 0, 0, 0)"
                        />
                    </svg>
                    <span>Atendimento humano</span>
                </button>

                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        setModalAttendance(false);
                    }}
                    className={`submenuModal ${
                        modalAttendace === true ? 'active' : 'inactive'
                    }`}
                >
                    <div className="text">
                        <Tooltip>
                            <p>
                                - As condições de entrada com atendimento humano
                                é do tipo "contém", então sempre que houver as
                                mensagens digitadas abaixo em uma frase, leverá
                                ao atendimento humano;
                            </p>
                            <p>
                                - As condições de entrada com atendimento humano
                                só é considerada depois de validar todas as
                                condições do diálogo atual.
                            </p>
                        </Tooltip>

                        <p>
                            Digite palavras separadas por virgulas para levar
                            até o atendimento humano.
                        </p>
                    </div>

                    <div className="form">
                        <input
                            onChange={(e) =>
                                setPalavrasAtendimento(
                                    e.target.value.split(',')
                                )
                            }
                            type="text"
                            value={palavrasAtendimento.join(',')}
                            className="input-default"
                            placeholder="Palavras separados por virgula"
                        />
                    </div>

                    <div className="phones">
                        {palavrasAtendimento.map((row, key) => (
                            <span className={`phone-number valid`}>{row}</span>
                        ))}
                    </div>
                </form>
            </div>

            <div className="menu-relative" id="embed">
                <button
                    className={`embed action ${embed ? 'active' : 'inactive'}`}
                    onClick={() => setEmbed(!embed)}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        focusable="false"
                        width="1.25em"
                        height="1em"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 20 16"
                    >
                        <path
                            d="M13 11.5l1.5 1.5l5-5l-5-5L13 4.5L16.5 8z"
                            fill="#626262"
                        />
                        <path
                            d="M7 4.5L5.5 3l-5 5l5 5L7 11.5L3.5 8z"
                            fill="#626262"
                        />
                        <path
                            d="M10.958 2.352l1.085.296l-3 11l-1.085-.296l3-11z"
                            fill="#626262"
                        />
                        <rect
                            x="0"
                            y="0"
                            width="20"
                            height="16"
                            fill="rgba(0, 0, 0, 0)"
                        />
                    </svg>
                    <span>Whatsapp Receptivo</span>
                </button>

                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        setEmbed(false);
                    }}
                    className={`submenuModal ${
                        embed === true ? 'active' : 'inactive'
                    }`}
                >
                    <div className="text">
                        <p>
                            Configure as cores e mensagem padrão, copie o código
                            abaixo e cole na tag head do seu site.
                        </p>
                    </div>

                    <div className="embed-iframe">
                        <div>
                            <label>
                                <input
                                    type="color"
                                    value={colorEmbed}
                                    onChange={(e) =>
                                        setColorEmbed(e.target.value)
                                    }
                                />
                                <span>Cor do texto</span>
                            </label>

                            <label>
                                <input
                                    type="color"
                                    value={backgroundEmbed}
                                    onChange={(e) =>
                                        setBackgroundEmbed(e.target.value)
                                    }
                                />
                                <span>Cor do fundo</span>
                            </label>
                        </div>

                        <iframe
                            src={`https://text2you.com.br/example-chatbot.php?token=${broker}&message=${
                                mensagemDeEntrada[0]
                            }&color=${colorEmbed.replace(
                                '#',
                                ''
                            )}&backgroundColor=${backgroundEmbed.replace(
                                '#',
                                ''
                            )}`}
                        ></iframe>
                    </div>

                    <div className="text">
                        <p>
                            Mensagens que iniciam o bot. Ex: Olá, gostei do
                            produto xxxx, gostaria de saber o valor.
                        </p>
                    </div>

                    <div className="formMessages">
                        <input
                            type="text"
                            value={mensagemEntradaUnico}
                            onChange={(e) =>
                                setMensagemEntradaUnico(e.target.value)
                            }
                            className="input-default"
                        />
                        <button
                            className="button-zig primary"
                            type="button"
                            onClick={handleOnSaveNovaMensagemEntrada}
                        >
                            Adicionar
                        </button>
                    </div>

                    <div className="messages">
                        {mensagemDeEntrada.map((row, key) => (
                            <span className={`message`}>
                                <i>{row}</i>
                                <svg
                                    onClick={(e) => handleRemoveMensagem(row)}
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                    focusable="false"
                                    width="1em"
                                    height="1em"
                                    preserveAspectRatio="xMidYMid meet"
                                    viewBox="0 0 36 36"
                                >
                                    <path
                                        class="clr-i-outline clr-i-outline-path-1"
                                        d="M19.61 18l4.86-4.86a1 1 0 0 0-1.41-1.41l-4.86 4.81l-4.89-4.89a1 1 0 0 0-1.41 1.41L16.78 18L12 22.72a1 1 0 1 0 1.41 1.41l4.77-4.77l4.74 4.74a1 1 0 0 0 1.41-1.41z"
                                        fill="#626262"
                                    />
                                    <path
                                        class="clr-i-outline clr-i-outline-path-2"
                                        d="M18 34a16 16 0 1 1 16-16a16 16 0 0 1-16 16zm0-30a14 14 0 1 0 14 14A14 14 0 0 0 18 4z"
                                        fill="#626262"
                                    />
                                    <rect
                                        x="0"
                                        y="0"
                                        width="36"
                                        height="36"
                                        fill="rgba(0, 0, 0, 0)"
                                    />
                                </svg>
                            </span>
                        ))}
                    </div>

                    <textarea
                        id="embedScript"
                        onClick={copyCode}
                        className="embed"
                        readOnly={true}
                        value={`<script id="t2u-chatbot" type="text/javascript" src="https://text2you.com.br/chatbot.js?token=${broker}&message=${mensagemDeEntrada[0]}&color=${colorEmbed}&backgroundColor=${backgroundEmbed}"></script>`}
                    />
                </form>
            </div>

            <button
                className="resetFlow action"
                onClick={() => Model.setElementsDefault()}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    focusable="false"
                    width="1em"
                    height="1em"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                >
                    <g fill="none">
                        <path
                            d="M6.78 2.72a.75.75 0 0 1 0 1.06L4.56 6h8.69a7.75 7.75 0 1 1-7.75 7.75a.75.75 0 0 1 1.5 0a6.25 6.25 0 1 0 6.25-6.25H4.56l2.22 2.22a.75.75 0 1 1-1.06 1.06l-3.5-3.5a.75.75 0 0 1 0-1.06l3.5-3.5a.75.75 0 0 1 1.06 0z"
                            fill="#626262"
                        />
                    </g>
                    <rect
                        x="0"
                        y="0"
                        width="24"
                        height="24"
                        fill="rgba(0, 0, 0, 0)"
                    />
                </svg>
                <span>Resetar</span>
            </button>

            <button className="saveFlow action" onClick={() => handleOnSave()}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    focusable="false"
                    width="1em"
                    height="1em"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 20 20"
                >
                    <g fill="none">
                        <path
                            d="M3 5a2 2 0 0 1 2-2h8.379a2 2 0 0 1 1.414.586l1.621 1.621A2 2 0 0 1 17 6.621V15a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5zm2-1a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1v-4.5A1.5 1.5 0 0 1 6.5 10h7a1.5 1.5 0 0 1 1.5 1.5V16a1 1 0 0 0 1-1V6.621a1 1 0 0 0-.293-.707l-1.621-1.621A1 1 0 0 0 13.379 4H13v2.5A1.5 1.5 0 0 1 11.5 8h-4A1.5 1.5 0 0 1 6 6.5V4H5zm2 0v2.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5V4H7zm7 12v-4.5a.5.5 0 0 0-.5-.5h-7a.5.5 0 0 0-.5.5V16h8z"
                            fill="#626262"
                        />
                    </g>
                    <rect
                        x="0"
                        y="0"
                        width="20"
                        height="20"
                        fill="rgba(0, 0, 0, 0)"
                    />
                </svg>
                <span>Salvar</span>
            </button>
        </div>
    );
}

import React from 'react'

import { Row, Col, Badge, Table } from 'react-bootstrap'

export default function BrokersList({data, totalPorcanal})
{

    return(
        <>
        { data.map((row, key) =>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>
                            {row.tipo}
                        </th>
                        <th>
                            Enviados
                        </th>
                        <th>
                            Recebido
                        </th>
                        <th>
                            Confirmado leitura
                        </th>
                        <th>
                            Erro
                        </th>
                        <th>
                            Total
                        </th>
                    </tr>
                </thead>
                <tbody>
                    { row.totalPorBroker.map((broker, brokerKey) =>
                        <tr key={brokerKey}>
                            <td className={broker.isActive !== true ? 'danger' : 'success'}>
                                { broker.identificacao }
                            </td>
                            { broker.totalPorStatus.map((totalPorStatus, totalKey) =>
                                <td key={totalKey}>
                                    { totalPorStatus.total }
                                </td>
                            )}
                            <td>
                                { broker.totalPorStatus[0].total + broker.totalPorStatus[1].total + broker.totalPorStatus[2].total + broker.totalPorStatus[3].total }
                            </td>
                        </tr>
                    )}
                </tbody>
                <tfoot>
                    <tr>
                        <td align={"right"}><b>TOTAL</b></td>
                        <td>{totalPorcanal[key]['Enviado']}</td>
                        <td>{totalPorcanal[key]['Recebido']}</td>
                        <td>{totalPorcanal[key]['Confirmado Leitura']}</td>
                        <td>{totalPorcanal[key]['Erro']}</td>
                    </tr>
                </tfoot>
            </Table>
        )}
        </>
    )

}